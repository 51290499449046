import React from "react";

function MainCarouselNoorOrchard() {
  return (
    <div className="hero-section bg-al-noor-orchard-carousel">
      <h2 className="carousel-main-text">AL NOOR ORCHARD</h2>
    </div>
  );
}

export default MainCarouselNoorOrchard;
