import React from "react";
import AlNoorOrchardDetails from "../../components/al-noor-Orchard/AlNoorOrchardDetails";
import MainCarouselNoorOrchard from "../../components/al-noor-Orchard/MainCarouselNoorOrchard";

function AlNoorOrchard() {
  return (
    <div>
      <MainCarouselNoorOrchard />
      <AlNoorOrchardDetails />
    </div>
  );
}

export default AlNoorOrchard;
