import React from "react";

function BWCDetails() {
  return (
    <div className="container text-start py-5">
      <div>
        <h2 className="sub-m-h-text py-3">Introduction:</h2>
        <p>
          <b>Blue World City</b> Islamabad is the top-ranking housing society in
          Islamabad. It is developing by the{" "}
          <b>Blue Group of Companies (BGC)</b> in consultation with
          world-renowned Shan Jian Municipal Engineering Company and located
          right next to the Chakri Interchange & newly proposed Rawalpindi Ring
          Road.
        </p>
        <p>
          Right now it is one of the <b>most affordable housing projects</b> in
          the area and has become quite popular among investors. For those
          looking to invest in a high-investment-return residential scheme, then
          this is the best option.
        </p>
        <p>
          Blue World City is also providing online services such as{" "}
          <b>
            blue world city verification, Online Installment, Certificate of
            registration online Verification, and Application Status.
          </b>
        </p>
        <p>
          Blue World is divided into several Blocks such as{" "}
          <b>
            General Block, Overseas Block, Blue Hills Country Farms, Awami
            Villas, Blue World Economic Zone, Orbital Apartments,
          </b>{" "}
          and low-cost <b>Awami Residential Complex.</b>
        </p>
      </div>
      <div>
        <h2 className="sub-m-h-text py-3">BWC Owner & Developers:</h2>
        <p>
          <b>Blue Group of Companies</b> is the owner and developer of{" "}
          <b>Blue World City (BWC).</b>
          The owner of this society is Saad Nazir, Son of ex-Deputy Commissioner
          Lahore, Chaudhry Nazeer. <b>Al-Muqtadir</b> is pleased to mention that
          Blue Group of Companies has signed a Memorandum of Understanding (MOU)
          with internationally recognized China-based Shan Jian Municipal
          Engineering Company to make sure and fulfill the promise of high-class
          development. Blue World City & \ Al-Muqtadir has developed a strong
          bond of marketing and development with each other.
        </p>
      </div>
      <div>
        <h2 className="sub-m-h-text py-3">
          About Blue Group of Companies (BGC):
        </h2>
        <p>
          Blue Group of Companies was established{" "}
          <b>
            in 1989 in Lahore. In the start, the company provided services such
            as
          </b>{" "}
          architectural design and construction services. The company was soon
          able to acquire a sound reputation of being a{" "}
          <b>trustworthy and professional organization</b> by grabbing the
          attention of clients and reputable investors in the market. Now the
          company ranks among the <b>Top 5</b> real estate development companies
          in Pakistan with global recognition.
        </p>
        <p>
          Since then the company has strived into becoming a one-stop solution
          for diverse services that include{" "}
          <b>
            real estate development, marketing, architectural design,
            construction, IT support, and commercial printing.
          </b>{" "}
        </p>
        <p>
          In addition to these services, the company has also started a{" "}
          <b>retail business.</b> It also owns a variety of convenience retail
          stores and clothing brands.
        </p>
        <p>
          Blue Group of Companies has employed over{" "}
          <b>300 hardworking professionals</b> carrying out their
        </p>
      </div>
      <div>
        <h2 className="sub-m-h-text py-3">Company Structure:</h2>
        <p>
          Blue Group of Companies owns a highly diversified set of businesses
          consisting of the following Companies:
        </p>
        <div className="text-center m-5">
          <img
            className="img-w-100"
            src={require("../../assets/images/blue_world/blue_world_city_company_structure.jpeg")}
            alt="blue_world_city_company_structure"
          />
        </div>
        <ul className="li-style-p">
          <li>Blue Bricks</li>
          <li>Blue Properties</li>
          <li>Blue Palms</li>
          <li>Blue Technologies</li>
          <li>WPZ</li>
          <li>Blue Media</li>
          <li>Mart Blue</li>
          <li>Brands Square</li>
          <li>Artimmix</li>
        </ul>
      </div>
      <div>
        <h2 className="sub-m-h-text py-3">
          Previous Property Projects by BGC:
        </h2>
        <p>
          Previously Saad Nazir has completed businesses and housing projects
          such as{" "}
          <b>
            PIA co-operative Society, Center Park Lahore, Blue Town Sapphire,
            Blue Mart, and Blue Technologies.
          </b>{" "}
          Blue World City society Islamabad is the latest state-of-the-art
          residential project launched by BGC.
        </p>
      </div>
      <div>
        <h2 className="sub-m-h-text py-3">Blue World City Islamabad NOC:</h2>
        <p>
          <b>Rawalpindi Development Authority (RDA)</b> had issued the planning
          permission to Blue World City Islamabad against the Vide{" "}
          <b>Letter no. 532/10/DC Dated: 07-08-2018.</b> In the beginning,
          Preliminary Planning Permission for around{" "}
          <b>427 Kanal of land was granted</b> to the society.
        </p>
        <div className="text-center m-5">
          <img
            className="img-w-100"
            src={require("../../assets/images/blue_world/blue_world_city_noc.jpg")}
            alt="blue_world_city_noc"
          />
        </div>
        <p>
          After some time another application was submitted and{" "}
          <b>
            NOC status from RDA of the society came in the list of under
            processing societies.
          </b>{" "}
        </p>
      </div>
      <div>
        <h2 className="sub-m-h-text py-3">NOC December 2021 Update:</h2>
        <p>
          The <b>District Council</b> has issued a letter regarding the NOC of{" "}
          <b>Blue World City</b> Vide Letter No. 532/10/DC dated 07-08-2018. The
          Government resolved the jurisdiction conflict between Rawalpindi
          Development Authority (RDA) and the District after signing the 2021
          Local Bodies Ordinance by Governor Punjab. According to this
          Ordinance, all the residential housing societies will be given
          approval from District Council. After all these deliberations, Blue
          World City was given due approval, due to which plot prices will also
          increase.
        </p>
        <p>
          The NOC of the Blue World City is under the process of approval once
          again. The owners are positive that the NOC of the housing society
          will get approved soon.
        </p>
      </div>
      <div>
        <h2 className="sub-m-h-text py-3">
          Following is the newly proposed possession areas:
        </h2>
        <div className="text-center m-5">
          <img
            className="img-w-100"
            src={require("../../assets/images/blue_world/blue_world_city_possession_area_map.jpg")}
            alt="blue_world_city_possession_area_map"
          />
        </div>
      </div>
      <div>
        <h2 className="sub-m-h-text py-3">
          Following is the Official Notice of Procured land Area by BWC
          Management:
        </h2>
        <div className="text-center m-5">
          <img
            className="img-w-100"
            src={require("../../assets/images/blue_world/blue_world_city_management.jpg")}
            alt="blue_world_city_management"
          />
        </div>
      </div>
      <div>
        <h2 className="sub-m-h-text py-3">Blue World City Location:</h2>
        <p>
          The location of Blue World City Islamabad is situated on the{" "}
          <b>
            main Chakri Road near Chakri Interchange having direct access from
            M-2 Lahore-Islamabad Motorway.
          </b>{" "}
          It is also one of those housing societies that are near Islamabad and
          Rawalpindi as well as the new International Islamabad Airport.
        </p>
        <p>
          It is a highly praised community by investors and realtors with
          beautiful surroundings. It provides a luxurious lifestyle away from
          the hustle and bustle of city life.
        </p>
        <div className="text-center m-5">
          <img
            className="img-w-100"
            src={require("../../assets/images/blue_world/blue_world_city_location.jpg")}
            alt="blue_world_city_location"
          />
        </div>
        <p>
          The Blue World City project provides one of the closest access
          locations to Islamabad and Rawalpindi while offering a peaceful,
          serene, and luxurious living environment that no other residential
          project has offered to its residents in Pakistan, and this is what
          makes the BWC stand out among other societies in Pakistan.
        </p>
        <p>
          Blue World City to Islamabad distance and Blue World City to
          Rawalpindi distance is so close that you could reach either of the
          city in just a few minutes.
        </p>
      </div>
      <div>
        <h2 className="sub-m-h-text py-3">Accessibility:</h2>
        <p>
          <b>Blue World City Rawalpindi is accessible in the following ways:</b>
        </p>
        <ul className="li-style-p">
          <li>Right next to the Chakri road and Chakri Interchange</li>
          <li>
            Approximately 13 min drive to Chakri M2 Toll Plaza, M2, Chakri,
            Rawalpindi, 46000
          </li>
          <li>Approximately 13 min drive to Chakri, Rawalpindi, Punjab</li>
          <li>
            Approximately 31 min drive to New Islamabad Airport Rd, Islamabad
            Gandhara International Airport, Islamabad
          </li>
          <li>Approximately 32 min drive to Rawalpindi</li>
          <li>Approximately 33 min drive to Islamabad</li>
          <li>Approximately 33 min drive to Khanial Homes</li>
          <li>
            Approximately 48 min drive to N-5 National Highway, Grand Trunk Rd,
            Rawalpindi
          </li>
          <li>
            Approximately 57 min drive to Defense Housing Authority, Islamabad
          </li>
          <li>
            Approximately 1hr 3 min drive to Bahria Town, Rawalpindi, Punjab,
            Pakistan
          </li>
          <li>Approximately 57 min drive to Rawat, Islamabad</li>
          <li>Approximately 1hr 2 min drive to N-80, Fateh Jang, Punjab</li>
          <li>Approximately 60 min drive to Saddar, Rawalpindi</li>
          <li>
            Approximately 21 min drive to Syed Abul A’la Maududi Ave, Qurtaba
            City, Rawalpindi, Punjab
          </li>
          <li>
            Approximately 03 min drive to Sihal, Chountra Rd, Pind Malhu,
            Rawalpindi, Punjab
          </li>
          <li>Approximately 05 min drive to Sihal, Rawalpindi, Punjab</li>
          <li>Approximately 13 min drive to Rawalpindi Race Club</li>
          <li>
            Approximately 29 min drive to Parial Hunting Club, Rawalpindi,
            Punjab
          </li>
        </ul>
      </div>
      <div>
        <h2 className="sub-m-h-text py-3">Nearby Landmarks & Places:</h2>
        <p>
          <b>Capital Smart City</b> is one of the most famous housing schemes
          neighbouring this residential project along with others such as Mumtaz
          City, Khanial Homes, Star Argo farms, Top city, and{" "}
          <b>University Town.</b>
        </p>
        <p>
          As seen in the location map attached below, the location of <b>BWC</b>
          is marked with{" "}
          <b>
            the intersection of Chakri Road and Proposed Rawalpindi Ring Road.
          </b>{" "}
        </p>
        <p>
          The society can be accessed{" "}
          <b>via Lahore-Islamabad Motorway M-2 to Chakari Road</b> if you are
          heading from Islamabad and it will also be accessible via Ring road
          after its completion.
        </p>
        <p>
          This Ring Road will cut short the travel time of people travelling to
          and from the furthest areas of Rawalpindi e.g. Giga Mall, Bahria Town
          Islamabad, DHA housing society, and{" "}
          <b>
            Bahria enclave. Following are the nearby locations and famous places
            to this society:
          </b>{" "}
        </p>
        <ul className="li-style-p">
          <li>New International Islamabad Airport</li>
          <li>Islamabad</li>
          <li>Rawalpindi</li>
          <li>Bahria Town Phase, Islamabad</li>
          <li>Defense Housing Authority, Islamabad</li>
          <li>Capital Smart City</li>
          <li>Mumtaz City</li>
          <li>University Town</li>
          <li></li>
        </ul>
        <div className="text-center m-5">
          <img
            className="img-w-100"
            src={require("../../assets/images/blue_world/blue_world_city_nearby_landmark.jpg")}
            alt="blue_world_city_nearby_landmark"
          />
        </div>
      </div>
      <div>
        <h2 className="sub-m-h-text py-3">Blue World City Master Plan:</h2>
        <p>
          The master plan of Blue World City Islamabad is a well-designed plan
          created with the help of national and international experts to develop
          it on the up-to-date principles of Urban Town Planning.
        </p>
        <div className="text-center m-5">
          <img
            className="img-w-100"
            src={require("../../assets/images/blue_world/blue_world_city_master_plan.jpeg")}
            alt="blue_world_city_master_plan"
          />
        </div>
        <h2 className="sub-m-h-text py-3">Moza:</h2>
        <p>
          The Moza of the Blue world city is a village named <b>“Sehal”.</b>
        </p>
        <h2 className="sub-m-h-text py-3">Size of Roads:</h2>
        <p>
          One of the attractive features will be{" "}
          <b>its wide network of carpeted roads</b> connecting all areas of
          society. <b>Main Boulevard </b> will be{" "}
          <b>120ft. main roads more than 80ft.</b> and all the{" "}
          <b>streets will be 40ft. wide.</b>{" "}
        </p>
        <h2 className="sub-m-h-text py-3">
          Residential & Commercial Plot Sizes:
        </h2>
        <p>
          Blue World City Islamabad has acquired <b>75,000 Kanal</b>
          and the whole area will be <b>divided into blocks and plots.</b>{" "}
        </p>
        <h2 className="sub-m-h-text py-3">
          The sizes of plots of residential, farmhouses, and commercial are as
          follows:
        </h2>
        <ul className="li-style-p">
          <li>05 Marla</li>
          <li>08 Marla</li>
          <li>10 Marla</li>
          <li>1 Kanal</li>
          <li>2 Kanal</li>
        </ul>
        <h2 className="sub-m-h-text py-3">
          Blue World City Farmhouses plots are as follows:
        </h2>
        <ul className="li-style-p">
          <li>04 Kanal</li>
          <li>08 Kanal</li>
        </ul>
        <h2 className="sub-m-h-text py-3">
          Blue World City Commercial plots are as follows:
        </h2>
        <ul className="li-style-p">
          <li>05 Marla</li>
          <li>08 Marla</li>
        </ul>
        <h2 className="sub-m-h-text py-3">
          Blue World City Overseas plots are as follows:
        </h2>
        <ul className="li-style-p">
          <li>7 Marla</li>
          <li>10 Marla</li>
          <li>14 Marla</li>
          <li>1 Kanal</li>
          <li>2 Kanal</li>
        </ul>
      </div>
      <div>
        <h2 className="sub-m-h-text py-3">
          World-Famous Replicas & Unique Infrastructure:
        </h2>
        <p>
          <b>
            Blue World City is building globally-recognized replicas of
            landmarks such as follows:
          </b>
        </p>
        <h2 className="sub-m-h-text py-3">Blue Mosque Replica:</h2>
        <p>
          The Blue World City will develop an exact replica of the World-famous
          Blue Mosque in Turkey. This will be a great feat for the project as it
          will be a distinctive attraction of the BWC. The developers have vowed
          to develop the replica exactly and provide almost the same amenities.
        </p>
        <div className="text-center m-5">
          <img
            className="img-w-100"
            src={require("../../assets/images/blue_world/blue_world_city_blue_mosque.jpg")}
            alt="blue_world_city_blue_mosque"
          />
        </div>
        <p>
          <b>
            The major features of the Blue Mosque Replica in the Blue World City
            might include:
          </b>
        </p>
        <ul className="li-style-p">
          <li>5-Star Hotel</li>
          <li>Plush Finishing</li>
          <li>Lavish Amenities</li>
          <li>Lavish Restaurant</li>
        </ul>
      </div>
      <div>
        <h2 className="sub-m-h-text py-3">Blue World City Torch Hotel:</h2>
        <p>
          The Torch Hotel will be built as a replica of “The Torch Doha”. The
          original icon possesses a range of features, such as it is a
          staggering 300 meters high with scenic 360° views. It is indeed an
          engineering marvel that has expertise from architects, planners, and
          engineers. It basically resembles a massive torch flame inaugurated in
          the 2006 Asian Games. And it is Blue World City that is bringing such
          massive structures to Pakistan.
        </p>
        <div className="text-center m-5">
          <img
            className="img-w-100"
            src={require("../../assets/images/blue_world/blue_world_city_torch_hotel.jpg")}
            alt="blue_world_city_torch_hotel"
          />
        </div>
        <p>
          The Torch replica that will be built in Blue World City might include
          several features:
        </p>
        <ul className="li-style-p">
          <li>5-Star hotel</li>
          <li>Exquisite Dining</li>
          <li>Panoramic 360° views</li>
        </ul>
      </div>
      <div>
        <h2 className="sub-m-h-text py-3">Blue World City Villagio Mall:</h2>
        <p>
          Blue World City will develop a range of world-class attractions,
          including a replica of Villaggio Mall. Originally, this mall is
          located in Doha, Qatar. It is one of the best shopping and commercial
          place in Doha. Almost all the globally-recognized brands are part of
          this arena with 50,000 visitor spaces and covering a land area of
          about 183,000 square kilometers.
        </p>
        <div className="text-center m-5">
          <img
            className="img-w-100"
            src={require("../../assets/images/blue_world/blue_world_city_villagio_mall.jpg")}
            alt="blue_world_city_villagio_mall"
          />
        </div>
        <p>
          The owners and developers of Blue World City have vowed to offer an
          almost similar experience to the residents and visitors.
        </p>
        <p>
          <b>The Villaggio Mall to be built in BWC might include:</b>
        </p>
        <ul className="li-style-p">
          <li>Shops</li>
          <li>Brands</li>
          <li>Parking</li>
          <li>Lavish Dining</li>
        </ul>
      </div>
      <div>
        <h2 className="sub-m-h-text py-3">Burj Al Arab Blue World City:</h2>
        <p>
          One of the most iconic hotels in the world is the Burj al Arab
          Jumeirah featuring a range of amenities under one roof. The unique
          design reflects a sail of a boat reaching out across the Arabian Gulf.
          The living experience at this hotel could not even be described in
          words. The lavish features and high prices make it look like a dream
          for many people. But now the Blue World City will be developing almost
          a similar replica in this housing society.
        </p>
        <div className="text-center m-5">
          <img
            className="img-w-100"
            src={require("../../assets/images/blue_world/blue_world_city_burj_al_arab.jpg")}
            alt="blue_world_city_burj_al_arab"
          />
        </div>
        <p>
          <b>
            The Burj al Arab Jumeirah replica to be built in Blue World City
            might include:
          </b>
        </p>
        <ul className="li-style-p">
          <li>Fine Dining</li>
          <li>5-Star Hotel</li>
          <li>Luxurious Suites</li>
        </ul>
      </div>
      <div>
        <h2 className="sub-m-h-text py-3">Blue World City Cricket Stadium:</h2>
        <p>
          Blue World City has decided to construct the largest cricket stadium
          in society with a seating capacity of over 50,000 spectators and
          almost 15,000 parking capacity. The stadium will also be provided with
          a commercial area for the convenience of the visitors.
        </p>
        <div className="text-center m-5">
          <img
            className="img-w-100"
            src={require("../../assets/images/blue_world/blue_world_city_cricket_stadium.jpg")}
            alt="blue_world_city_cricket_stadium"
          />
        </div>
        <p>
          <b>
            The Cricket Stadium to be built in Blue World City might include:
          </b>
        </p>
        <ul className="li-style-p">
          <li>55000 seating capacity</li>
          <li>15000 Parking</li>
          <li>Shops</li>
        </ul>
      </div>
      <div>
        <h2 className="sub-m-h-text py-3">Blue World City Water Theme Park:</h2>
        <p>
          The water-themed park in the Blue World City Islamabad will be
          equipped with modern safety and entertainment features. It will be
          built in close collaboration with the Guangzhou Haisain Amusement
          Technology & Co. Ltd. This will cover an estimated area of around
          70,000 square meters.
        </p>
        <div className="text-center m-5">
          <img
            className="img-w-100"
            src={require("../../assets/images/blue_world/blue_world_city_water_theme_park.jpg")}
            alt="blue_world_city_water_theme_park"
          />
        </div>
        <p>
          <b>
            The water-themed park to be built in Blue World City might include:
          </b>
        </p>
        <ul className="li-style-p">
          <li>20 hot rides</li>
          <li>Entertainment</li>
          <li>Adults` play area</li>
          <li>Children play area</li>
        </ul>
      </div>
      <div>
        <h2 className="sub-m-h-text py-3">The Largest Horse Mascots:</h2>
        <p>
          The Horse mascots in the Blue World City Islamabad will be the largest
          in the world, rising 125 feet above the ground. The prime location
          itself will be an attraction for visitors by Central Avenue. The lush
          green areas and residential areas surrounding the Mascots will provide
          scenic views.
        </p>
        <div className="text-center m-5">
          <img
            className="img-w-100"
            src={require("../../assets/images/blue_world/blue_world_city_largest_horse_mascots_1.jpg")}
            alt="blue_world_city_largest_horse_mascots_1"
          />
        </div>
        <div className="text-center py-3">
          <img
            className="img-w-100"
            src={require("../../assets/images/blue_world/blue_world_city_largest_horse_mascots_2.jpg")}
            alt="blue_world_city_largest_horse_mascots_2"
          />
        </div>
        <div className="text-center m-5">
          <img
            className="img-w-100"
            src={require("../../assets/images/blue_world/blue_world_city_largest_horse_mascots_3.jpg")}
            alt="blue_world_city_largest_horse_mascots_3"
          />
        </div>
        <p>
          <b>The project will house several features, such as follows:</b>
        </p>
        <ul className="li-style-p">
          <li>Art museum</li>
          <li>Food courts</li>
        </ul>
      </div>
      <div>
        <h2 className="sub-m-h-text py-3">
          Blue World City Second Cup Coffee:
        </h2>
        <p>
          Blue World City, Second Cup Coffee, will provide a unique dining
          experience to the visitors. Jim Ragas, the President and CEO of Second
          Cup Coffee Company visited the site area and inaugurated the cafe. The
          event was also participated by several other senior officials of the
          BWC. The Canadian-based company has shown high trust in the project by
          launching its branch in Blue World City.
        </p>
        <div className="text-center m-5">
          <img
            className="img-w-100"
            src={require("../../assets/images/blue_world/blue_world_City_second_cup_coffee.jpg")}
            alt="blue_world_City_second_cup_coffee"
          />
        </div>
      </div>
      <div>
        <h2 className="sub-m-h-text py-3">
          Blue World City Forces School System:
        </h2>
        <p>
          The country-wide Forces School System is a one-of-a-kind academic
          project being developed to offer the best education facilities to the
          children living in Blue World City.
        </p>
        <p>
          The School & College will be equipped with state-of-the-art facilities
          along with qualified teachers.
        </p>
        <div className="text-center m-5">
          <img
            className="img-w-100"
            src={require("../../assets/images/blue_world/blue_world_city_forces_school_system_1.jpg")}
            alt="blue_world_city_forces_school_system_1"
          />
        </div>
        <div className="text-center m-5">
          <img
            className="img-w-100"
            src={require("../../assets/images/blue_world/blue_world_city_forces_school_system_2.jpg")}
            alt="blue_world_city_forces_school_system_2"
          />
        </div>
        <div className="text-center m-5">
          <img
            className="img-w-100"
            src={require("../../assets/images/blue_world/blue_world_city_forces_school_system_3.jpg")}
            alt="blue_world_city_forces_school_system_3"
          />
        </div>
      </div>
      <div>
        <h2 className="sub-m-h-text py-3">
          Blue World City File Verification:
        </h2>
        <p>
          <b>Al-Muqtadir</b> offers you a crucial piece of information regarding
          how to verify the plot files of Blue World City. Through our
          informative file verification methods, you will be able to verify your
          file registration for residential or commercial plots.
        </p>
        <p>
          All local Pakistanis and Overseas Pakistanis can use these methods to
          ensure their file verification.
        </p>
        <p>
          <b>Al-Muqtadir</b> is only responsible for valid file verification for
          those obtained from us, not others. So, be responsible and only get
          Blue World City files from <b>Al-Muqtadir</b>
        </p>
        <p>
          Al-Muqtadir and Blue World City offer several file verification ways,
          including:
        </p>
        <ul className="li-style-p">
          <li>
            Contact <b>Al-Muqtadir</b>, and we will help you verify the file
            registration
          </li>
          <li>Directly Contact the management of Blue World City</li>
          <li>
            To verify the Blue World City file online, you will have to open the{" "}
            <b>Blue World City website (Home Page), as shown below</b>
          </li>
          <li>
            Here you will have to enter <b>“Form No.”</b> and{" "}
            <b>“Security Code”</b>, through which you will have your file
            verification as shown below
          </li>
        </ul>
        <div className="text-center m-5">
          <img
            className="img-w-100"
            src={require("../../assets/images/blue_world/blue_world_city_file_verification_1.jpg")}
            alt="blue_world_city_file_verification_1"
          />
        </div>
        <ul className="li-style-p">
          <li>
            After inserting “Form No.” and “Security Code”, another window will
            open highlighting online <b>file verification search results</b>, as
            shown below
          </li>
        </ul>
        <div className="text-center m-5">
          <img
            className="img-w-100"
            src={require("../../assets/images/blue_world/blue_world_city_file_verification_2.jpg")}
            alt="blue_world_city_file_verification_2"
          />
        </div>
        <ul className="li-style-p">
          <li>
            And if the information entered is{" "}
            <b>wrong or the file verification information is fraud/scam</b>,{" "}
            then the result will show <b>“No Result”</b>.
          </li>
        </ul>
      </div>
      <div>
        <h2 className="sub-m-h-text py-3">
          Advantages of Blue World City File Verification:
        </h2>
        <p>
          Property investment is a big lifetime opportunity and decision of any
          person. So, it is of utmost importance that you go through all the
          file verification processes and be aware of their importance. There
          are many advantages of Blue World City file registration methods
          mentioned as follows:
        </p>
        <ul className="li-style-p">
          <li>Prevention from scams</li>
          <li>Prevention from financial frauds</li>
          <li>Saving your lifetime investments</li>
          <li>Prevention of heavy financial losses</li>
          <li>Ensuring purchase of legal properties</li>
          <li>Prevention from falling into the hands of scammer</li>
        </ul>
      </div>
      <div>
        <h2 className="sub-m-h-text py-3">Blue World City Project Details:</h2>
        <p>
          <b>Blue World City comprises several Blocks such as:</b>
        </p>
        <ul className="li-style-p">
          <li>
            <b>Blue World City Orbital Apartments</b>
          </li>
          <li>
            <b>Blue World City Country Farms</b>
          </li>
          <li>
            <b>Blue World City Awami Complex</b>
          </li>
          <li>
            <b>Blue World City Overseas Block</b>
          </li>
          <li>
            <b>Blue World City Waterfront Block</b>
          </li>
          <li>
            <b>Blue World City General Block</b>
          </li>
          <li>
            <b>Blue World City Sports Valley Block</b>
          </li>
          <li>
            <b>Blue World City Hollywood Block</b>
          </li>
        </ul>
        <p>
          <b>Following are the project details discussed with every feature:</b>
        </p>
      </div>
      <div>
        <h2 className="sub-m-h-text py-3">Blue World City Overseas Block:</h2>
        <p>
          The society offers overseas block, which has become the{" "}
          <b>high point</b> of this remarkable housing project.
        </p>
        <p>
          The overseas block is intended solely for the overseas Pakistanis who
          have acquired higher expectations and taste for lavish facilities.{" "}
          <b>A limited number</b> of residential and commercial plots are
          available in the overseas block on a{" "}
          <b>first come first serve basis.</b>
        </p>
        <p>
          This opportunity is a convenient opportunity for Pakistani citizens
          living abroad to invest safely in Pakistan at a very economical rate.
        </p>
      </div>
      <div>
        <h2 className="sub-m-h-text py-3">Location of Overseas Block:</h2>
        <p>
          The Overseas Block has <b>direct access from Chakri Road</b> and will
          enjoy its <b>designated gate directly</b> through the{" "}
          <b>Main Gate of the society.</b> The housing project will also have
          <b>access from Rawalpindi Ring Road,</b> which will directly connect
          Blue World City Islamabad with Motorway M-2 and Islamabad city in the
          next 2 years. The project is gaining a lot of attraction from the
          local and international investors for its convenient and appealing
          location.
        </p>
        <p>
          Overseas Block is specially designed to cater to all the needs of the
          Overseas Pakistanis who are willing to invest in their homeland. The
          major deterrence for the Overseas investors is the issue of scams. So,
          it is good news that BWC and Overseas Block is no scam while offering
          legal investment opportunities in Rawalpindi. All the facilities and
          amenities in this Block are provided to provide the Overseas residents
          with an abroad like environment. Such facilities offer a familiar
          lifestyle. Direct access from Chakri Road, M-2 Motorway and Rawalpindi
          Ring Road (RRR) is also a plus point for the residents of Overseas
          Block.
        </p>
      </div>
      <div>
        <h2 className="sub-m-h-text py-3">Blue World City Waterfront Block:</h2>
        <p>
          Blue World City Waterfront Block is the recent addition to the Blue
          World City project. The area is right by the waterfront in the society
          and front of Overseas Block. The residents could enjoy the serenity of
          the society with calm waterfront views.
        </p>
        <p>
          Located in the vicinity of Rawalpindi and Islamabad, you could dwell
          on just minutes’ notice to your office or business. In fact, all the
          residential and commercial amenities are made available within the
          society so that no one has to leave the area to fulfill their needs.
          Even state-of-the-art healthcare facilities could also be availed from
          within the premises of society.
        </p>
      </div>
      <div>
        <h2 className="sub-m-h-text py-3">Blue World City Executive Block:</h2>
        <p>
          The Blue World City introduces its latest block, Blue World City
          Executive Block, a brilliant addition to the already mesmerizing
          residential society. The Executive Block is located at an ideal
          location and can be accessed via various routes. Moreover, it lies in
          the vicinity of the twin cities; Islamabad and Rawalpindi.
        </p>
        <p>
          The Executive Block is going to be the epitome of luxury, class, and
          elegance. The residents of the block will be provided with all the
          basic and modern facilities and amenities. The Executive Block of Blue
          World City is going to offer an ultra-luxurious lifestyle to the
          residents.
          <b>Blue World City Executive Block</b> will offer the residence of
          their dreams to the residents at very reasonable rates. It offers
          plots of <b>5 Marla, 10 Marla, and 20 Marla</b> at a{" "}
          <b>4-year installment plan.</b>
        </p>
        <p>
          <b>Note:</b> The Executive plots are not available currently.
        </p>
      </div>
      <div>
        <h2 className="sub-m-h-text py-3">
          Blue World City Overseas Executive Block:
        </h2>
        <p>
          Blue World City Overseas Executive Block is a great addition to the
          master plan of the Blue World City. The booking of the Overseas
          Executive Block has already started. The Overseas Executive Block
          offers residential plots of the following size:
        </p>
        <ul className="li-style-p">
          <li>• 7 Marla (175 Sq. Yd.)</li>
        </ul>
      </div>
      <div>
        <h2 className="sub-m-h-text py-3">Orbital Apartments:</h2>
        <p>
          Orbital Apartments in Blue City offers a premium style luxurious
          lifestyle. If you are fond of panoramic scenic views all day long,
          then no other apartment option in Pakistan could get better than this.
        </p>
        <p>
          Orbital flats offer luxurious yet affordable living options with
          world-class infrastructure development. The idea of the orbital flats
          is to provide spacious and budget-friendly residential properties.
          With the increasing population, vertical residential developments are
          the new trend in Pakistan and worldwide, thus the need to develop
          orbital flats. The Orbital apartments include several features
          including spacious spaces, dedicated lifts, themed interiors,
          world-class services, 24/7 maintenance and much more.
        </p>
        <p>
          It is providing all the high-class features and amenities such as the
          tallest horse mascots, a water theme park, 208ft. wide orbital avenue
          with lush green and well-maintained public parks. All the plush
          lifestyles at affordable prices are rare in Pakistan redefining the
          whole concept of luxury living with features as follows:
        </p>
        <ul className="li-style-p">
          <li>High-rise luxury apartments</li>
          <li>Scenic overlooking penthouse with dedicated special elevators</li>
          <li>Themed interiors</li>
          <li>High-end services</li>
          <li>Atrium</li>
          <li>Indoor swimming pools</li>
          <li>Gyms</li>
          <li>Spa</li>
          <li>24/7 top-notch maintenance and services</li>
          <li>Uninterrupted utility supplies</li>
        </ul>
      </div>
      <div>
        <h2 className="sub-m-h-text py-3">Blue Hills Country Farms:</h2>
        <p>
          Living a busy and hectic everyday routine life can become a burden. To
          get a break from this stressful environment one always dreams of
          living in a peaceful and tranquil place where you can enjoy the
          natural beauty and experience luxury. To meet that need Blue Hills
          Country Farms are included in its vicinity.
        </p>
        <p>
          Whether you are a businessman or a 9 to 5 job person, living a
          stressful life could affect your mental and physical health. That is
          why a serene environment, close to nature and away from the urban
          hustle, is needed to freshen up. Blue Hills Country Farms is a perfect
          getaway location for those looking to live a stress-free life. The
          farms offer a close to nature and peaceful environment.
        </p>
      </div>
      <div>
        <h2 className="sub-m-h-text py-3">
          Location of Blue Hills Farm Houses:
        </h2>
        <p>
          The location of the Blue hills farmhouse is ideally placed at the{" "}
          <b>most serene area of the society,</b> with a{" "}
          <b>beautiful water stream flowing nearby and</b> surrounded by the
          <b>natural magnificence</b> of the society. The peaceful and soothing
          environment of society is a perfect place to enjoy a life full of
          harmony and solace, away from the city.
        </p>
      </div>
      <div>
        <h2 className="sub-m-h-text py-3">Blue World City Awami Complex:</h2>
        <p>
          Awami Residential Complex is the newest addition to the majestic
          housing project, Blue World City. The complex comprises independent
          and duplex units each equipped with all necessities required to live a
          luxurious life with incredible views of society. Each unit is designed
          to provide sustainable infrastructure, upscale amenities, and comfort.
        </p>
        <p>
          Keeping in view the residential needs of the general public, Awami
          Residential Complex is being developed to meet the growing demand for
          affordable housing properties. The Awami complex comprise single
          duplex units with budget friendly construction process, but quality
          was never compromised. BWC Awami Complex is the pinnacle of
          cost-effective housing constructions.
        </p>
      </div>
      <div>
        <h2 className="sub-m-h-text py-3">
          Location of Awami Residential Complex:
        </h2>
        <p>
          The location of this Awami Residential Complex is parallel to the main
          Blue World City with directed access to Chakri Road, one of the most
          demanded areas near Islamabad International Airport. The development
          will be executed by the Blue Group of Companies with the highest
          qualities and will consist of common spaces and services at the height
          of this emblematic property.
        </p>
      </div>
      <div>
        <h2 className="sub-m-h-text py-3">
          Blue World City Sports Valley Block:
        </h2>
        <p>
          The very latest addition to the master plan of the Blue World City is
          Sports Valley Block. The launch of this block is exciting news for the
          sports lover as this block is going to promote sports tourism in
          Pakistan. It will feature Pakistan’s largest cricket ground,
          accommodating more than <b>55,000 viewers</b> and a huge parking space
          for almost <b>15,000 vehicles.</b>
        </p>
        <p>
          <b>25 recreational parks</b> are also going to be built, along with
          courts for volleyball, badminton, futsal, tennis, and basketball. It
          will also feature open-air gymnasiums, bowling alleys, cycling tracks,
          and hiking trails.{" "}
          <b>The following structures will add to the beauty of the block:</b>
        </p>
        <ul className="li-style-p">
          <li>Villaggio Mall</li>
          <li>Torch Hotel</li>
          <li>
            Blue Mosque (Copy of the Blue Mosque in Turkey) The block offers
            residential and commercial plots of different sizes at affordable
            rates.
          </li>
        </ul>
      </div>
      <div>
        <h2 className="sub-m-h-text py-3">
          Sports Valley Block Residential Plots:
        </h2>
        <p>
          <b>This block offers residential plots of the following sizes:</b>
        </p>
        <ul className="li-style-p">
          <li>5 Marla</li>
          <li>8 Marla</li>
          <li>10 Marla</li>
          <li>1 Kanal</li>
        </ul>
      </div>
      <div>
        <h2 className="sub-m-h-text py-3">
          Sports Valley Block Commercial Plots:
        </h2>
        <p>
          <b>It offers commercial plots of the following sizes:</b>
        </p>
        <ul className="li-style-p">
          <li>• 5 Marla</li>
          <li>• 8 Marla</li>
          <li>• 10 Marla</li>
          <li>• 8 Kanal Mega Commercial Plots</li>
        </ul>
        <p>
          <b>Note:</b> The payment plan for the commercial plots will be
          announced soon.
        </p>
      </div>
      <div>
        <h2 className="sub-m-h-text py-3">Blue World City Hollywood Block:</h2>
        <p>
          Hollywood Block is one of the finest and most modern blocks of Blue
          World City. It is located adjacent to the Trafalgar Square of Blue
          World City. The block can be accessed from Gate 1 and Gate 2 of the
          housing society. <b>Some of its exquisite features are as follows:</b>
        </p>
        <ul className="li-style-p">
          <li>• Grand Mosque</li>
          <li>• Gated Community</li>
          <li>• Hollywood Insignia</li>
          <li>• Multiplex Cinema</li>
          <li>• Thinker’s Club</li>
          <li>• Exclusive Clubhouse</li>
          <li>• Expansive Green Areas</li>
          <li>• Commercial Plazzas</li>
          <li>• 24/7 Security & Surveillance</li>
        </ul>
      </div>
      <div>
        <h2 className="sub-m-h-text py-3">
          Hollywood Block Residential Plots:
        </h2>
        <p>
          <b>It offers residential plots of the following sizes:</b>
        </p>
        <ul className="li-style-p">
          <li>• 10 Marla</li>
          <li>• 1 Kanal</li>
          <li>• 2 Kanal</li>
          <li>• 4 Kanal</li>
          <li></li>
        </ul>
      </div>
      <div>
        <h2 className="sub-m-h-text py-3">Blue World City Payment Plan:</h2>
        <p>
          The society has offered{" "}
          <b>a 3-year, 4-year, and 5-year installment plan</b> for the
          convenience of its investors. Bookings start with only a{" "}
          <b>10% down payment.</b>
        </p>
        <p>
          CEO Blue World City has announced easy payment plans of Blue World
          City as follows:
        </p>
      </div>
      <div>
        <h2 className="sub-m-h-text py-3">Blue World City General Block:</h2>
        <p>
          General Block <b>of</b> Blue World City offers a 4-year payment plan
          for <b>5, 8, 10 Marla, 1, and 2 Kanal luxurious plots.</b> This modern
          style Block is designed to provide a luxurious and cost-effective
          living style, upscale facilities and services, and an appealing
          location.
        </p>
        <p>
          The management is offering <b>5 Marla</b> for 14 Lac and 90 thousand
          rupees, with only a 10% down payment of 1 lac and 49thousand rupees.
          40 monthly installments will just cost 14 thousand and 900 rupees. 8
          half-yearly installments will cost 93 thousand and 125rupees.
        </p>
        <p>
          The management is offering <b>8 Marla</b> for 19 Lacs with only a 10%
          down payment of 1 lac and 91 thousand, and 500 rupees. 40 monthly
          installments will just cost 19 thousand and 150 rupees. 8 half-yearly
          installments will cost 1 lac, nineteen thousand and 688 rupees only.
        </p>
        <p>
          The management is offering <b>10 Marla</b> for 22 Lacs and 90 thousand
          with only a 10% down payment of 2 lac and 29 thousand rupees. 40
          monthly installments will just cost 20 thousand and 900 rupees. 8
          half-yearly installments will 1 lac, 43 thousand and 125 rupees only.
        </p>
        <p>
          The management is offering <b>1 Kanal</b> for 42 Lacs and 90 thousand
          with only a 10% down payment of 4 lacs and 29 thousand rupees. 40
          monthly installments will just cost 42 thousand and 900 rupees. 8
          half-yearly installments will 2 lacs, 68 thousand and 125 rupees only.
        </p>
        <p>
          The management is offering <b>2 Kanal</b> for 83 Lacs with only a 10%
          down payment of 8 lacs and 30 thousand. 40 monthly installments will
          just cost 83 thousand. 8 half-yearly installments will cost 5 lacs,18
          thousand and 750 rupees only.
        </p>
        <p>
          The management is offering <b>5 Marla</b> commercial plots for 90 Lacs
          with only a 10% down payment of 9 lacs. 40 monthly installments will
          just cost 90 thousand. 8 half-yearly installments will cost 5 lacs, 62
          thousand and 500 rupees only.
        </p>
      </div>
      <div>
        <h2 className="sub-m-h-text py-3">
          Blue World City Executive Block Payment Plan:
        </h2>
        <p>
          Blue World City Executive Block offers a very easy-to-follow payment
          plan. Moreover, it offers a convenient <b>4-year installment plan</b>{" "}
          for those who cannot pay the lump sum money at once. The Executive
          Block is offering the plots of 5 Marla, 10 Marla, and 20 Marla.
          Bookings start with only a <b>10% down payment.</b>
        </p>
        <p>
          The Executive Block is offering <b>5 Marla</b> plots for PKR 875,000,
          with only a 10% down payment.
        </p>
        <p>
          The Executive Block is offering <b>10 Marla</b> plots for PKR
          1,675,000, with only a 10% down payment.
        </p>
        <p>
          The Executive Block is offering <b>20 Marla</b> plots for PKR
          3,150,000, with only a 10% down payment.
        </p>
        <p>
          <b>Note:</b> The Executive plots are not available currently.
        </p>
      </div>
      <div>
        <h2 className="sub-m-h-text py-3">
          The payment Plan of General Block is as follows:
        </h2>
        <div className="text-center m-5">
          <img
            className="img-w-100"
            src={require("../../assets/images/blue_world/payment_plan/blue_world_city_general_block.jpg")}
            alt="blue_world_city_general_block"
          />
        </div>
      </div>
      <div>
        <h2 className="sub-m-h-text py-3">
          General Block Sector-1 Payment Plan:
        </h2>
        <p>
          The plots for sale in Sector-1 of General Block are balloted. You can
          easily book the 5 Marla and 10 Marla residential plots on a 1-year
          payment plan.
        </p>
        <div className="text-center m-5">
          <img
            className="img-w-100"
            src={require("../../assets/images/blue_world/payment_plan/blue_world_city_general_block_sector_1.jpg")}
            alt="blue_world_city_general_block_sector_1"
          />
        </div>
      </div>
      <div>
        <h2 className="sub-m-h-text py-3">
          General Block Sector-2 Payment Plan:
        </h2>
        <p>
          The plots for sale in Sector-2 of General Block are balloted. You may
          easily buy the 5 Marla and 10 Marla residential plots on a 1-year
          payment plan.
        </p>
        <div className="text-center m-5">
          <img
            className="img-w-100"
            src={require("../../assets/images/blue_world/payment_plan/blue_world_city_general_block_sector_2.jpg")}
            alt="blue_world_city_general_block_sector_2"
          />
        </div>
      </div>
      <div>
        <h2 className="sub-m-h-text py-3">
          General Block Sector-4 Payment Plan:
        </h2>
        <p>
          The residential plots for sale in Sector-4 of BWC General Block are
          balloted. Only 5 Marla residential plots on a 1-year payment plan are
          open for booking.
        </p>
        <div className="text-center m-5">
          <img
            className="img-w-100"
            src={require("../../assets/images/blue_world/payment_plan/blue_world_city_general_block_sector_4.jpg")}
            alt="blue_world_city_general_block_sector_4"
          />
        </div>
      </div>
      <div>
        <h2 className="sub-m-h-text py-3">Blue World City Overseas Block:</h2>
        <p>
          Overseas Block is offering a 4-year installment plan for several
          luxurious sizes of residential plots, comprising of
        </p>
        <ul className="li-style-p">
          <li>• 7 Marla</li>
          <li>• 10 Marla</li>
          <li>• 14 Marla</li>
          <li>• 1 Kanal</li>
          <li>• 2 Kanal</li>
        </ul>
        <p>
          Booking starts from <b>only a 10% down payment</b>, while the
          remaining amount is payable in{" "}
          <b>40 monthly or 16 quarterly installments.</b>
        </p>
        <p>
          The management is offering <b>7 Marla</b> for 19 Lac and 25 thousand
          rupees, with only a 10% down payment of 1 lac and 92 thousand, 5
          hundred rupees. 40 monthly installments will just cost 19 thousand and
          250 rupees. 8 half-yearly installments will cost 96 thousand 250
          rupees only.
        </p>
        <p>
          The management is offering <b>10 Marla</b> for 26 Lac and 80 thousand
          rupees with only a 10% down payment of 2 lac and 68 thousand rupees.
          40 monthly installments will just cost 2 lac, 6 thousand, and 8
          hundred rupees. 8 half-yearly installments will 1 lac and 34 thousand
          only.
        </p>
        <p>
          The management is offering <b>14 Marla</b> for 36 Lac and 50 thousand
          with only a 10% down payment of 3 lacs and 65 thousand rupees. 40
          monthly installments will just cost 36 thousand and 500 rupees. 8
          half-yearly installments will 1 lac, 82 thousand and 500 rupees only.
        </p>
        <p>
          The management is offering <b>1 Kanal</b> for 48 Lacs with only a 10%
          down payment of 4 lacs and 80 thousand rupees. 40 monthly installments
          will just cost 48 thousand rupees. 8 half-yearly installments will 2
          lac and 40 thousand, rupees only.
        </p>
        <p>
          The management is offering <b>2 Kanal</b> for 77 Lacs only a 10% down
          payment of 7 lacs and 70 thousand rupees. 40 monthly installments will
          just cost 77thousand rupees. 8 half-yearly installments will 3 lacs,
          85 thousand rupees only.
        </p>
        <p>
          The management is offering <b>5 Marla commercial plot</b> for 94 Lac
          with only a 10% down payment of 9 lac and 40thousand rupees. 40
          monthly installments will just cost 94 thousand rupees. 8 half-yearly
          installments will cost 4 lac and 70 thousand rupees only.
        </p>
        <p>
          The management is offering <b>10 Marla commercial plot</b> for 1 crore
          and 50 Lac with only a 10% down payment of 15 lac. 40 monthly
          installments will just cost 1 lac and 50 thousand rupees. 8
          half-yearly installments will cost 7 lac and 50 thousand rupees only.
        </p>
      </div>
      <div>
        <h2 className="sub-m-h-text py-3">
          The payment Plan of Overseas Block is as follows:
        </h2>
        <div className="text-center m-5">
          <img
            className="img-w-100"
            src={require("../../assets/images/blue_world/payment_plan/blue_world_city_overseas_block.jpg")}
            alt="blue_world_city_overseas_block"
          />
        </div>
      </div>
      <div>
        <h2 className="sub-m-h-text py-3">
          The Payment Plan of Blue World City Waterfront Block:
        </h2>
        <p>
          <b>
            Following is the payment plan for Blue World City Waterfront Block:
          </b>
        </p>
        <div className="text-center m-5">
          <img
            className="img-w-100"
            src={require("../../assets/images/blue_world/payment_plan/blue_world_city_water_front_block.jpeg")}
            alt="blue_world_city_water_front_block"
          />
        </div>
      </div>
      <div>
        <h2 className="sub-m-h-text py-3">
          Blue World City Waterfront Block Commercial Plots Payment Plan:
        </h2>
        <div className="text-center m-5">
          <img
            className="img-w-100"
            src={require("../../assets/images/blue_world/payment_plan/blue_world_city_water_front_district.jpg")}
            alt="blue_world_city_water_front_district"
          />
        </div>
      </div>
      <div>
        <h2 className="sub-m-h-text py-3">Hollywood Block Payment Plan:</h2>
        <p>
          The plots for sale in Sector-5 of General Block are balloted. 10
          Marla, 1 Kanal and 2 Kanal, and 4 Kanal residential plots on a 1-year
          payment plan could be booked by the investors and avail highest ROI.
          The facility of monthly instalments is also given for convenience to
          the investors.
        </p>
        <div className="text-center m-5">
          <img
            className="img-w-100"
            src={require("../../assets/images/blue_world/payment_plan/blue_world_city_holywood_block.jpg")}
            alt="blue_world_city_holywood_block"
          />
        </div>
      </div>
      <div>
        <h2 className="sub-m-h-text py-3">Blue Hills Country Farms:</h2>
        <p>
          Blue Hills Country Farms offers a 4-Year installment plan for{" "}
          <b>4, 8, 12, and 16 Kanal plots</b> in the Blue World City to build
          your dream farmhouse. The down payment starts only with 15% and just
          40 monthly installments with as low as 86 thousand rupees for 4 Kanal
          plot. The farms also offer 8 half-yearly installments.
        </p>
        <p>
          The management is offering <b>4 Kanal</b> for 85 Lacs and 25 thousand
          rupees with only a 15% down payment of 12 lacs and 15 thousand rupees.
          40 monthly installments will just cost 86 thousand and 63 rupees. 8
          half-yearly installments will 4 lacs, 30 thousand, and 313 rupees
          only.
        </p>
        <p>
          The management is offering <b>8 Kanal</b> for 1 crore and 54 Lacs
          rupees with only a 15% down payment of 22 lacs and 35 thousand rupees.
          40 monthly installments will just cost 1 Lac, 58 thousand, and 313
          hundred rupees. 8 half-yearly installments will 7 lacs, 91 thousand,
          and 563 hundred rupees only.
        </p>
        <p>
          The management is offering <b>12 Kanal</b> for 2 crores and 55 Lacs
          and 75 thousand rupees with only a 15% down payment of 37 lacs and 16
          thousand and 250 rupees. 40 monthly installments will just cost 2 Lac,
          63 thousand, and 234 hundred rupees. 8 half-yearly installments will
          13 lacs, 16 thousand, and 132 hundred rupees only.
        </p>
        <p>
          The management is offering <b>16 Kanal</b> for 2 crores and 75 Lacs
          rupees with only a 15% down payment of 39 lac rupees. 40 monthly
          installments will just cost 2 Lac, 76 thousand, and 250 hundred
          rupees. 8 half-yearly installments will 13 lacs, 81 thousand, and 250
          hundred rupees only.
        </p>
      </div>
      <div>
        <h2 className="sub-m-h-text py-3">
          Awami Residential Complex, Awami Villas & Duplex Villa:
        </h2>
        <p>
          You can book apartments in <b>Awami Residential Complex</b> that
          offers studio apartments, <b>1 bed</b> and also <b>2-bed</b>{" "}
          apartments for the easy 50 monthly installments as low as Rs. 10,800/-
        </p>
        <p>
          Blue World City Awami Villas offers <b>3 Marla</b> single-floor
          residential area luxurious Villas. These contemporary style villas are
          designed to provide lavish accommodations, upscale facilities and
          services, and an appealing location.
        </p>
        <p>
          The management is offering <b>Studio apartments</b> for 15 Lacs with a
          down payment of 1 lac and 20 thousand rupees. 50 monthly installments
          will just cost 10 thousand, and 800 hundred rupees. 10 half-yearly
          installments will cost 54 thousand rupees only.
        </p>
        <p>
          The management is offering a <b>1 Bed Lounge</b> for 18 Lacs with a
          down payment of 1 lac and 50 thousand rupees. 50 monthly installments
          will just cost 13 thousand, and 500 hundred rupees. 10 half-yearly
          installments will cost 67 thousand, and 500 rupees only.
        </p>
        <p>
          The management is offering a <b>2 Bed TV Lounge</b> for 22 Lacs with a
          down payment of 1 lac and 90 thousand rupees. 50 monthly installments
          will just cost 17 thousand, and 100 hundred rupees. 10 half-yearly
          installments will cost 85 thousand, and 500 rupees only.
        </p>
        <p>
          The management is offering a <b>3.5 Marla Duplex Villa</b> for 27 Lacs
          with a down payment of 2 lacs and 40 thousand rupees. 50 monthly
          installments will just cost 20 thousand, and 400 hundred rupees. 10
          half-yearly installments will cost 1 Lac and 200 rupees only.
        </p>
        <p>
          The management is offering a <b>3 Marla Single-Storey Awami Villas</b>{" "}
          for 25 Lacs with a down payment of 2 lacs and 20 thousand rupees. 50
          monthly installments will just cost 18 thousand, and 700 hundred
          rupees. 10 half-yearly installments will cost 93 thousand and 500
          rupees only.
        </p>
      </div>
      <div>
        <h2 className="sub-m-h-text py-3">
          The payment Plan of Awami Residential Complex:
        </h2>
        <div className="text-center m-5">
          <img
            className="img-w-100"
            src={require("../../assets/images/blue_world/payment_plan/blue_world_city_awami_residential_complex.jpg")}
            alt="blue_world_city_awami_residential_complex"
          />
        </div>
      </div>
      <div>
        <h2 className="sub-m-h-text py-3">
          Blue World City Overseas Executive Block Payment Plan:
        </h2>
        <p>
          The Down Payment for the Overseas Executive Block plots is PKR
          748,500. The Block also offers easy 12 monthly installments.{" "}
          <b>The payment plan of Overseas Executive Block is as follows:</b>
        </p>
        <div className="text-center m-5">
          <img
            className="img-w-100"
            src={require("../../assets/images/blue_world/payment_plan/blue_world_city_executive_block_district_1.jpg")}
            alt="blue_world_city_executive_block_district_1"
          />
        </div>
      </div>
      <div>
        <h2 className="sub-m-h-text py-3">
          Blue World City 4.5 Marla Residential Plots Payment Plan:
        </h2>
        <p>The payment plan of 4.5 Marla Residential Plots is as follows:</p>
        <div className="text-center m-5">
          <img
            className="img-w-100"
            src={require("../../assets/images/blue_world/payment_plan/blue_world_city_awami_residential.jpg")}
            alt="blue_world_city_awami_residential"
          />
        </div>
      </div>
      <div>
        <h2 className="sub-m-h-text py-3">
          Blue World City Sports Valley Block Payment Plan:
        </h2>
        <p>
          <b>The payment plan for the Sports Valley Block is as follows:</b>
        </p>
        <div className="text-center m-5">
          <img
            className="img-w-100"
            src={require("../../assets/images/blue_world/payment_plan/blue_world_city_sports_valley_block.jpg")}
            alt="blue_world_city_sports_valley_block"
          />
        </div>
      </div>
      <div>
        <h2 className="sub-m-h-text py-3">Salient Features:</h2>
        <p>
          Blue World City aims to develop the biggest housing project in
          Islamabad that would cater to the future residential and commercial
          needs of Islamabad and Rawalpindi. It offers all the basic amenities
          which are pre-requisites of a modern housing development.
        </p>
        <p>
          <b>
            Following are some salient features of Blue World City Islamabad:
          </b>
        </p>
        <ul className="li-style-p">
          <li>Jamia Mosque, a replica of Blue Mosque, Turkey</li>
          <li>The largest themed water park</li>
          <li>International standard Infrastructure</li>
          <li>Society`s Power Plant</li>
          <li>Police station to maintain law and order</li>
          <li>Well-developed roads</li>
          <li>Specifically, designated mass transportation system</li>
          <li>Paved Walkways</li>
          <li>
            All the basic utility available 24/7 such as water, gas, and
            electricity
          </li>
          <li>Underground supply of utilities</li>
          <li>Foolproof security system</li>
          <li>Boundary wall and gated community</li>
          <li>Hospitals and pharmacies the best in the region</li>
          <li>Highly qualified doctors and nursing staff</li>
          <li>The best educational institutions</li>
          <li>Highly-trained and professional teachers</li>
          <li>Skilled maintenance and management workforce</li>
          <li>Most affordable housing options</li>
          <li>Well-planned drainage and waste disposal system</li>
          <li>Modern and capacious business hubs</li>
          <li>
            To keep the society from any drainage clogging, a sophisticated
            drainage system is also installed
          </li>
          <li>Planned sewerage management & waste disposal system</li>
          <li>Beautiful and lush green parks and green areas</li>
          <li>A diverse variety of residential and commercial plots</li>
          <li></li>
        </ul>
        <div className="text-center m-5">
          <img
            className="img-w-100"
            src={require("../../assets/images/blue_world/payment_plan/overseas-block-blue-world-city.jpeg")}
            alt="overseas-block-blue-world-city"
          />
        </div>
      </div>
      <div>
        <h2 className="sub-m-h-text py-3">Facilities & Amenities:</h2>
        <p>
          This housing project will be a wonder of{" "}
          <b>Chinese development and architecture.</b> Not only it is the most
          affordable housing society in the area it is also specifically planned
          to meet the need to accommodate the{" "}
          <b>influx of over 2 million Chinese residents</b> moving into Pakistan
          for the <b>CPEC</b> route project in the next few years.
        </p>
        <p>
          To fulfill this requirement, it has become essential for the society
          to introduce{" "}
          <b>international standard facilities and infrastructure</b> to not
          only facilitate Chinese residents but also to{" "}
          <b>improve the standard of living for Pakistani citizens.</b>{" "}
        </p>
        <p>
          The housing project will introduce the following futuristic, never
          before seen amenities to its residents:
        </p>
      </div>
      <div>
        <h2 className="sub-m-h-text py-3">Water, gas & electricity:</h2>
        <p>
          Blue World City Islamabad is situated in a relatively remote place and
          for so long this area has been neglected and the connections for
          electricity, gas, and water are rarely granted.
        </p>
        <p>
          Although it was a hard task, the employees worked around the clock and
          endlessly to facilitate the investors and to provide them with the
          necessities of life including{" "}
          <b>24/7 availability of water, electricity, and gas</b> so the
          citizens of this society can enjoy a stress-free life.
        </p>
      </div>
      <div>
        <h2 className="sub-m-h-text">Replica of Blue Mosque at Turkey:</h2>
        <p>
          <b>Blue Mosque in Istanbul, Turkey </b> is a marvel of classical
          architecture built between the years 1609 by Muslim designers and
          architects who have inspired the planners of the Blue World City to
          build a replica of this monumental mosque in the society to{" "}
          <b>restore Islamic values and culture in Pakistan.</b>
        </p>
        <p>
          After the Faisal Mosque, the replica of the Blue Mosque will become a
          landmark and will attract visitors to this society. The mosque will
          truly represent and complement the theme of Blue World City.
        </p>
      </div>
      <div>
        <h2 className="sub-m-h-text py-3">
          Commercial Hubs, Schools & Colleges:
        </h2>
        <p>
          The society will prove to become one of the{" "}
          <b>biggest commercial hub</b> in the region with the CPEC route in its
          proximity, the <b>commercial opportunities are endless.</b>
        </p>
        <p>
          The master plan of this spectacular society has been designed
          including state of art, <b>modern commercial avenues,</b> and
          high-standard <b>educational facilities</b> in all the sectors of the
          society for the accessibility of people.
        </p>
      </div>
      <div>
        <h2 className="sub-m-h-text py-3">
          The biggest water theme park in Pakistan:
        </h2>
        <p>
          Park which will include{" "}
          <b>
            fun water rides, slides, wave pools, volcanic waterfall, specially
            designed water pools for underage children, and water surfing.
          </b>{" "}
          The society has planned to build the biggest and Pakistan’s first
          International water theme.
        </p>
      </div>
      <div>
        <h2 className="sub-m-h-text py-3">Gated Community:</h2>
        <p>
          Blue World City Islamabad will be a heavily guarded and gated
          community. Unauthorized people will be prohibited in society. A{" "}
          <b>secure boundary wall</b> will surround the society to add a layer
          of security. Additional features such as{" "}
          <b>face recognition and key cards</b> will be incorporated into the
          society to avoid unwanted visitors and mishaps in the society.
        </p>
      </div>
      <div>
        <h2 className="sub-m-h-text py-3">24/7 Security:</h2>
        <p>
          Blue World City Islamabad takes the security of its residents and
          visitors very seriously, <b>a specially trained security team</b> will
          monitor and ensure the safety of its residents 24/7.
        </p>
        <p>
          <b>CCTV cameras</b> will be installed throughout the society to build
          a secure environment in the housing society so you can enjoy a
          luxurious stress environment.
        </p>
      </div>
      <div>
        <h2 className="sub-m-h-tex py-3">Sector and Jamia mosques:</h2>
        <p>
          Every sector of society will have specially designed sector mosques
          for the residents of each sector so that they can pray comfortably and
          fulfill their religious obligations without any hassle or major
          traveling.
        </p>
        <p>
          To accommodate people for the more Jummah prayers,{" "}
          <b>Jamia Mosques</b> will be built throughout the society for the
          convenience of the occupants and visitors of the society.
        </p>
      </div>
      <div>
        <h2 className="sub-m-h-text py-3">Carpeted Roads:</h2>
        <p>
          Society has already begun working on the infrastructure and the first
          that step is to build a wide network of carpeted roads in the society
          to connect all parts of the society.
        </p>
        <p>
          These roads are proposed to be{" "}
          <b>40, 60, 80, 100, and 120 feet wide</b> respectively and will also
          have concrete pathways and sidewalks for the pedestrians and cyclists.
        </p>
      </div>
      <div>
        <h2 className="sub-m-h-text py-3">Public Transport:</h2>
        <p>
          The society will have an{" "}
          <b>internal network of the public transport system</b> in the society
          to make the traveling in society easier for the occupants as well as
          anyone who is visiting the society.
        </p>
      </div>
      <div>
        <h2 className="sub-m-h-text py-3">Power plant:</h2>
        <p>
          To make the society a load-shedding free zone, the officials have
          proposed the idea of <b>installing an electric power plant</b> in
          society. With society’s very own power plant the availability of
          electricity in the society will ensure{" "}
          <b>a more efficient and productive community.</b>
        </p>
      </div>
      <div>
        <h2 className="sub-m-h-text py-3">Underground Electricity:</h2>
        <p>
          Although the concept of underground electricity is not new and all the
          newest housing schemes are opting for it, it has become{" "}
          <b>an essential upgrade for</b> the <b>safety of the community.</b>{" "}
          Not only this step will bring an{" "}
          <b>improvement in the beauty and elegant plan of the society</b> but
          will also be a lot safer and reduce the chances of any mishaps.
        </p>
      </div>
      <div>
        <h2 className="sub-m-h-text py-3">Sports and cultural complex:</h2>
        <p>
          <b>Multifunctional sports and cultural complex</b> will be built in
          Blue World City to entertain and{" "}
          <b>
            promote diversity of cultures and promote extra-curricular
            activities in the society.
          </b>
        </p>
      </div>
      <div>
        <h2 className="sub-m-h-text py-3">Sewerage Treatment Plant:</h2>
        <p>
          State of art sewerage treatment plants will be installed in the
          society to ensure a{" "}
          <b>hygienic and eco-friendly waste disposal system</b> in the society.
        </p>
      </div>
      <div>
        <h2 className="sub-m-h-text py-3">Water filtration plant:</h2>
        <p>
          Blue world city will be a{" "}
          <b>self-sufficient and sustainable society</b> and one of the most
          prominent features of an independent housing society is to be able to
          provide its residents with clean water.
        </p>
        <p>
          <b>
            The installation of an innovative water filtration plant will ensure
            the supply of clean water
          </b>{" "}
          everywhere in society and will improve the living conditions in the
          region.
        </p>
      </div>
      <div>
        <h2 className="sub-m-h-text py-3">Recreational Park and lake:</h2>
        <p>
          The Oxygen Park and lakes in the society will{" "}
          <b>add to the beauty of the society and the appeal</b> of society.
          These urban green spaces are essential{" "}
          <b>
            to creating a positive impact on health, making social connections,
            and helping the environment.
          </b>
        </p>
      </div>
      <div>
        <h2 className="sub-m-h-text py-3">I-Max cinema:</h2>
        <p>
          Cinemas have become an important means of{" "}
          <b>entertainment and leisure</b> in the lives of people in any
          community. Blue World City will introduce{" "}
          <b>
            3D I-max cinemas to bring the most advanced technology and
            entertainment
          </b>{" "}
          to its people.
        </p>
      </div>
      <div>
        <h2 className="sub-m-h-text py-3">Adventure Club:</h2>
        <p>
          The society will have an adventure club to bring the community
          together and make social connections in society. This club will
          arrange a variety of <b>adventurous activities</b> for the pleasure of
          its members including{" "}
          <b>
            horseback riding, dirt bike riding, paragliding, parachuting,
            hiking,
          </b>{" "}
          etc.
        </p>
      </div>
      <div>
        <h2 className="sub-m-h-text py-3">Safari Zoo:</h2>
        <p>
          Yet another striking feature in the society will be its Safari Zoo,
          with a safe drive through a path amid in the society with{" "}
          <b>wild animals roaming freely</b> will not be only exciting but will
          provide an <b>unforgettable experience</b> for the visitors. The zoo
          will contain a variety of different{" "}
          <b>
            species of animals and birds imported from all around the world.
          </b>
        </p>
      </div>
      <div>
        <h2 className="sub-m-h-text py-3">Water pools and spa clubs:</h2>
        <p>
          Another luxury that will be enjoyed by the occupants of this society
          will be its warm and freshwater pools and spa club. These wonderful
          spas and pools will allow the members to enjoy a{" "}
          <b>variety of relaxing treatments as well as therapeutic sessions.</b>
        </p>
      </div>
      <div>
        <h2 className="sub-m-h-text py-3">Police station:</h2>
        <p>
          Blue World will also have a fully functional and{" "}
          <b>responsive police station</b> in its surroundings to help with any
          criminal encounters and illegal activity in the society.
        </p>
      </div>
      <div>
        <h2 className="sub-m-h-text py-3">40-Bed Hospital:</h2>
        <p>
          Health is the most important concern in any community,{" "}
          <b>a fully operational 40-bed Hospital</b> is designed as a
          significant part of the master plan of Blue World City. This modern
          new health facility will be{" "}
          <b>equipped with the latest technology and machinery</b> to facilitate
          the patients and to deal with any emergency in society.
        </p>
      </div>
      <div>
        <h2 className="sub-m-h-text py-3">
          Latest Development in Blue World City:
        </h2>
        <p>
          Even the society has not been officially launched yet, the development
          work in the project has begun. The{" "}
          <b>construction work of the grand entrance of the society</b> is near
          completion, the construction of the{" "}
          <b>main boulevard of the society</b> is in process.
        </p>
        <p>
          <b>The land clearing process in phase 1</b> of society has commenced
          and it will not be long before the{" "}
          <b>mapping and leveling of plots</b> will begin. In a recent
          development, the CEO of Blue World City Saad Nazeer held a meeting
          with Mr. Chang, a top Chinese Multi-Floor Apartment building developer
          for the construction of{" "}
          <b>Apartment buildings at Blue World City Islamabad.</b>
        </p>
      </div>
      <div>
        <h2 className="sub-m-h-text py-3">
          Blue World City Pak-China Friendly City:
        </h2>
        <p>
          The management of this housing project has signed a{" "}
          <b>
            Memorandum of Understanding (MOU) with Shan Jian Municipal
            Engineering Company, a leading development, and Investment company
            based in China
          </b>{" "}
          for the development and investment of this mega-housing project and to
          make it a successful Pak-China friendly venture.
        </p>
        <p>
          Let us discuss some reason as to why this housing society will be
          monumental in strengthening the relationship between the two countries
          and becoming the <b>first Pak-China friendly City.</b>
        </p>
      </div>
      <div>
        <h2 className="sub-m-h-text py-3">
          1. Development of Chinese and Pakistanis:
        </h2>
        <p>
          This housing society will be developed using{" "}
          <b>
            Chinese skills & technology along with the help of professionals
            from Pakistan.
          </b>{" "}
          The standard of maintenance and development of this society will match
          all the international standards of living.
        </p>
        <p>
          The society will also cater to all the provisions including commercial
          and economic zones, residential areas, education, and health
          institutes & entertainment clubs, making this housing society “A
          Pak-China Friendly Society”.
        </p>
      </div>
      <div>
        <h2 className="sub-m-h-text py-3">2. Pak-China Investment:</h2>
        <p>
          The Shan Jian Municipal Engineering Company has joined hands with Blue
          Group of companies to share the investment of this remarkable housing
          scheme, thus making this a{" "}
          <b>
            joint venture, not just two companies but between two countries,
          </b>{" "}
          thus making it a Pak-China friendly city in a true sense.
        </p>
      </div>

      <div>
        <h2 className="sub-m-h-text py-3">3. CPEC Route Proximity:</h2>
        <p>
          The location of the{" "}
          <b>CPEC Route is very close to Blue World City Islamabad,</b> it is a
          joint venture between Pakistan and China to <b>improve the trade</b>{" "}
          between the two countries.
        </p>
        <p>
          Since the majority of the workers will be Chinese and will need a
          secure nearby accommodation, it will provide excellent accommodation
          to satisfy and meet their requirements.
        </p>
      </div>
      <div>
        <h2 className="sub-m-h-text py-3">
          4. Chinese Architecture and Development:
        </h2>
        <p>
          The infrastructure of society will be{" "}
          <b>developed using Chinese techniques and construction.</b> The master
          plan of the society and the architectural design will be inspired by
          the modern architecture of the cities in China. Thus making it a
          marvel of China Pakistan friendship.
        </p>
      </div>
      <div>
        <h2 className="sub-m-h-text py-3">
          5. Accommodation for Chinese Residents:
        </h2>
        <p>
          <b>A large inflow of Chinese residents</b> will move to Pakistan in
          the next few years due to the CPEC project. This housing project will
          also be developed by Chinese workers and is expected to receive over{" "}
          <b>10,000 Chinese residents for construction and development work.</b>
        </p>
        <p>
          A specially designed block has been reserved for Chinese residents in
          the society under conditional ownership.
        </p>
        <p>
          Blue World City Islamabad is the very first Pak-China friendly City to
          be built in Pakistan which{" "}
          <b>will benefit both the countries economically and socially.</b>
        </p>
        <p>
          This society will not{" "}
          <b>just bring citizens of China and Pakistan closer,</b> it will also
          present endless commercial and residential opportunities for its
          occupants. The venture will also <b>promote harmony and peace</b> in
          the region and will <b>encourage foreign investment</b> in the
          country.
        </p>
      </div>
      <div>
        <h2 className="sub-m-h-text py-3">Why Invest in Blue World City?</h2>
        <p>
          This is a low-cost housing project that will{" "}
          <b>change the perception of affordable accommodation</b> in Pakistan,
          not only people with a limited budget can invest in society and
          provide their family{" "}
          <b>
            a comfortable living but also with luxurious facilities and supreme
            location.
          </b>
        </p>
        <p>
          If you are still not convinced about booking your plot in this project
          let us list down the major points for you that will help you make your
          decision easier.
        </p>
      </div>
      <div>
        <h2 className="sub-m-h-text ppy-3">1. Most Affordable & Convenient:</h2>
        <p>
          The easy installment plan of society makes it possible for people with
          low salaries to be able to provide lavish accommodation for their
          families.
        </p>
      </div>
      <div>
        <h2 className="sub-m-h-text py-3">2. Easy Installment Plan:</h2>
        <p>
          Like most housing societies in the region such as{" "}
          <b>Lahore Smart City and Gulberg Green,</b> Blue world city also
          offers a very <b>convenient 3 years and 4 years installment plan</b>{" "}
          for a variety of sizes and types of plots for the feasibility of its
          investors belonging from all classes of society thus making it an
          ideal and most fitting place to invest your savings in.
        </p>
      </div>
      <div>
        <h2 className="sub-m-h-text">3. Modern Facilities:</h2>
        <p>
          This society will be{" "}
          <b>equipped with all the basic and modern housing facilities.</b>{" "}
          Apart from providing necessities such as gas, clean water, and
          electricity, the society has included parks, cinemas, shopping
          centers, cinemas, and exclusive leisure clubs for its residents. By
          investing in this society you are securing a modern, luxurious and
          peaceful life.
        </p>
      </div>
      <div>
        <h2 className="sub-m-h-text py-3">
          4. Peaceful and Tranquil Environment:
        </h2>
        <p>
          Blue world city Islamabad will allow you to{" "}
          <b>experience a carefree life closer to nature.</b> A society
          surrounded by lush green surroundings and a quiet environment away
          from the noisy city.
        </p>
        <p>
          It will provide an <b>eco-friendly</b> place to raise your family in a
          healthy and natural environment, away from pollution. Although its
          location is not like the housing societies in zone IV of the like Park
          View City its natural ambiance and the amenities are definitely of the
          same level.
        </p>
      </div>
      <div>
        <h2 className="sub-m-h-text py-3">
          5. Infinite Commercial Opportunities:
        </h2>
        <p>
          You can be certain of the <b>infinite possibilities</b> of commercial
          opportunities in this society hence making it one of a kind housing
          project in the area. The diverse commercial avenues, high rise
          shopping malls, entertainment clubs will ensure sufficient jobs and
          economic ventures in the society.
        </p>
      </div>
      <div>
        <h2 className="sub-m-h-text py-3">6. Maximum Return on Invest:</h2>
        <p>
          The prices offered in society as of now are at their{" "}
          <b>lowest and pre-launching phase</b> of the society, making it an
          ideal time to invest in society. After the official launch and further
          development, these rates will most certainly increase and give the
          investors a <b>good return on investment.</b>
        </p>
      </div>
      <div>
        <h2 className="sub-m-h-text py-3">7. Sense of Belonging:</h2>
        <p>
          It will give a <b>sense of belonging to its occupants</b> with its
          up-scale facilities and modern infrastructure, this society is a catch
          and worth investing in. Society will not just improve your quality of
          living but will also{" "}
          <b>
            win your hearts with their innovative services and strategic
            development.
          </b>
        </p>
      </div>
      <div>
        <h2 className="sub-m-h-text py-3">Pros and Cons:</h2>
        <p>
          <b>The pros and cons of BWC are as under:</b>
        </p>
        <div className="text-center m-5">
          <img
            className="img-w-100"
            src={require("../../assets/images/blue_world/payment_plan/pros-and-cons-blue-world-city.jpeg")}
            alt="pros-and-cons-blue-world-city"
          />
        </div>
      </div>
      <div>
        <h2 className="sub-m-h-text py-3">
          Documentation Requirement for booking:
        </h2>
        <p>
          <b>
            To book your plot in Blue World City Visits AM Marketing Office now
            with the following documents:
          </b>
        </p>
        <ul className="li-style-p">
          <li>2 passport size Pictures</li>
          <li>2 copies of your national ID card</li>
          <li>2 copies of the ID card of your next of Kin</li>
          <li>For overseas clients</li>
          <li>
            2 copies of the Overseas National ID Card along with the
            above-mentioned documents
          </li>
        </ul>
        <div className="text-center m-5">
          <img
            className="img-w-100"
            src={require("../../assets/images/blue_world/payment_plan/blue-world-city-booking-procedure.jpeg")}
            alt="blue-world-city-booking-procedure"
          />
        </div>
      </div>
      <div>
        <h2 className="sub-m-h-text py-3">
          Possession of Plots in Blue World City:
        </h2>
        <p>
          Initially, the information was shared by the management of BWC that
          the ballot will be carried out in January 2020. The ballot for General
          Block has already been accomplished.
        </p>
        <p>
          The ownership of the plots was planned to be announced in December
          2020. For the voting of the plots, the e-balloting is decided to be
          used to make the process transparent.
        </p>
        <p>
          Those investors, who have already submitted the 90% payment will be
          able to take part in the balloting. However, there is a piece of good
          news that you could still become part of balloting by paying a 50%
          upfront price.
        </p>
        <p>
          <b>
            Possession of the plots of phase 1 in the society is expected to be
            given after 3 years,
          </b>{" "}
          unlike CDA sectors such as <b>B-17</b> and <b>ICHS Town</b> which are
          offering the possession right away.
        </p>
        <p>
          The possession in Blue World will be granted after the development
          work is completed in this block, however, this given timeframe is not
          confirmed and can get extended due to factors involving such as late
          development, NOC, or natural causes.
        </p>
      </div>
      <div>
        <h2 className="sub-m-h-text py-3">
          Frequently Asked Questions (FAQs):
        </h2>
        <p>
          <b>The FAQs about Blue World City are as under:</b>
        </p>
      </div>
      <div>
        <h2 className="sub-m-h-text">Q1- What is Blue World City?</h2>
        <p>
          Blue World City Islamabad is a housing project, located at Chakri
          Road, Rawalpindi.
        </p>
      </div>
      <div>
        <h2 className="sub-m-h-text py-3">
          Q2-Who is the owner and developer of Blue World City?
        </h2>
        <p>
          Blue World City is a project of Blue Group of Companies, which is
          owned by Saad Nazir. The task of developing this mega project has been
          given to a Chinese company Shan Jian Municipal Engineering Company.
        </p>
      </div>
      <div>
        <h2 className="sub-m-h-text py-3">
          Q3-Why Blue World City is called Blue World City Islamabad?
        </h2>
        <p>
          Blue World City lies on the border region of Rawalpindi and Islamabad
          city hence it is referred to as Blue World City Islamabad by some
          people, even though it falls under the jurisdiction of RDA.
        </p>
      </div>
      <div>
        <h2 className="sub-m-h-text py-3">
          Q4-Is Blue World City a legal housing society?
        </h2>
        <p>
          The layout planning permission of the society is approved by RDA and
          the society has applied for the renewal of NOC as well for the
          extension area and is in the process to get approved, but rest assure
          Blue World City is guaranteed a legal housing society.
        </p>
      </div>
      <div>
        <h2 className="sub-m-h-text py-3">
          Q5-When will the development work start and how long will it take for
          the society to get fully developed?
        </h2>
        <p>
          The development work in the society has started, with the construction
          work on the main gate and Main Boulevard almost completed. The
          earthwork has also commenced in the society and the leveling of land
          has begun. The time frame of the development given by society at the
          given time is 3-4 years.
        </p>
      </div>
      <div>
        <h2 className="sub-m-h-text py-3">
          Q6-Will the Blue World City provide gas, water, and electricity?
        </h2>
        <p>
          Blue World City aims to provide its residents with all the basic and
          advanced facilities including availability of water, electricity, and
          gas 24/7.
        </p>
      </div>
      <div>
        <h2 className="sub-m-h-text py-3">
          Q7-Is the Blue World City offering any installment plan?
        </h2>
        <p>
          Yes, there is a 3 year and 4-year easy installment payment plan
          offered by the society for the feasibility of the investors.
        </p>
      </div>
      <div>
        <h2 className="sub-m-h-text py-3">
          Q8-When will be the possession of plots given in society?
        </h2>
        <p>
          The possession of plots is expected to be granted in 3 to 4 years.
          Initially, the possession will be granted to the plots in phase 1 of
          the society due to the early development of the blocks in that phase.
        </p>
      </div>
      <div>
        <h2 className="sub-m-h-text py-3">
          Q9-Are there commercial plots available in the society?
        </h2>
        <p>
          Yes, there are various sizes of commercial plots available in the
          society, for complete details refer to the payment plan attached in
          the article above if you have missed it.
        </p>
      </div>
      <div>
        <h2 className="sub-m-h-text py-3">
          Q10-Is there any Overseas Block available in the society for Overseas
          Pakistani Citizens?
        </h2>
        <p>
          Yes, society has a dedicated overseas block specially designed for
          overseas Pakistanis in society.
        </p>
      </div>
      <div>
        <h2 className="sub-m-h-text py-3">
          Q11- How Will Blue World City become the hub of economic growth in the
          region?
        </h2>
        <p>
          Society will become the hub of economic growth with the diverse
          commercial opportunities offered in society. Variety of Jobs and
          career opportunities offered in the society along with the proximity
          of the CPEC route, the blue world City will become an investor’s
          paradise.
        </p>
      </div>
      <div>
        <h2 className="sub-m-h-text py-3">
          Q12-Why Blue World City is called Pak-China friendly City?
        </h2>
        <p>
          Blue World City has signed an MOU with A Chinese Company for all the
          development work of this mega housing project. It is the first housing
          society developed by the Chinese in Pakistan, hence it holds great
          significance in the hearts of the people of both countries so it’s
          called A Pak- China-friendly city.
        </p>
      </div>
      <div>
        <h2 className="sub-m-h-text py-3">
          Q13- Who is the Blue World City Brand Ambassador?
        </h2>
        <p>
          It pleasantly stated that blue world city signs Esra Bilgiç as the
          brand ambassador.
        </p>
      </div>
      <div>
        <h2 className="sub-m-h-text py-3">Conclusion:</h2>
        <p>
          <b>Blue world city Islamabad</b> is a dream housing society for the
          people of Pakistan. The main attractive feature of this society is
          that it is affordable and also provides every modern facility. Society
          is still in the development stages so the prices are still low. Once,
          the society is fully developed in 1 to 2 years then the prices may go
          higher and then these low prices will not be available. Installment
          payment plans, affordability, and luxurious amenities are the
          top-selling points of BWC.
        </p>
        <p>
          So, <b>Al-Muqtadir highly </b> recommends you avail yourself of this
          golden opportunity to invest in this society. You may also contact us
          for the blue world city file price or any kind of blue world city
          price. Blue World City reviews are beyond the charts.
        </p>
        <p>
          If you want to know more about such housing societies then please read
          about <b>Blue World City, Park View City, Al-Noor Orchid,</b> and Al-
          Rehman Garden to learn more about similar luxuries being offered at
          low prices.
        </p>
      </div>
    </div>
  );
}

export default BWCDetails;
