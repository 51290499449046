import React from "react";

function MainCarouselPVC() {
  return (
    <div className="hero-section bg-pvc-carousel">
      <h2 className="carousel-main-text">PARK VIEW CITY</h2>
    </div>
  );
}

export default MainCarouselPVC;
