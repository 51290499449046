import React from "react";

import PhoneIcon from "@mui/icons-material/Phone";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import MailIcon from "@mui/icons-material/Mail";

import { Link } from "react-router-dom";

import "../index.css";

function ContactDetails() {
  return (
    <div className="p-3 bg-dark">
      <h4 className="text-start text-light">
        <b>HEAD OFFICE</b>
      </h4>
      <hr className="text-light" />
      <div className="card bg-none border-0 ">
        <div className="card-body p-0 text-start text-dark">
          <div className="row">
            <div className="col">
              <a
                href="https://goo.gl/maps/7z3KRYMKsjxyuURaA"
                className="nav-link border-0 p-0 text-dark text-start d-flex mob-style-ft-lo"
              >
                <LocationOnIcon className="text-blue-c" />
                <lable className="ms-2 ml-mob-0">
                  Office # 2-M, 1st Floor Valencia Mall, K1-Block, Valancia
                  Town, Lahore.
                </lable>
              </a>
            </div>
          </div>

          <div className="row my-3">
            <div className="col">
              <a
                href="mailto:info@almuqtadirestategroup.com"
                className="nav-link border-0 p-0 text-dark text-start"
              >
                <MailIcon className="text-blue-c" />
                <lable className="ms-2">info@almuqtadirestategroup.com</lable>
              </a>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <a
                href="tel:+923259009786"
                className="nav-link border-0 p-0 text-dark text-start"
              >
                <PhoneIcon className="text-blue-c" />
                <lable className="ms-2">+92 325-9009786</lable>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ContactDetails;
