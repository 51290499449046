import React from "react";
import MainCarouselPVC from "../../components/parkViewCity/MainCarouselPVC";
import PVCDetails from "../../components/parkViewCity/PVCDetails";

function ParkViewCity() {
  return (
    <div>
      <MainCarouselPVC />
      <PVCDetails />
    </div>
  );
}

export default ParkViewCity;
