import React from "react";
import ContactForm from "../contactUs/ContactForm";

function ReadyToServe() {
  return (
    <div className="py-5 bg-readt-to-serve">
      <div className="container">
        <div className="row row-cols-1 row-cols-md-2 align-items-center justify-content-center">
          <div className="col">
            <h2 className="h-text text-start text-light">
              WE ARE READY TO SERVE YOU IN REAL ESTATE
            </h2>
            <hr />
            <h2 className="P-text text-start text-light">
              <b>CALL US TODAY</b>
            </h2>
            <h2 className="h-text text-start text-light">+92 325-9009786</h2>
            <p></p>
          </div>
          <div className="col">
            <ContactForm />
          </div>
        </div>
      </div>
    </div>
  );
}

export default ReadyToServe;
