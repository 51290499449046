import React from "react";

function Welcome() {
  return (
    <div>
      <div className="container">
        <div className="row row-cols-1 row-cols-md-2 pt-5">
          <div className="col">
            <h2 className="h-text m-0 text-start">Welcome to AM MARKETING</h2>
          </div>
          <div className="col">
            <div className="row row-cols-2 row-cols-md-2 justify-content-center">
              <div className="col">
                <img
                  src={require("../../assets/images/Badge100.png")}
                  width="150px"
                  alt="Badge100"
                />
              </div>
              <div className="col">
                <img
                  src={require("../../assets/images/am-excellence-of-15-years.png")}
                  width="150px"
                  alt="am-excellence-of-15-years"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="row row-cols-1 row-cols-md-2 py-5 align-items-center">
          <div className="col">
            <p className="p-text text-start">
              Welcome to the rapidly emerging Real Estate Company{" "}
              <b> AM Marketing </b> Islamabad working from last 17 years. Our
              professional team is serving many housing societies for marketing
              purposes and has completed many renowned projects achieving high
              excellency. Use of most modern techniques of marketing like
              e-media and web-based medium for mass communication is one of our
              strengths to reach-out maximum number of clients and customers.
            </p>
          </div>
          <div className="col mt-4">
            <img
              src={require("../../assets/images/real-estate-investing.jpg")}
              width="100%"
              alt="real-estate-investing"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Welcome;
