import React from "react";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import TwitterIcon from "@mui/icons-material/Twitter";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import PhoneIcon from "@mui/icons-material/Phone";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import MailIcon from "@mui/icons-material/Mail";
import { Link } from "react-router-dom";
import "./index.css";

function Footer() {
  return (
    <div className="bg-dark" id="footer">
      <div className="style-ft footer-height py-5">
        <div className="container style-z-1">
          <div className="row row-cols-1 row-cols-lg-3">
            <div className="col align-self-center">
              <div className="card bg-none border-0">
                <div className="card-body p-0 footer-mob-style">
                  <div className="flex-end">
                    <ul className="list-unstyled m-0 text-center d-flex justify-content-start">
                      <a
                        className="link-light"
                        href="https://www.facebook.com/almuqtadirestateofficial?mibextid=ZbWKwL"
                      >
                        <li className="ms-3">
                          <FacebookIcon
                            className="social-icon-facebook"
                            style={{ fontSize: "30" }}
                          />
                        </li>
                      </a>

                      <a
                        className="link-light"
                        href="https://www.instagram.com/almuqtadirestateofficial/?igshid=YmMyMTA2M2Y%3D"
                      >
                        <li className="ms-3">
                          <InstagramIcon
                            className="social-icon-instagram"
                            style={{ fontSize: "30" }}
                          />
                        </li>
                      </a>

                      <a
                        className="link-light"
                        href="https://twitter.com/ALMUQTADIR_E?t=7GGcyvA_6gHW3QxkTRjfTg&s=09"
                      >
                        <li className="ms-3">
                          <TwitterIcon
                            className="social-icon-twitter"
                            style={{ fontSize: "30" }}
                          />
                        </li>
                      </a>

                      {/* <a
                        className="link-light"
                        href="https://www.linkedin.com/company/plumlogix"
                      >
                        <li className="ms-3" data-aos="zoom-in">
                          <LinkedInIcon
                            className="social-icon-linkedin"
                            style={{ fontSize: "30" }}
                          />
                        </li>
                      </a> */}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="col align-self-start">
              <div className="card bg-none border-0">
                <div className="card-body p-0 text-start">
                  <h4 className="mb-3 ft-h-text mt-4">Investment Projects</h4>

                  <Link
                    to="/blue-world-city"
                    className="nav-link border-0 p-0 text-dark text-start"
                  >
                    Blue World City
                  </Link>
                  <Link
                    to="/kingdom-valley"
                    className="nav-link border-0 p-0 my-2 text-dark text-start"
                  >
                    Kingdom Valley
                  </Link>

                  <Link
                    to="/park-view-city"
                    className="nav-link border-0 p-0 my-2 text-dark text-start"
                  >
                    Park View City
                  </Link>
                  <Link
                    to="/al-noor-orchard"
                    className="nav-link border-0 p-0 text-dark text-start"
                  >
                    Al-Noor Orchard
                  </Link>
                </div>
              </div>
            </div>
            <div className="col align-self-center">
              <div className="card bg-none border-0">
                <div className="card-body p-0 text-start">
                  <h4 className="mb-3 ft-h-text mt-4">Get in Touch</h4>
                  <div className="row">
                    <div className="col">
                      <a
                        href="https://goo.gl/maps/7z3KRYMKsjxyuURaA"
                        className="nav-link border-0 p-0 text-dark text-start d-flex mob-style-ft-lo"
                        target="_blank"
                      >
                        <LocationOnIcon className="text-blue-c" />
                        <lable className="ms-2 ml-mob-0">
                          Office # 2-M, 1st Floor Valencia Mall, K1-Block,
                          Valancia Town, Lahore.
                        </lable>
                      </a>
                    </div>
                  </div>

                  <div className="row my-3">
                    <div className="col">
                      <a
                        href="mailto:info@almuqtadirestategroup.com"
                        className="nav-link border-0 p-0 text-dark text-start"
                      >
                        <MailIcon className="text-blue-c" />
                        <lable className="ms-2">
                          info@almuqtadirestategroup.com
                        </lable>
                      </a>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col">
                      <a
                        href="tel:+923259009786"
                        className="nav-link border-0 p-0 text-dark text-start"
                      >
                        <PhoneIcon className="text-blue-c" />
                        <lable className="ms-2">
                          <b>+92 325-9009786</b>
                        </lable>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <p className="card-text text-center bg-none mt-2 mob-pb-fc text-light">
              <hr className="text-light" />
              &copy; 2022 AM Marketing All rights reserved
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
