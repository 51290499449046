import React from "react";

function MainCarouselBWC() {
  return (
    <div className="hero-section bg-bwc-carousel">
      <h2 className="carousel-main-text">BLUE WORLD CITY</h2>
    </div>
  );
}

export default MainCarouselBWC;
