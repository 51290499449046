import React from "react";

import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";

import PhoneIcon from "@mui/icons-material/Phone";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import MailIcon from "@mui/icons-material/Mail";

function WhyChoose() {
  return (
    <div className="py-5">
      <h2 className="sub-h-text m-0 text-center">WHY CHOOSE AM MARKETING</h2>
      <div className="py-5">
        <Carousel
          showArrows={true}
          infiniteLoop={true}
          showThumbs={false}
          showStatus={false}
          autoPlay={true}
          interval={6100}
        >
          <div>
            {/* <img
              src={require("../../assets/testimonials/testimonial-2.webp")}
              width={139}
              height={139}
              alt=""
            /> */}
            <div className="myCarousel">
              <h3 className="pt-3">Saqlain Ali</h3>
              <h4 className="pt-1">CEO - SQ Crypto</h4>
              <p>
                From single purchase to multiple purchasing through AM
                Marketing. I have found them very cooperative and honest
                advisor. <b>100% recommended.</b>
              </p>
            </div>
          </div>

          <div>
            {/* <img
              src={require("../../assets/testimonials/testimonial-1.webp")}
              width={139}
              height={139}
              alt=""
            /> */}
            <div className="myCarousel">
              <h3 className="pt-3">Tehmas Khan</h3>
              <h4 className="pt-1">
                Cheif Marketing Officer at Pakistan Chains Management Group
              </h4>
              <p>
                I found them reliable and invested with AM Marketing multiple
                time and planning do more in future.
              </p>
            </div>
          </div>

          <div>
            {/* <img
              src={require("../../assets/testimonials/testimonial-3.webp")}
              width={139}
              height={139}
              alt=""
            /> */}
            <div className="myCarousel">
              <h3 className="pt-3">Abdul Rehman</h3>
              <h4 className="pt-1">Manager at UAE Based Company</h4>
              <p>
                Friend of mine recommended me AM Marketing and i invested with
                them. I would recommend you to get in touch with AM Marketing.
              </p>
            </div>
          </div>

          <div>
            {/* <img
              src={require("../../assets/testimonials/testimonial-4.webp")}
              width={139}
              height={139}
              alt=""
            /> */}
            <div className="myCarousel">
              <h3 className="pt-3">Mukarram Hussain</h3>
              <h4 className="pt-1">
                Sr. Software Engineer at US Based Company
              </h4>
              <p>
                I am happy to secure my investments through AM Marketing and i
                am strongly recommend this company.
              </p>
            </div>
          </div>
        </Carousel>
      </div>
      <h2 className="sub-h-text m-0 text-center">Our clients love us</h2>
      <p className="p-text">We have an average of 9.6</p>
    </div>
  );
}

export default WhyChoose;
