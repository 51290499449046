import React from "react";
import { NavLink } from "react-router-dom";

function Header() {
  return (
    <div className="bg-dark">
      <div className="container">
        <nav className="navbar navbar-expand-lg p-0 navbar-dark bg-dark">
          <div className="container-fluid">
            <h1 className="m-0">
              <a className="navbar-brand" href="/">
                <img
                  src={require("../assets/logo/logo.png")}
                  width={120}
                  alt="al_muqtadir_logo"
                />
              </a>
            </h1>

            <button
              className="navbar-toggler text-light"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <div
              className="collapse navbar-collapse justify-content-end"
              id="navbarSupportedContent"
            >
              <div>
                <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                  <li className="nav-item">
                    <NavLink
                      to="/"
                      className="nav-link"
                      aria-current="page"
                      onClick={() => {
                        document
                          .getElementById("navbarText")
                          .classList.remove("show");
                      }}
                    >
                      Home
                    </NavLink>
                  </li>

                  {/* <li className="nav-item">
                    <NavLink to="videos" className="nav-link">
                      Videos
                    </NavLink>
                  </li> */}
                  <li className="nav-item dropdown">
                    <a
                      className="nav-link dropdown-toggle"
                      id="navbarDropdown"
                      href="#"
                      role="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      Projects
                    </a>
                    <ul
                      className="dropdown-menu bg-dark"
                      aria-labelledby="navbarDropdown"
                    >
                      <li>
                        <NavLink
                          className="dropdown-item"
                          to="/blue-world-city"
                          onClick={() => {
                            document
                              .getElementById("navbarText")
                              .classList.remove("show");
                          }}
                        >
                          Blue World City
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          className="dropdown-item"
                          to="/kingdom-valley"
                          onClick={() => {
                            document
                              .getElementById("navbarText")
                              .classList.remove("show");
                          }}
                        >
                          Kingdom Valley
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          className="dropdown-item"
                          to="/park-view-city"
                          onClick={() => {
                            document
                              .getElementById("navbarText")
                              .classList.remove("show");
                          }}
                        >
                          Park View City
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          className="dropdown-item"
                          to="/al-noor-orchard"
                          onClick={() => {
                            document
                              .getElementById("navbarText")
                              .classList.remove("show");
                          }}
                        >
                          Al-Noor Orchard
                        </NavLink>
                      </li>
                    </ul>
                  </li>
                  {/* <li className="nav-item">
                    <NavLink className="nav-link" to="/offers">
                      Offers
                    </NavLink>
                  </li> */}
                  {/* <li className="nav-item">
                    <NavLink className="nav-link" to="/career">
                      Career
                    </NavLink>
                  </li> */}
                  <li className="nav-item">
                    <NavLink
                      className="nav-link"
                      to="/hot-deals"
                      onClick={() => {
                        document
                          .getElementById("navbarText")
                          .classList.remove("show");
                      }}
                    >
                      Hot Deals
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink
                      className="nav-link"
                      to="/contact"
                      onClick={() => {
                        document
                          .getElementById("navbarText")
                          .classList.remove("show");
                      }}
                    >
                      Contact
                    </NavLink>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </nav>
      </div>
    </div>
  );
}

export default Header;
