import React from "react";

function KVDetails() {
  return (
    <div className="container text-start py-5">
      <div>
        <h2 className="sub-m-h-text py-3">Introduction:</h2>
        <p>
          <b>Kingdom Valley Islamabad</b> is a futuristic state-of-the-art
          housing society situated away from the hustle-bustle of the city{" "}
          <b>located </b> right close to the M-2 Lahore Islamabad Motorway. The
          Kingdom Valley is a flagship project <b>developing </b> by Kingdom
          Group.
        </p>
        <p>
          The Kingdom Valley is being raised in collaboration with the Naya
          Pakistan Housing Scheme. The developers are striving to provide an
          outstanding level of expertise and service at affordable prices.
        </p>
      </div>
      <div>
        <h2 className="sub-m-h-text py-3">
          Kingdom Valley Islamabad Developers & Owners:
        </h2>
        <p>
          The <b>developers </b> & <b>owners </b> of Kingdom Valley Islamabad
          are <b>Kingdom Group.</b> The developers are quite famous for their
          real estate projects. The company has been delivering one-of-a-kind
          residential projects. The developers offer a range of property
          services as follows:
        </p>
        <ul className="li-style-p">
          <li>Sales</li>
          <li>Media</li>
          <li>After-Sales</li>
          <li>Consultancy</li>
          <li>Architecture</li>
          <li>Management</li>
          <li>Construction</li>
          <li>Development</li>
          <li>Digital Marketing</li>
        </ul>
        <p>
          The <b>core values</b> of Kingdom Group:
        </p>
        <ul className="li-style-p">
          <li>Truth</li>
          <li>Skilled</li>
          <li>Team Work</li>
          <li>Responsible</li>
          <li>Understanding</li>
        </ul>
      </div>
      <div>
        <h2 className="sub-m-h-text py-3">Kingdom Valley Islamabad NOC:</h2>
        <p>
          The <b>No Objection Certificate (NOC)</b> of Kingdom Valley Islamabad
          is allegedly <b>approved under Naya Pakistan Housing Scheme</b> with
          registration number <b>“DRG/PHATA/2176-2021”.</b> The approved real
          estate projects have better chances of rapid development.
        </p>
        <div className="text-center m-5">
          <img
            className="img-w-100"
            src={require("../../assets/images/kingdom_valley/kingdom_valley_Islmabad_NOC_1_scaled.jpg")}
            alt="kingdom_valley_Islmabad_NOC_1_scaled"
          />
        </div>
        <div className="text-center m-5">
          <img
            className="img-w-100"
            src={require("../../assets/images/kingdom_valley/kingdom_valley_Islmabad_NOC_1_scaled_1.jpg")}
            alt="kingdom_valley_Islmabad_NOC_1_scaled_1"
          />
        </div>
      </div>
      <div>
        <h2 className="sub-m-h-text py-3">
          Kingdom Valley Islamabad Location:
        </h2>
        <p>
          The location of Kingdom Valley Islamabad is{" "}
          <b>situated right close to the M-2 Lahore Islamabad Motorway.</b>
        </p>
        <div className="text-center m-5">
          <img
            className="img-w-100"
            src={require("../../assets/images/kingdom_valley/kingdom_valley_islamabad_location.jpg")}
            alt="kingdom_valley_islamabad_location"
          />
        </div>
        <p>
          The ideal location of the Kingdom Valley Rawalpindi makes it the most
          easily accessible housing society in Rawalpindi.
        </p>
        <div className="text-center m-5">
          <img
            className="img-w-100"
            src={require("../../assets/images/kingdom_valley/kingdom_valley_islamabad_location_1.jpg")}
            alt="kingdom_valley_islamabad_location_1"
          />
        </div>
        <h2 className="sub-m-h-text py-3">Kingdom Valley Accessibility:</h2>
        <p>The Kingdom Valley is highly accessible.</p>
        <div className="text-center m-5">
          <img
            className="img-w-100"
            src={require("../../assets/images/kingdom_valley/kingdom_valley_accessibility.jpg")}
            alt="kingdom_valley_accessibility"
          />
        </div>
        <p>
          <b>
            The Kingdom Valley Islamabad is accessible in the following ways:
          </b>
        </p>
        <ul className="li-style-p">
          <li>Almost 25 min drive away from International Islamabad Airport</li>
          <li>Located right close to the M-2 Lahore Islamabad Motorway</li>
          <li>Almost 26 min drive away from Rawat-Chakbeli Road</li>
          <li>Almost 25 min drive away from Adiala Road</li>
          <li>Almost 5 min drive away from Chakri Road</li>
        </ul>
      </div>
      <div>
        <h2 className="sub-m-h-text py-3">
          Kingdom Valley Nearby Landmarks & Places:
        </h2>
        <p>
          <b>
            The Kingdom Valley in Rawalpindi is surrounded by several nearby
            landmarks & places:
          </b>
        </p>
        <ul className="li-style-p">
          <li>M-2 Lahore Islamabad Motorway</li>
          <li>International Islamabad Airport</li>
          <li>Qurtaba City</li>
          <li>Islamabad</li>
          <li>Rawalpindi</li>
        </ul>
      </div>
      <div>
        <h2 className="sub-m-h-text py-3">
          Kingdom Valley Islamabad Master Plan:
        </h2>
        <p>
          The master plan of Kingdom Valley Islamabad was designed and developed
          by a team of highly qualified experts with years of property
          development experience. Society is offering all the primary and
          luxurious facilities.
        </p>
        <div className="text-center m-5">
          <img
            className="img-w-100"
            src={require("../../assets/images/kingdom_valley/kingdom_valley_islamabad_master_plan.jpg")}
            alt="kingdom_valley_islamabad_master_plan"
          />
        </div>
        <p>
          The top-quality infrastructure development was ensured by the
          qualified engineers of the company. The Kingdom Valley housing scheme
          Rawalpindi is offering a range of <b>residential plots</b> as follows:
        </p>
      </div>
      <div>
        <h2 className="sub-m-h-text py-3">Kingdom Valley Residential Plots:</h2>
        <p>
          <b>Following are the sizes of the residential plots:</b>
        </p>
        <ul className="li-style-p">
          <li>
            <b>5 Marla</b>
          </li>
          <li>
            <b>7 Marla</b>
          </li>
          <li>
            <b>8 Marla</b>
          </li>
          <li>
            <b>10 Marla</b>
          </li>
          <li>
            <b>1 Kanal</b>
          </li>
          <li>
            <b>2 Kanal</b>
          </li>
        </ul>
      </div>
      <div>
        <h2 className="sub-m-h-text py-3">Kingdom Valley Blocks:</h2>
        <p>
          <b>The Kingdom Valley consists of several Blocks as follows:</b>
        </p>
        <ul className="li-tyle-p">
          <li>
            <b>Residential Block</b>
          </li>
          <li>
            <b>Commercial Block</b>
          </li>
          <li>
            <b>Farmhouse Block</b>
          </li>
          <li>
            <b>Kingdom Villas</b>
          </li>
          <li>
            <b>Executive Block</b>
          </li>
        </ul>
      </div>
      <div>
        <h2 className="sub-m-h-text py-3">Kingdom Valley Residential Block:</h2>
        <p>
          Kingdom Valley Residential Block will provide the residents with all
          the basic and modern facilities and amenities. It is offering
          residential plots of various sizes at extremely affordable rates.
        </p>
        <p>
          <b>Following are the sizes of the residential plots:</b>
        </p>
        <ul className="li-style-p">
          <li>3.5 Marla</li>
          <li>6 Marla</li>
          <li>10 Marla</li>
          <li>20 Marla</li>
        </ul>
      </div>
      <div>
        <h2 className="sub-m-h-text">Kingdom Valley Commercial Block:</h2>
        <p>
          Kingdom Valley Commercial Block is reserved solely for all commercial
          activities. It offers commercial plots at extremely affordable rates.
        </p>
        <p>
          <b>Following are the sizes of the commercial plots:</b>
        </p>
        <ul className="li-style-p">
          <li>4 Marla</li>
          <li>8 Marla</li>
        </ul>
      </div>
      <div>
        <h2 className="sub-m-h-text">Kingdom Farmhouses:</h2>
        <p>
          Kingdom Valley Islamabad also features ‘Kingdom Farmhouses’. The
          farmhouses will provide an elegant and up-to-date lifestyle to the
          residents.
        </p>
        <p>
          <b>The farmhouses are available in the following sizes:</b>
        </p>
        <ul className="li-style-p">
          <li>2 Kanal</li>
          <li>4 Kanal</li>
          <li>8 Kanal</li>
        </ul>
      </div>
      <div>
        <h2 className="sub-m-h-text py-3">Kingdom Villas:</h2>
        <p>
          Kingdom Villas are also being offered in Kingdom Valley Islamabad. The
          villas are built according to modern standards. The villas will
          consist of single-storey and double-storey.
        </p>
        <p>
          <b>The villas are available in the following sizes:</b>
        </p>
        <ul className="li-style-p">
          <li>5 Marla</li>
          <li>3.5 Marla (single-storey)</li>
          <li>3.5 Marla (double-storey)</li>
        </ul>
      </div>
      <div>
        <h2 className="sub-m-h-text py-3">Executive Block:</h2>
        <p>
          The Executive Block facilitates the residents to live a luxurious and
          comfortable life. It offers several residential and commercial plots.
        </p>
        <h2 className="sub-m-h-text py-3">Residential Plots:</h2>
        <p>
          <b>Following are the sizes of the residential plots:</b>
        </p>
        <ul className="li-style-p">
          <li>6 Marla</li>
          <li>8 Marla</li>
          <li>10 Marla</li>
          <li>1 Kanal</li>
        </ul>
        <h2 className="sub-m-h-text py-3">Commercial Plots:</h2>
        <p>
          <b>Following are the sizes of the commercial plots:</b>
        </p>
        <ul className="li-style-p">
          <li>4 Marla</li>
          <li>8 Marla</li>
        </ul>
      </div>
      <div>
        <h2 className="sub-m-h-text py-3">Kingdom Apartments:</h2>
        <p>
          Kingdom Valley intends to launch Kingdom Apartments very soon. The
          apartments will be located within the multi-floor building. The
          Kingdom Apartments payment plan will be issued shortly.
        </p>
        <div className="text-center m-5">
          <img
            className="img-w-100"
            src={require("../../assets/images//kingdom_valley/kingdom_apartments.jpg")}
            alt="kingdom_apartments"
          />
        </div>
        <h2 className="sub-m-h-text py-3">Kingdom Heights:</h2>
        <p>
          Another promising residential project, Kingdom Heights, will be
          launched in Kingdom Valley. Further details regarding the project will
          be shared soon.
        </p>
        <div className="text-center m-5">
          <img
            className="img-w-100"
            src={require("../../assets/images/kingdom_valley/kingdom_heights.jpg")}
            alt="kingdom_heights"
          />
        </div>
        <h2 className="sub-m-h-text py-3">
          Kingdom Valley Islamabad Payment Plan:
        </h2>
        <p>
          The payment plan of Kingdom Valley Islamabad is flexible and
          affordable. The cost-effective rates of the plots make it one of the
          most attractive investment opportunities in the real estate market.
          The plots for sale in Kingdom Valley housing society Rawalpindi are
          open for booking.
        </p>
        <p>
          All you have to do is contact <b>Al-Muqtadir</b> and let us offer the
          best investment opportunities in major cities of Pakistan.{" "}
          <b>
            The management of the Kingdom Valley residential society is offering
            4-yearly installment plans as follows:
          </b>
        </p>
      </div>
      <div>
        <h2 className="sub-m-h-text py-3">
          Kingdom Valley Islamabad Executive Block Residential Plots Payment
          Plan:
        </h2>
        <div className="text-center m-5">
          <img
            className="img-w-100"
            src={require("../../assets/images/kingdom_valley/kingdom_valley_islamabad_payment_plan.jpg")}
            alt="kingdom_valley_islamabad_payment_plan"
          />
        </div>
      </div>
      <div>
        <h2 className="sub-m-h-text py-3">
          Kingdom Valley Islamabad Executive Block Residential Plots New Payment
          Plan:
        </h2>
        <div className="text-center m-5">
          <img
            className="img-w-100"
            src={require("../../assets/images/kingdom_valley/kingdom_valley_islamabad_executive_block_residential_plots_new_payment_plan.jpg")}
            alt="kingdom_valley_islamabad_executive_block_residential_plots_new_payment_plan"
          />
        </div>
      </div>
      <div>
        <h2 className="sub-m-h-text py-3">
          Kingdom Valley Islamabad Executive Block Commercial Plots Payment
          Plan:
        </h2>
        <div className="text-center m-5">
          <img
            className="img-w-100"
            src={require("../../assets/images/kingdom_valley/kingdom_valley_islamabad_executive_block_commercial_plots_payment_plan.jpg")}
            alt="kingdom_valley_islamabad_executive_block_commercial_plots_payment_plan"
          />
        </div>
      </div>
      <div>
        <h2 className="sub-m-h-text py-3">
          Kingdom Valley Islamabad Commercial Plots Payment Plan:
        </h2>
        <div className="text-center m-5">
          <img
            className="img-w-100"
            src={require("../../assets/images/kingdom_valley/kingdom_valley_islamabad_commercial_plots_payment_plan.jpg")}
            alt="kingdom_valley_islamabad_commercial_plots_payment_plan"
          />
        </div>
      </div>
      <div>
        <h2 className="sub-m-h-text py-3">
          Kingdom Valley 3.5 Marla Residential Plots:
        </h2>
        <div className="text-center m-5">
          <img
            className="img-w-100"
            src={require("../../assets/images/kingdom_valley/kingdom_valley_3.5_marla_residential_plots.jpg")}
            alt="kingdom_valley_3.5_marla_residential_plots"
          />
        </div>
      </div>
      <div>
        <h2 className="sub-m-h-text py-3">
          Kingdom Valley Islamabad 4 Marla Residential Plots Payment Plan:
        </h2>
        <div className="text-center m-5">
          <img
            className="img-w-100"
            src={require("../../assets/images/kingdom_valley/kingdom_valley_islamabad_4_marla_residential_plots_payment_plan.jpg")}
            alt="kingdom_valley_islamabad_4_marla_residential_plots_payment_plan"
          />
        </div>
      </div>
      <div>
        <h2 className="sub-m-h-text py-3">
          Kingdom Valley 7 Marla Residential Plots Payment Plan:
        </h2>
        <div className="text-center m-5">
          <img
            className="img-w-100"
            src={require("../../assets/images/kingdom_valley/kingdom_valley_7_marla_residential_plots_payment_plan.jpg")}
            alt="kingdom_valley_7_marla_residential_plots_payment_plan"
          />
        </div>
      </div>
      <div>
        <h2 className="sub-m-h-text py-3">
          Kingdom Valley Islamabad Residential Plots Detailed Payment Plan:
        </h2>
        <div className="text-center m-5">
          <img
            className="img-w-100"
            src={require("../../assets/images/kingdom_valley/kingdom_valley_islamabad_residential_plots_detailed_payment_plan.jpg")}
            alt="kingdom_valley_islamabad_residential_plots_detailed_payment_plan"
          />
        </div>
      </div>
      <div>
        <h2 className="sub-m-h-text py-3">
          Kingdom Valley Islamabad General Block Residential Plots Payment Plan:
        </h2>
        <div className="text-center m-5">
          <img
            className="img-w-100"
            src={require("../../assets/images/kingdom_valley/kingdom_valley_islamabad_general_block_residential_plots_payment_plan.jpg")}
            alt="kingdom_valley_islamabad_general_block_residential_plots_payment_plan"
          />
        </div>
      </div>
      <div>
        <h2 className="sub-m-h-text py-3">
          Kingdom Valley General Block Residential Plots New Payment Plan:
        </h2>
        <div className="text-center m-5">
          <img
            className="img-w-100"
            src={require("../../assets/images/kingdom_valley/kingdom_valley_general_block_residential_plots_new_payment_plan.jpg")}
            alt="kingdom_valley_general_block_residential_plots_new_payment_plan"
          />
        </div>
      </div>
      <div>
        <h2 className="sub-m-h-text py-3">
          Kingdom Valley Farmhouses Payment Plan:
        </h2>
        <div className="text-center m-5">
          <img
            className="img-w-100"
            src={require("../../assets/images/kingdom_valley/kingdom_valley_farmhouses_payment_plan.jpg")}
            alt="kingdom_valley_farmhouses_payment_plan"
          />
        </div>
      </div>
      <div>
        <h2 className="sub-m-h-text py-3">
          Kingdom Valley Kingdom Villas Payment Plan:
        </h2>
        <div className="text-center m-5">
          <img
            className="img-w-100"
            src={require("../../assets/images/kingdom_valley/kingdom_valley_kingdom_villas_payment_plan.jpg")}
            alt="kingdom_valley_kingdom_villas_payment_plan"
          />
        </div>
      </div>
      <div>
        <h2 className="sub-m-h-text py-3">
          Kingdom Valley Facilities & Amenities:
        </h2>
        <p>
          The Kingdom Valley Islamabad is providing all the modern facilities
          and amenities at budget-friendly rates. The facilities are part of any
          contemporary luxurious society that could be termed as a complete
          residential scheme.
        </p>
        <p>
          The developers have reserved a large area for the development of green
          areas such as parks. Kingdom Valley Islamabad is considered the most
          lavish society with all the modern-day luxuries and amenities.
        </p>
        <p>
          The owners’ focus was to offer a healthy and luxurious lifestyle
          environment; for this reason, a large land area is dedicated to the
          central theme park where children and the elder may enjoy leisure time
          with no worries in their minds.{" "}
          <b>
            The facilities in the Kingdom Valley Rawalpindi residential area at
            Rawalpindi are as follows:
          </b>
        </p>
        <h2 className="sub-m-h-text py-3">Eco-Friendly:</h2>
        <p>
          The society will provide an environment-friendly close-to-the-natural
          lifestyle with all the contemporary amenities. The nature-close
          environment will provide a unique experience that could not be found
          in Pakistan’s housing societies.
        </p>
        <h2 className="sub-m-h-text py-3">Grand Mosque:</h2>
        <p>
          Society is going to cater to all the needs of the residents that also
          includes religious needs. For this, the developers are building a
          beautiful grand Jamia mosque. This mosque will be an intelligent
          mosque with all the modern technology and architecture.
        </p>
        <h2 className="sub-m-h-text py-3">Water Resources:</h2>
        <p>
          Society has taken into account the residents’ water needs. For this
          purpose, water reservoirs will be developed to store a large amount of
          water to be used by the resident for daily chores. In addition, filter
          plants will also be a part of society to ensure clean drinking water.
        </p>
        <h2 className="sub-m-h-text py-3">Graveyard:</h2>
        <p>
          The graveyard is an essential part of any society. Life and death are
          part of life; hence, the residents may choose to bury their deceased
          relatives and pray for their eternal life’s success. In addition, they
          may also visit the graves of loved ones within the society
        </p>
        <h2 className="sub-m-h-text py-3">Community Center:</h2>
        <p>
          To keep the residents socially active, community centers will prove as
          the beacon of social life. Here they may enjoy the social activities
          and participate as well.
        </p>
        <h2 className="sub-m-h-text py-3">Health Facilities:</h2>
        <p>
          The developers have paid particular attention to the health facilities
          in the society. For this purpose, the developers will develop
          state-of-the-art international hospitals and clinics. The emergency
          will be open 24/7, the staff and doctors will be available all the
          time.
        </p>
        <h2 className="sub-m-h-text py-3">Education Complex:</h2>
        <p>
          To make sure the provision of international level education to the
          children. The teaching staff will also be professional and trained in
          their specified fields to have the best education. Education is also a
          top priority for the residents in Kingdom Valley Islamabad, for which
          the management has dedicated a large land area to develop a
          world-class education complex.
        </p>
        <h2 className="sub-m-h-text py-3">Business & Commercial Hub:</h2>
        <p>
          The developers have taken care of all the needs of the residents,
          economic & commercial needs. For this reason, society will provide an
          all-in-one commercial area. From these areas, the residents may
          fulfill all the commercial needs from within the society.
        </p>
        <h2 className="sub-m-h-text py-3">Secure Community:</h2>
        <p>
          A sense of safety is necessary for a housing society. A gated
          community ensures safety. A security system with perfectly installed
          24/7 CCTV cameras and other surveillance gear provides all-inclusive
          security to the people. In addition, the society will be surrounded by
          a boundary wall with a foolproof system to provide a high safety
          level.
        </p>
        <h2 className="sub-m-h-text py-3">High-quality Road Infrastructure:</h2>
        <p>
          The roads and other infrastructure are developed with a high degree of
          professionalism and equipment to ensure the perfect development. The
          main boulevard and streets are wide and spacious enough to give away a
          beautiful look.
        </p>
      </div>
      <div>
        <h2 className="sub-m-h-text py-3">Kingdom Valley Salient Features:</h2>
        <p>
          <b>
            Following are the salient features of the Kingdom Valley Islamabad:
          </b>
        </p>
        <ul className="li-style-p">
          <li>Beautiful entrance gate</li>
          <li>Affordability</li>
          <li>Grand Mosque</li>
          <li>Water Resources</li>
          <li>Eco-community</li>
          <li>Education Complex</li>
          <li>Accessibility</li>
          <li>Club House</li>
          <li>Retail Area</li>
          <li>24/7 security</li>
          <li>Maintenance</li>
          <li>Boundary Wall</li>
          <li>Gated community</li>
          <li>Quality Development</li>
          <li>Water, Gas, Electricity</li>
          <li>Underground electrification</li>
          <li>Sewerage and waste disposal system</li>
          <li>World-class infrastructure development</li>
          <li>Sports complex with indoor and outdoor facilities</li>
          <li>
            Medical Facilities offered by Hospitals, dispensaries, and clinics
          </li>
        </ul>
      </div>
      <div>
        <h2 className="sub-m-h-text py-3">
          Guidelines to follow while Purchasing/Sale of
        </h2>
        <h2 className="sub-m-h-text py-3">Plots in Kingdom Valley:</h2>
        <p>
          <b>Following are the guidelines to follow:</b>
        </p>
        <h2 className="sub-m-h-text py-3">Document Verification:</h2>
        <p>
          Please ensure always to verify all the documentation with complete
          satisfaction to avoid any miscommunication or misunderstanding. Before
          getting into any property purchase or sale, make sure to inquire about
          the NOC and approval document by the developers duly approved by the
          authorities. In this way, your documentation will be valid by all
          means that may offer customer satisfaction.
        </p>
        <h2 className="sub-m-h-text py-3">Financial Security:</h2>
        <p>
          Before the conclusion of any sale or purchase of the property, please
          ensure that your funds align with your purchase plan. In this way,
          your purchase or sale will be made without any issue.
        </p>
        <h2 className="sub-m-h-text py-3">Property Visits:</h2>
        <p>
          Before any purchase and after surety of the documents’ validity, visit
          the site to ensure that the written documents are under the plot
          specifications.
        </p>
      </div>
      <div>
        <h2 className="sub-m-h-text py-3">
          Documentation Requirement for Booking:
        </h2>
        <p>
          Al-Muqtadir believes in the bond of trust and transparency with our
          customers, so we recommend enquiring about the latest documentation
          requirement for booking. You can also book residential and commercial
          plots in other housing societies such as <b>Blue World City,</b> Park
          view City, Al-noor Orchid.
        </p>
        <p>
          <b>
            Following documentations are required to buy property in Kingdom
            Valley Islamabad:
          </b>
        </p>
        <ul className="li-style-p">
          <li>Two passport size Pictures</li>
          <li>Two copies of your National Identity Card</li>
          <li>Two copies of the I.D. card of your next of Kin</li>
          <li>NICOP for overseas clientss</li>
        </ul>
      </div>
      <div>
        <h2 className="sub-m-h-text py-3">
          Benefits of Real Estate Investment:
        </h2>
        <p>
          Real estate investment is one of the most profitable businesses among
          others. This is because it has excellent investment return potential.
          But the point to keep in mind is that it will only be profitable when
          conducted correctly.
        </p>
        <p>
          You may also avail huge property benefits by investing in the{" "}
          <b>Mall of Islamabad</b> and <b>Florence Galleria.</b> One can gain
          several kinds of benefits from investment in the real estate industry.{" "}
          <b>Some of the main benefits are as follows:</b>
        </p>
        <h2 className="sub-m-h-text py-3">1. Financial Security:</h2>
        <p>
          If a person has some extra amount or someone has some asset, and he
          wants to keep the value of that asset high, then indeed, the real
          estate industry is the sector that can keep the value of investment
          high and not depreciate.
        </p>
        <h2 className="sub-m-h-text py-3">2. Stable Income:</h2>
        <p>
          A person can generate a stable income with investment in real estate
          quickly. For example, one can buy a property, develop it, buy a
          developed property, and rent it monthly or yearly. Not just this, the
          rent increases every year by some percentage, so it would not be wrong
          to say that it gets better than stable.
        </p>
        <h2 className="sub-m-h-text py-3">3. Manageable:</h2>
        <p>
          Once an investment is concluded, it’s not like some living thing you
          have to look after daily. The maximum you will have to do is visit
          your property every while to ensure it does not fall into the wrong
          hands of encroachers.
        </p>
        <h2 className="sub-m-h-text py-3">4. Passive Income:</h2>
        <p>
          Once someone has invested in a real estate property, then passive
          income like rent could be generated. Investors buy a developed
          property or develop an open land and then rent it out, ensuring a
          steady source generation source throughout the year, uninterruptedly.
        </p>
        <h2 className="sub-m-h-text py-3">5. Non-depreciable Asset:</h2>
        <p>
          Real estate investment is a sort of business that will never lose its
          value once done rightly. Real estate value always increases every
          year. And if some significant government or private project is started
          in its vicinity, then the value may increase manifold.
        </p>
      </div>
      <div>
        <h2 className="sub-m-h-text py-3">
          Booking Procedure for Property in Kingdom Valley Islamabad:
        </h2>
        <p>
          Al-Muqtadir always advises our respected customers that inquire about
          the latest booking procedure from management to clear out any
          communication. The booking procedure is as simple as possible.
        </p>
        <p>
          <b>
            You may follow the same procedure for Nova City Islamabad and Blue
            Town Sapphire Lahore. All you have to do is follow the steps
            mentioned below:
          </b>
        </p>
        <ul className="li-style-p">
          <li>Fill your booking application form with full attention</li>
          <li>Attach the CNIC Copies of Applicant</li>
          <li>
            Pay Down Payment via Cheque or Pay order in favor of{" "}
            <b>“Kingdom Valley,”</b> but confirm process from the management in
            case of new changes
          </li>
          <li>
            Cash Payments are also accepted after confirmation by the management
          </li>
          <li>
            Submit all the required documents, Payment, and Get the receipt
          </li>
        </ul>
      </div>
    </div>
  );
}

export default KVDetails;
