import React from "react";

function AlNoorOrchardDetails() {
  return (
    <div className="container text-start py-5">
      <div>
        <h2 className="sub-m-h-text py-3">Introduction:</h2>
        <p>
          <b>Al Noor Orchard Lahore</b> is an <b>LDA approved</b> beautiful
          housing society developed by globally well-known Al-Jalil developers.
          It is located on main Bazar Sharaqpur Shareef Road opposite to
          Al-Raziq Garden, main Jaranwala Road, Lahore.
        </p>
        <p>
          It is an affordable & wondrous residential project spanning over{" "}
          <b>40 acres of premium land.</b> It is a gated community with
          luxurious facilities and amenities at the doorsteps of residents.{" "}
          <b>The balloting was organized in September 2018.</b>
        </p>
        <p>
          It was designed and developed with just one goal in mind and that was
          to provide a modern and high-tech lifestyle to the investors. People
          can buy a property with easy payment plans.
        </p>
      </div>
      <div>
        <h2 className="sub-m-h-text py-3">
          Al-Noor Orchard Owners and Developers:
        </h2>
        <p>
          <b>Al-Jalil developers</b> are the developers of this famous
          residential society in Lahore.{" "}
          <b>Al-Jail developers were founded by Nasrullah Khan in 2002.</b> The
          main emphasis of Al-Jalil was to develop a community that is a true
          depiction of modern society in the 21st century. The infrastructure
          development was conducted with highly experienced architects and civil
          engineers.
        </p>
        <p>
          The investors have liked the society and placed their trust in the
          Al-Jalil developers for providing one-of-a-kind housing projects. As
          they have previously constructed <b>Al-Jalil Garden,</b> which was a
          tremendous success and highly praised by the keen investors.
        </p>
        <p>
          All the globally applied urban town planning principles were taken
          into account while designing and developing the Al Noor Orchard. The
          best professionals were hired to maintain the high standards that the
          developers always promise.
        </p>
        <div className="text-center m-5">
          <img
            className="img-w-100"
            src={require("../../assets/images/al_noor_orchard/al_noor_orchard_owners_and_developers.jpg")}
            alt="al_noor_orchard_owners_and_developers"
          />
        </div>
      </div>
      <div>
        <h2 className="sub-m-h-text py-3">Al-Noor Orchard Lahore NOC:</h2>
        <p>
          The Al-Noor Orchard Lahore`s management acquired approved{" "}
          <b>No Objection Certificate NOC #LDA/DMP1/SKP/913</b> from Lahore
          Development Authority (LDA). The{" "}
          <b>NOC was approved in October 2019</b> and balloting for the society
          was conducted in September 2018.{" "}
          <b>Around 150-200 plots were balloted in Blocks A and B.</b>
        </p>
        <p>
          The NOC enabled the developers to begin with the work on
          infrastructure development. You can also access the officially
          approved NOC from the LDA Website
        </p>
        <div className="text-center m-5">
          <img
            className="img-w-100"
            src={require("../../assets/images/al_noor_orchard/al_noor_orchard_lahore_noc.jpg")}
            alt="al_noor_orchard_lahore_noc"
          />
        </div>
      </div>
      <div>
        <h2 className="sub-m-h-text py-3">Al Noor Orchard Lahore Location:</h2>
        <p>
          <b>The location of Al-Noor Orchard Lahore</b> is located at one of the
          most prime locality in Lahore. It is right opposite to the Faizpur
          Interchange, opposite to the Al-Raziq Garden with just a few minutes’
          drive from Al-Jalil Garden.
        </p>
        <div className="text-center m-5">
          <img
            className="img-w-100"
            src={require("../../assets/images/al_noor_orchard/al_noor_orchard_lahore_location.jpg")}
            alt="al_noor_orchard_lahore_location"
          />
        </div>
        <p>
          It is situated at Main Bazar Sharaqpur Sharif Road that links Lahore
          to Jaranwala. It is perfectly located at the junction of
          Karachi-Lahore Motorway and Faizpur Interchange. It is also very close
          to Babu Sabu Interchange and Ravi Tool Plaza is just 1.3 kilometres
          away.
        </p>
        <div className="text-center m-5">
          <img
            className="img-w-100"
            src={require("../../assets/images/al_noor_orchard/al_noor_orchard_lahore_location_1.jpg")}
            alt="al_noor_orchard_lahore_location_1"
          />
        </div>
      </div>
      <div>
        <h2 className="sub-m-h-text py-3">Accessibility:</h2>
        <p>
          <b>Al-Noor Orchard is accessible in the following ways:</b>
          <ul className="li-style-p">
            <li>Right next to the Faizpur Interchange.</li>
            <li>Approximately 8 min drive to M-2 Motorway</li>
          </ul>
        </p>
        <div className="text-center m-5">
          <img
            className="img-w-100"
            src={require("../../assets/images/al_noor_orchard/al_noor_orchard_is_accessible.jpg")}
            alt="al_noor_orchard_is_accessible"
          />
        </div>
        <ul className="li-style-p">
          <li>Approximately 8 min drive to M-3 Abdul Hakeem Motorway</li>
          <li>Approximately 9 min drive to Sagian Wala Bypass Rd</li>
          <li>Approximately 12 min drive to Lahore – Sargodha Rd</li>
          <li>Approximately 7 min drive to Lahore-Jaranwala Rd</li>
        </ul>
      </div>
      <div>
        <h2 className="sub-m-h-text py-3">Nearby Landmarks & Places:</h2>
        <p>
          <b>
            Following are the nearby locations and famous places to this
            society:
          </b>
          <ul className="li-style-p">
            <li>Shahdara Town</li>
            <li>Shahdara Railway Station</li>
            <li>Badshahi Mosque</li>
          </ul>
          <div className="text-center m-5">
            <img
              className="img-w-100"
              src={require("../../assets/images/al_noor_orchard/nearby_landmarks_&_places.jpg")}
              alt="nearby_landmarks_&_places"
            />
          </div>
          <ul className="li-style-p">
            <li>Data Darbar</li>
            <li>Badami Bagh</li>
            <li>Walled City of Lahore</li>
            <li>Urdu Bazar</li>
            <li>Railway Colony</li>
            <li>Garhi Shahu</li>
            <li>Gulshan e Ravi</li>
            <li>Samnabad Town</li>
            <li>Ferozwala</li>
            <li>Lahore Cantt</li>
            <li>Allama Iqbal International Airport</li>
          </ul>
        </p>
      </div>
      <div>
        <h2 className="sub-m-h-text py-3">
          Al-Noor Orchard Lahore Master Plan:
        </h2>
        <p>
          With the experience of around two decades, Al-Jalil developers have
          come up with this housing society in the heart of Lahore city. The
          location is supreme and the plots for sale are at amazingly affordable
          prices by any person from humble financial status.
        </p>
        <div className="text-center m-5">
          <img
            className="img-w-100"
            src={require("../../assets/images/al_noor_orchard/al_noor_orchard_lahore_master_plan.jpg")}
            alt="al_noor_orchard_lahore_master_plan"
          />
        </div>
        <p>
          This 40 acres of premium affordable land is further divided into
          several blocks. Blocks are designed to offer plots sizes of 3, 5, 10
          Marla, and 1 Kanal. 4 Marla commercial plots are also available, along
          with 3.5 and 5 Marla homes
        </p>
        <p>
          A theme park is also part of the plan that will span over 140 Kanal
          land areas along with a beautiful Masjid, medical facilities,
          educational complex and Egyptian themed health care center. The lush
          green parks are also part of the master plan to provide a healthy
          lifestyle.
        </p>
      </div>
      <div>
        <h2 className="sub-m-h-text py-3">
          Al-Noor Orchard Residential Blocks:
        </h2>
        <p>
          Al-Noor Orchard is a developed housing society to provide luxuriously
          plots land area. The proposed date for the Ballot announcement of
          Block was 1st February 2021. The society is divided into the following
          Blocks to better manage and offer different sizes of plots with
          diverse features:
        </p>
        <ul className="li-style-p">
          <li>Block A</li>
          <li>Block B</li>
          <li>Block C</li>
          <li>Block D</li>
        </ul>
        <div className="text-center m-5">
          <img
            className="img-w-100"
            src={require("../../assets/images/al_noor_orchard/al_noor_orchard_residential_blocks.jpg")}
            alt="al_noor_orchard_residential_blocks"
          />
        </div>
      </div>
      <div>
        <h2 className="sub-m-h-text py-3">Residential Plots Size:</h2>
        <p>
          <b>
            Following are the sizes of residential plots in Al-Noor Orchard
            Lahore:
          </b>
        </p>
        <ul className="li-style-p">
          <li>3 Marla</li>
          <li>5 Marla</li>
          <li>10 Marla</li>
          <li>1 Kanal</li>
        </ul>
      </div>
      <div>
        <h2 className="sub-m-h-text py-3">
          Al Noor Orchards Residential Plot Size Block-A:
        </h2>
        <div className="text-center m-5">
          <img
            className="img-w-100"
            src={require("../../assets/images/al_noor_orchard/al_noor_orchards_residential_plot_size_block_a.jpg")}
            alt="al_noor_orchards_residential_plot_size_block_a"
          />
        </div>
      </div>
      <div>
        <h2 className="sub-m-h-text py-3">
          Al Noor Orchards Residential Plot Size Block-B:
        </h2>
        <div className="text-center m-5">
          <img
            className="img-w-100"
            src={require("../../assets/images/al_noor_orchard/al_noor_orchards_residential_plot_size_block_b.jpg")}
            alt="al_noor_orchards_residential_plot_size_block_b"
          />
        </div>
      </div>
      <div>
        <h2 className="sub-m-h-text py-3">Al-Noor Orchard Commercial Plots:</h2>
        <p>Following are the sizes of commercial plots in AL-Noor Orchard:</p>
        <ul className="li-style-p">
          <li>4 Marla</li>
        </ul>
        <div className="text-center m-5">
          <img
            className="img-w-100"
            src={require("../../assets/images/al_noor_orchard/al_noor_orchard_commercial_plots.jpg")}
            alt="al_noor_orchard_commercial_plots"
          />
        </div>
      </div>
      <div>
        <h2 className="sub-m-h-text py-3">
          Al Noor Orchards Commercial Plot Size Block-B:
        </h2>
        <div className="text-center m-5">
          <img
            className="img-w-100"
            src={require("../../assets/images/al_noor_orchard/al_noor_orchards_commercial_plot_size_block_b.jpg")}
            alt="al_noor_orchards_commercial_plot_size_block_b"
          />
        </div>
      </div>
      <div>
        <h2 className="sub-m-h-text py-3">
          Marina Sports City Residential Plots:
        </h2>
        <p>
          The Al Noor Orchard’s new block, Marina Sports City offers residential
          plots of the following sizes:
        </p>
        <ul className="li-style-p">
          <li>3 Marla</li>
          <li>5 Marla</li>
          <li>10 Marla</li>
          <li>1 Kanal</li>
        </ul>
      </div>
      <div>
        <h2 className="sub-m-h-text py-3">Marina Sports City Residencia:</h2>
        <p>
          Marina Sports City Residencia is the new addition to the real estate
          of Lahore. <b>It offers residential plots of the following sizes:</b>
        </p>
        <ul className="li-style-p">
          <li>3 Marla</li>
          <li>5 Marla</li>
          <li>10 Marla</li>
          <li>20 Marla / 1 Kanal</li>
        </ul>
      </div>
      <div>
        <h2 className="sub-m-h-text py-3">
          Al-Noor Orchard Lahore Payment Plans:
        </h2>
        <p>
          The payment plans of Al-Noor Orchard are flexible and easy to afford.
          The housing project offers different types of plots under residential
          and commercial category.
        </p>
      </div>
      <div>
        <h2 className="sub-m-h-text py-3">
          Al-Noor Orchard Lahore Residential Payment Plan:
        </h2>
        <p>Following is the residential payment plan of Al-Noor Orchards:</p>
        <div className="text-center m-5">
          <img
            className="img-w-100"
            src={require("../../assets/images/al_noor_orchard/al_noor_orchard_lahore_residential_payment_plan.jpg")}
            alt="al_noor_orchard_lahore_residential_payment_plan"
          />
        </div>
      </div>
      <div>
        <h2 className="sub-m-h-text py-3">
          Al-Noor Orchard West Marina Block Details:
        </h2>
        <p>
          Al-Noor Orchards has brought a one-of-a-kind housing society that
          every person wishes for. West Marina Block has become the center of
          attraction as compared to the rest of the Blocks. Limited plots are
          offered in this Block.
        </p>
        <p>
          The plot sizes are 5, 10.11 Marla, 1 Kanal, and 2 Kanal. It is
          offering a flexible 5-year payment plan with affordable prices.
        </p>
        <div className="text-center m-5">
          <img
            className="img-w-100"
            src={require("../../assets/images/al_noor_orchard/al_noor_orchard_west_marina_block_details.jpg")}
            alt="al_noor_orchard_west_marina_block_details"
          />
        </div>
      </div>
      <div>
        <h2 className="sub-m-h-text py-3">
          Al-Noor Orchard West Marina Block Cottages & Villas Details:
        </h2>
        <p>
          The Al-Noor Orchard West Marina Block offers various sizes of cottages
          and villas.
        </p>
        <div className="text-center m-5">
          <img
            className="img-w-100"
            src={require("../../assets/images/al_noor_orchard/al_noor_orchard_west_marina_block_cottages_&_villas_details.jpg")}
            alt="al_noor_orchard_west_marina_block_cottages_&_villas_details"
          />
        </div>
      </div>
      <div>
        <h2 className="sub-m-h-text py-3">
          Al-Noor Orchard Villas Payment Plan:
        </h2>
        <p>
          Al-Noor Orchard is offering premium location luxurious villas on an
          easy 5-year installment plan. The plot sizes are 3 and 5 Marla. The
          details are as follows:
        </p>
        <div className="text-center m-5">
          <img
            className="img-w-100"
            src={require("../../assets/images/al_noor_orchard/al_noor_orchard_villas_payment_plan.jpg")}
            alt="al_noor_orchard_villas_payment_plan"
          />
        </div>
        <p>
          Note: It is important to mention here that the real estate property
          prices never remain the same.
        </p>
      </div>
      <div>
        <h2 className="sub-m-h-text py-3">
          Al Noor Orchard West Marina Block Central Commercial Area Payment
          Plan:
        </h2>
        <p>
          The payment plan for West Marina Block Central Commercial Area is as
          follows:
        </p>
        <div className="text-center m-5">
          <img
            className="img-w-100"
            src={require("../../assets/images/al_noor_orchard/al_noor_orchard_west_marina_block_central_commercial_area_payment_plan.jpg")}
            alt="al_noor_orchard_west_marina_block_central_commercial_area_payment_plan"
          />
        </div>
      </div>
      <div>
        <h2 className="sub-m-h-text py-3">
          Marina Sports City Residential Plots Payment Plan:
        </h2>
        <p>
          The payment plan of the Marina Sports City Residential Plots is as
          follows:
        </p>
        <div className="text-center m-5">
          <img
            className="img-w-100"
            src={require("../../assets/images/al_noor_orchard/marina_sports_city_residential_plots_payment_plan.jpg")}
            alt="marina_sports_city_residential_plots_payment_plan"
          />
        </div>
      </div>
      <div>
        <h2 className="sub-m-h-text py-3">
          Marina Sports City Residencia Payment Plan:
        </h2>
        <p>
          <b>
            The payment plan of Marina Sports City Residencia is as follows:
          </b>
        </p>
        <div className="text-center m-5">
          <img
            className="img-w-100"
            src={require("../../assets/images/al_noor_orchard/marina_sports_city_residencia_payment_plan.jpg")}
            alt="marina_sports_city_residencia_payment_plan"
          />
        </div>
      </div>
      <div>
        <h2 className="sub-m-h-text py-3">
          Al-Noor Orchards Residential & Commercial Plot Prices Market Trend:
        </h2>
        <p>Plots for sale in Al Noor Orchards are as follows:</p>
        <div className="text-center m-5">
          <img
            className="img-w-100"
            src={require("../../assets/images/al_noor_orchard/al_noor_orchards_residential_&_commercial_plot_prices_market_trend.jpg")}
            alt="al_noor_orchards_residential_&_commercial_plot_prices_market_trend"
          />
        </div>
      </div>
      <div>
        <h2 className="sub-m-h-text py-3">Facilities & Amenities:</h2>
        <p>
          Al-Noor Orchard Lahore is a planned housing society that offers all
          the modern facilities and amenities with highly efficient systems. The
          high level of luxurious facilities are matchless in Pakistan. This
          gated community is truly a blessing for the people of Lahore. All the
          amenities are offered by the dedication and hard work of the esteemed
          developers. Engineers and developers are working day and night to
          fulfill their promise. It is designed to provide a chance for every
          class of family to live an affordable and luxurious life. Following
          are some of the high-end facilities:
        </p>
      </div>
      <div>
        <h2 className="sub-m-h-text py-3">Jamia Mosque:</h2>
        <p>
          This society will encompass every need of the residents, so as
          religious ones. For this purpose, the developers and planners have
          developed a main Jamia mosque to accommodate the spiritual need of the
          residents. In this Jamia mosque, individuals may grow a strong
          connection with ALLAH by offering their prayers within their society.
        </p>
        <div className="text-center m-5">
          <img
            className="img-w-100"
            src={require("../../assets/images/al_noor_orchard/jamia_mosque.jpg")}
            alt="jamia_mosque"
          />
        </div>
      </div>
      <div>
        <h2 className="sub-m-h-text py-3">Gated Community:</h2>
        <p>
          A sense of safety is important for a real estate residential society.
          Society has provided a gated community that ensures a safety factor.
        </p>
        <div className="text-center m-5">
          <img
            className="img-w-100"
            src={require("../../assets/images/al_noor_orchard/gated_community.jpg")}
            alt="gated_community"
          />
        </div>
        <p>
          A security mechanism with professionally installed 24/7 CCTV cameras
          and other surveillance equipment provides comprehensive and quick
          safety to the people.
        </p>
        <div className="text-center m-5">
          <img
            className="img-w-100"
            src={require("../../assets/images/al_noor_orchard/security.jpg")}
            alt="security"
          />
        </div>
        <p>
          The society is surrounded by a boundary wall with a foolproof
          mechanism to ensure the maximum level of safety for the residents.
        </p>
        <div className="text-center m-5">
          <img
            className="img-w-100"
            src={require("../../assets/images/al_noor_orchard/society_is_surrounded.jpg")}
            alt="society_is_surrounded"
          />
        </div>
      </div>
      <div>
        <h2 className="sub-m-h-text py-3">Wide Carpeted Road:</h2>
        <p>
          The roads and other relevant infrastructure is developed with
          professionalism and high-tech equipment to maintain the perfect
          development. This was made possible by the commitment of the
          developers and the engineers who have worked throughout the
          development time intending to provide a modern residential society.
        </p>
      </div>
      <div>
        <h2 className="sub-m-h-text py-3">
          Dedicated Stand by Power Supply & Gas provision:
        </h2>
        <p>
          With the current situation of load shedding especially in the summers,
          the power backup systems and uninterrupted power supply are necessary
          to fulfill the promise of a modern housing society in Lahore in the
          form of Al-Noor Orchards.
        </p>
        <div className="text-center m-5">
          <img
            className="img-w-100"
            src={require("../../assets/images/al_noor_orchard/dedicated_stand_by_power_supply_&_gas_provision.jpg")}
            alt="dedicated_stand_by_power_supply_&_gas_provision"
          />
        </div>
        <p>
          The developers have ensured the aesthetics of the society by providing
          the basic utilities from laying the lines underground. This makes
          society safe and beautiful. Because the modern housing society with
          old-fashioned poles and overhead dangling wires give away a bulky and
          unpleasant look.
        </p>
        <div className="text-center m-5">
          <img
            className="img-w-100"
            src={require("../../assets/images/al_noor_orchard/underground_electric.jpg")}
            alt="underground_electric"
          />
        </div>
        <p>
          Gas is another basic utility that is the need of every household. The
          society has timely provided the gas infrastructure to the doorsteps of
          the residents to make sure the provision of luxurious life with all
          the facilities and amenities.
        </p>
        <div className="text-center m-5">
          <img
            className="img-w-100"
            src={require("../../assets/images/al_noor_orchard/gas.jpg")}
            alt="gas"
          />
        </div>
      </div>
      <div>
        <h2 className="sub-m-h-text py-3">Lush Green Parks:</h2>
        <p>
          Society`s developers have made sure that the residents will live a
          luxurious life with a strong and close bond with nature and for this
          public recreational parks are developed. In these pars families and
          children may spend their leisure time and live a happy healthy life.
        </p>
        <div className="text-center m-5">
          <img
            className="img-w-100"
            src={require("../../assets/images/al_noor_orchard/lush_green_parks.jpg")}
            alt="lush_green_parks"
          />
        </div>
      </div>
      <div>
        <h2 className="sub-m-h-text py-3">Community Clubs & Mini Zoo:</h2>
        <p>
          The society is offering a great community club for residents to gather
          at occasions and perform social activities that itself is a healthy
          activity for the residents. It will provide the residents an
          opportunity to meet with each other, create a bond and become a family
          who takes care of each other.
        </p>
        <div className="text-center m-5">
          <img
            className="img-w-100"
            src={require("../../assets/images/al_noor_orchard/community_clubs_&_mini_zoo.jpg")}
            alt="community_clubs_&_mini_zoo"
          />
        </div>
      </div>
      <div>
        <h2 className="sub-m-h-text py-3">
          State-of-the-Art Waste Management System:
        </h2>
        <p>
          A failed waste disposal system completely fails a housing society. The
          disposal mechanism of the society is designed with the capability to
          cope up with all the heavy waste load of the society. This will be a
          highly efficient system with high-tech equipment and vehicles.
        </p>
        <div className="text-center m-5">
          <img
            className="img-w-100"
            src={require("../../assets/images/al_noor_orchard/state_of_the_art_waste_management_system.jpg")}
            alt="state_of_the_art_waste_management_system"
          />
        </div>
      </div>
      <div>
        <h2 className="sub-m-h-text py-3">Water Filtration Plant:</h2>
        <p>
          A luxurious lifestyle requires premium quality living amenities. This
          is the reason that the developers and planners have incorporated the
          facility of the purest form of drinking water through the water
          filtration process from the water filtration plant in the society.
        </p>
        <div className="text-center m-5">
          <img
            className="img-w-100"
            src={require("../../assets/images/al_noor_orchard/water_filtration_plant.jpg")}
            alt="water_filtration_plant"
          />
        </div>
      </div>
      <div>
        <h2 className="sub-m-h-text py-3">Healthcare Facilities:</h2>
        <p>
          This will be like no other society like its medical facilities.
          Society is going to provide the best healthcare services to its
          residents. These facilities will be state of the art. The doctors and
          non-medical staff will be highly trained to offer top-notch treatment
          24/7.
        </p>
        <div className="text-center m-5">
          <img
            className="img-w-100"
            src={require("../../assets/images/al_noor_orchard/healthcare_facilities.jpg")}
            alt="healthcare_facilities"
          />
        </div>
        <p>
          These hospitals, labs, and clinics are capable to treat several
          patients and do it efficiently with the help of trained staff and
          medical specialists. Workload and patient load will not be an issue in
          the hospital of Blue Town Sapphire Lahore.
        </p>
      </div>
      <div>
        <h2 className="sub-m-h-text py-3">Salient Features:</h2>
        <p>
          <b>Salient features of the society are the following:</b>
        </p>
        <ul className="li-style-p">
          <li>Beautiful main entrance.</li>
          <li>Gated Community and Boundary Wall around the society</li>
          <li>Street lights</li>
          <li>Main Jamia Mosque</li>
          <li>Recreational areas like public parks and also Green Belts</li>
          <li>
            24/7 state-of-the-art security systems as well as CCTV Cameras
          </li>
          <li>Underground 24/7 Provision of Electricity, Water, and Gas</li>
          <li>
            Clean Drinking Water is also to be provided through a dedicated
            water filtration plant
          </li>
          <li>Play Land for Kids as well as Lush Green Parks</li>
          <li>Sewerage system with wide underground sewerage pipelines</li>
          <li>Garbage disposal and management system</li>
          <li>Sports areas for the healthy activities of the residents</li>
          <li>
            Distinct Commercial Areas to fulfill the economic needs of the
            inhabitants
          </li>
          <li>
            Medical & Educational Centers, like pharmacies and schools, are also
            part of the plan
          </li>
        </ul>
      </div>
      <div>
        <h2 className="sub-m-h-text py-3">
          Guidelines to follow while Purchasing/Sale of Plots in Al-Noor
          Orchard:
        </h2>
        <p>
          <b>Following are the guidelines to follow:</b>
        </p>
        <h2 className="sub-m-h-text py-3">Document Verification:</h2>
        <p>
          Please ensure, to always verify all the documentation with complete
          satisfaction to avoid any miscommunication or misunderstanding. Before
          getting into any purchase or sale of the property make sure to inquire
          about the NOC and approval document by the developers duly approved by
          the LDA. In this way, your documentation will be valid by all means
          that may offer customer satisfaction.
        </p>
        <h2 className="sub-m-h-text py-3">Financial Security:</h2>
        <p>
          Before the conclusion of any transaction of any sale or purchase of
          the property, please make sure that your funds are in line with your
          purchase plan. In this way, your purchase or sale will be done without
          any issue.
        </p>
        <h2 className="sub-m-h-text py-3">Property Visits:</h2>
        <p>
          Before any purchase and after surety of the validity of documents, do
          visit the site on the ground to ensure that the written documents are
          under the plot specifications on the ground.
        </p>
        <h2 className="sub-m-h-text py-3">
          Documentation Requirement for booking:
        </h2>
        <p>
          <b>
            Following documentation is required to buy property Al-Noor Orchard:
          </b>
        </p>
        <ul className="li-style-p">
          <li>2 passport size Pictures</li>
          <li>2 copies of your national ID card</li>
          <li>2 copies of the ID card of your next of Kin</li>
          <li>NICOP for overseas clients</li>
        </ul>
        <h2 className="sub-m-h-text py-3">
          Benefits of Real Estate Investment:
        </h2>
        <p>
          Real estate investment is one of the most lucrative businesses among
          other businesses. It has huge success potential. But the point to keep
          in mind is that it will only be profitable when done correctly. One
          can gain numerous benefits from investment in the real estate sector.
          Some of the main benefits are as follows:
        </p>
        <h2 className="sub-m-h-text py-3">1. Financial Security:</h2>
        <p>
          If a person has some extra amount or someone has some asset and he
          wants to keep the value of that asset high then surely real is the
          sector that can keep the value of investment high and does not
          depreciates. As mentioned earlier Gold and property are commodities
          when purchased correctly may never lose their value.
        </p>
        <h2 className="sub-m-h-text py-3">2. Stable Income:</h2>
        <p>
          A person can generate a stable income with investment in real estate
          easily. One can buy a property develop it or buy a developed property
          and rent it out on a monthly or yearly or quarterly basis. Not just
          this, the rent increases every year by some percentage so it would not
          be wrong to say that it gets better than stable.
        </p>
        <h2 className="sub-m-h-text py-3">3. Manageable:</h2>
        <p>
          Once an investment is concluded then it’s not like some living thing
          that you have to look after it on daily basis. The maximum you will
          have to do is visit your property every while to ensure it does not
          into the wrong hands of encroachers.
        </p>
        <h2 className="sub-m-h-text py-3">4. Passive Income:</h2>
        <p>
          Real estate investment is a sort of business that will never lose its
          value, once done rightly. Real estate value always increases every
          year. And if some major government or private project is started in
          its vicinity then the value may increase manifold.
        </p>
        <h2 className="sub-m-h-text py-3">
          Why Invest in Al-Noor Orchard Lahore?
        </h2>
        <p>
          It is an extremely perfectly planned residential society striving to
          provide a luxurious and eco-friendly environment to its residents.
          Environment and location is always the key factor for any society.
          This beautiful housing society has approval from LDA.
        </p>
        <p>
          It is situated in Lahore city and has easy accessibility that makes it
          an easily accessed premium location housing community. The prime area,
          fast-paced construction, and high-end development have provided a
          beautiful and attractive reality for investors.
        </p>
        <p>
          The main fascination in this town is its quality infrastructure and
          the tranquility that appropriately marks it as a dream living place in
          Pakistan. The price of the residential and commercial plots is also
          set in a manner that could be availed by even families from humble
          backgrounds.
        </p>
      </div>
      <div>
        <h2 className="sub-m-h-text py-3">Pros & Cons:</h2>
        <p>
          <b>The pros and cons are the following:</b>
        </p>
      </div>
    </div>
  );
}

export default AlNoorOrchardDetails;
