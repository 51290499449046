import React from "react";
import { Link } from "react-router-dom";

function OurProjects() {
  return (
    <div className="bg-dark bg-project">
      <div className="container py-5">
        <h2 className="sub-h-text m-0 text-center pb-3">
          Best Investment Opportunity
        </h2>
        <div className="row row-cols-1 row-cols-md-2 g-4">
          <div className="col">
            <Link to="/blue-world-city" className="nav-link-our-project">
              <div className="card border-0">
                <img
                  src={require("../../assets/carousel/carousel-img-1.jpg")}
                  width="100%"
                  height="160px"
                  alt="blue_world_city"
                />
                <div className="card-body bg-golden p-2">
                  <h5 className="card-title m-0">BLUE WORLD CITY</h5>
                </div>
              </div>
            </Link>
          </div>
          <div className="col">
            <Link to="/kingdom-valley" className="nav-link-our-project">
              <div className="card border-0">
                <img
                  src={require("../../assets/carousel/carousel-img-2.jpg")}
                  width="100%"
                  height="160px"
                  alt="kingdom_valley_islamabad"
                />
                <div className="card-body bg-golden p-2">
                  <h5 className="card-title m-0">KINGDOM VALLEY ISLAMABAD</h5>
                </div>
              </div>
            </Link>
          </div>
          <div className="col">
            <Link to="/park-view-city" className="nav-link-our-project">
              <div className="card border-0">
                <img
                  src={require("../../assets/carousel/carousel-img-4.jpg")}
                  width="100%"
                  height="160px"
                  alt="park_view_city"
                />
                <div className="card-body bg-golden p-2">
                  <h5 className="card-title m-0">PARK VIEW CITY ISLAMABAD</h5>
                </div>
              </div>
            </Link>
          </div>
          <div className="col">
            <Link to="/al-noor-orchard" className="nav-link-our-project">
              <div className="card border-0">
                <img
                  src={require("../../assets/carousel/carousel-img-3.jpeg")}
                  width="100%"
                  height="160px"
                  alt="al_noor_orchard"
                />
                <div className="card-body bg-golden p-2">
                  <h5 className="card-title m-0">AL-NOOR ORCHARD</h5>
                </div>
              </div>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default OurProjects;
