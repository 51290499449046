import React from "react";
import ContactDetails from "../components/contactUs/ContactDetails";
import ContactForm from "../components/contactUs/ContactForm";

import "./index.css";

function Contact() {
  return (
    <div className="py-5 bg-contact">
      <div className="container">
        <div className="row w-100">
          <div className="col-sm-4">
            <ContactDetails />
          </div>
          <div className="col-sm-8">
            <ContactForm />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Contact;
