import React from "react";

function PVCDetails() {
  return (
    <div className="container text-start py-5">
      <div>
        <h2 className="sub-m-h-text py-3">Introduction:</h2>
        <p>
          <b>Park View City </b> is a CDA Approved, beautiful residential
          project by <b>Vision Group in zone IV of Islamabad.</b> The housing
          society has a 400 ft wide Main Boulevard which grants direct access
          from the highway into the society, furthermore, you can also get
          access via Kurri Road and Banigala. This housing venture is popular
          among investors due to its scenic views and high-end facilities. It is
          planned to provide a perfect place for families who want to live in a
          family-friendly neighbourhood and a secure place with a futuristic
          vision and exquisite views.
        </p>
      </div>
      <div>
        <h2 className="sub-m-h-text py-3">
          Park View City Owners and Developers:
        </h2>
        <p>
          Designed by modern architecture and technology by Vision Group,{" "}
          <b>
            Park View city has become one of the most sought out housing
            societies in Islamabad.
          </b>{" "}
          Vision group is owned by Aleem khan, a senior & active member of PTI.
          This is the first project of Vision Group in Islamabad.
        </p>
      </div>
      <div>
        <h2 className="sub-m-h-text py-3">Vision Group:</h2>
        <p>
          Vision group was founded in 2012 and since then has accomplished
          unprecedented real estate projects. In less than a decade, Vision
          Group has aced the real estate industry in Pakistan and established
          itself as one of the most reliable and trustworthy developing groups
          in the region.
        </p>
        <p>
          <b>
            Park view by Vision Group is an initiative striving for excellence
            by being one of the most exuberant housing projects in the
            surrounding.
          </b>
        </p>
        <p>
          The company aims to create luxurious living through various
          construction and urban landscape projects in cosmopolitan cities.
          Vision group features elegant aesthetics and modern functionality in
          the elements of their projects that make for convenient and lavish
          living.
        </p>
      </div>
      <div>
        <h2 className="sub-m-h-text py-3">Projects By Vision Group:</h2>
        <p>
          The vision group executed several successful projects with great
          finesse and architectural masterpieces in major cities of Pakistan.
          Following is the list of the unparalleled projects by Vision Group:
        </p>
        <ul className="li-style-p">
          <li>Abdul Aleem Khan Foundation</li>
          <li>PARK View Icon located at I.I.Chundrigar road-Karachi</li>
          <li>Park View City in Islamabad</li>
          <li>PARK View Corporate Center at Mall road-Lahore</li>
          <li>Park View Signature Apartments at Gulberg-Lahore</li>
          <li>The National School</li>
          <li>Park view villas at Multan road-Lahore</li>
        </ul>
        <div className="text-center m-5">
          <img
            className="img-w-100"
            height="100%"
            src={require("../../assets/images/park_view_city/park_view_city_owners_and_developers.jpg")}
            alt="park_view_city_owners_and_developers"
          />
        </div>
      </div>
      <div>
        <h2 className="sub-m-h-text py-3">Mauza:</h2>
        <p>The Mauza (old name) of this magnificent housing scheme is Malot.</p>
      </div>
      <div>
        <h2 className="sub-m-h-text py-3">Park View City NOC:</h2>
        <p>
          The <b>No Objection Certificate (NOC)</b> of Park View City Islamabad
          will soon be <b>approved </b> by{" "}
          <b>Capital Development Authority (CDA)</b> and developers are putting
          their full legal efforts to get the approved status. Becoming legal is
          very important for any housing society because that will grant the
          society the status of a legal and approved residential area.
        </p>
      </div>
      <div>
        <h2 className="sub-m-h-text py-3">Park View City Islamabad Map:</h2>
        <p>
          As you can see from the map, Park view City is ideally located
          opposite <b>Bahria Enclave</b> at only 15 mins drive from Serena Hotel
          Islamabad, a 5-minute drive from <b> Bahria Enclave </b> and{" "}
          <b>Bani Gala</b> and an only 1-minute walk to the lush green botanical
          Garden.
        </p>
        <p>
          It is situated an 8 km drive from <b>Chak Shahzad</b> through the{" "}
          <b>Park Road</b> & the <b>Kuri Road </b> via the route from{" "}
          <b>Kashmir Highway, Lehtrar Road & Islamabad Highway.</b>
        </p>
      </div>
      <div>
        <h2 className="sub-m-h-text py-3">Access Roads:</h2>
        <p>
          Park View City Islamabad can be accessed via main Jinnah Avenue from
          the Malot Road or the 200 feet wide main access road, whose access has
          been directly given to the society from Kurri Road by CDA.
        </p>
      </div>
      <div>
        <h2 className="sub-m-h-text py-3">Park View City Total Land Area:</h2>
        <p>
          Stretched across the land of 7000 Kanal with several residential and
          commercial blocks, the society holds an attraction to all norms of
          society.
        </p>
        <p>
          It is situated near Bahria Enclave surrounded by lush green
          surroundings of Bani Gala with the most mesmerizing sceneries, 15
          minutes away from the main Islamabad city, this society is the epitome
          of luxury and tranquillity.
        </p>
        <p>
          Park View City Islamabad is located in the middle of the Botanical
          Garden with serene views. Our main entrance is from Rawal Chowk side
          and our gate 2 can give you access from Bhara Kahu, The Park View City
          Islamabad location is such that it is close to all the major
          destinations of the main city yet away from all the scrutiny of noise
          and pollution, making it an excellent choice to make your permanent
          residence.
        </p>
        <p>
          The project is located on Malot Road with easy access from Kurri Road,
          200ft wide road has been approved by the CDA to make way for the mega
          project.
        </p>
      </div>
      <div>
        <h2 className="sub-m-h-text py-3">Park View City Master Plan:</h2>
        <p>
          The location of the blocks in the society can be viewed on the
          following map of the master plan of the whole society:
        </p>
        <div className="text-center m-5">
          <img
            className="img-w-100"
            src={require("../../assets/images/park_view_city/park_view_city_master_plan.jpg")}
            alt="park_view_city_master_plan"
          />
        </div>
      </div>
      <div>
        <h2 className="sub-m-h-text py-3">Park View City Project Details:</h2>
        <p>
          The Park View City is divided into different alphabetical blocks
          consisting of commercial and residential blocks. An extension block
          “J” has also been launched in the society by the management in the
          recent balloting event held at the premises of society. Blocks A, B,
          F, J and K consist of 5 Marla plots while 10-marla plots are offered
          in Blocks A, B, F, H and I. 1-Kanal plots are available in Blocks B,
          C, E, F, N and M and Blocks D and P offer 2-Kanal plots in the
          society.
        </p>
      </div>
      <div>
        <h2 className="sub-m-h-text py-3">Park View City Terrace C Block:</h2>
        <p>
          The Terrace Block of Park View City is located in C Block. The block
          consists of residential plots of the following size at low rates:
        </p>
        <ul className="li-style-p">
          <li>
            <b>10 Marla</b>
          </li>
        </ul>
        <p>
          Moreover, the block also consists of Terrace Apartments that are the
          epitome of luxury and elegance. The Terrace C Block is equipped with
          all the basic utilities and modern amenities.
        </p>
      </div>

      <div>
        <h2 className="sub-m-h-text py-3">Park View City A & B Blocks:</h2>
        <p>
          The A and B Blocks of Park View City are residential and consist of
          residential plots at low rates. Below are the sizes of the plots:
        </p>
        <ul className="li-style-p">
          <li>
            <b>5 Marla</b>
          </li>
          <li>
            <b>10 Marla</b>
          </li>
          <li>
            <b>1 Kanal</b>
          </li>
        </ul>
      </div>
      <div>
        <h2 className="sub-m-h-text py-3">Park View City H Block:</h2>
        <p>
          H Bock of Park View City consists of residential plots of the
          following sizes:
        </p>
        <ul className="li-style-p">
          <li>
            <b>5 Marla</b>
          </li>
          <li>
            <b>10 Marla</b>
          </li>
          <li>
            <b>1 Kanal</b>
          </li>
        </ul>
      </div>
      <div>
        <h2 className="sub-m-h-text py-3">Park View City K Block:</h2>
        <p>
          K Block of Park View City Islamabad is the latest block that offers
          residential plots of the following size:
        </p>
        <ul className="li-style-p">
          <li>
            <b>3.5 Marla</b>
          </li>
        </ul>
      </div>
      <div>
        <h2 className="sub-m-h-text py-3">
          Park View City Hills Estate Block:
        </h2>
        <p>
          Park View City is on a spree of launching projects one after another.
          The Hills Estate Block is the newest addition to the master plan of
          Park View City Islamabad.{" "}
          <b>It offers residential plots of the following sizes:</b>
        </p>
        <ul className="li-style-p">
          <li>
            <b>5 Marla</b>
          </li>
          <li>
            <b>10 Marla</b>
          </li>
        </ul>
      </div>
      <div>
        <h2 className="sub-m-h-text py-3">The Walk Commercial-Downtown:</h2>
        <p>
          The Walk Commercial-Downtown of the housing scheme is undoubtedly a
          hub of all the commercial activities within the residential society.
          Apart from shopping centers, there are commercial plots of various
          sizes:
        </p>
      </div>
      <div>
        <h2 className="sub-m-h-text py-3">Commercial Downtown:</h2>
        <ul className="li-style-p">
          <li>
            <b>6 Marla</b>
          </li>
          <li>
            <b>8 Marla</b>
          </li>
        </ul>
      </div>
      <div>
        <h2 className="sub-m-h-text py-3">The Walk:</h2>
        <ul className="li-style-p">
          <li>
            <b>1 Kanal</b>
          </li>
        </ul>
      </div>
      <div>
        <h2 className="sub-m-h-text py-3">
          Park View City Terrace Apartments:
        </h2>
        <p>
          Park View City Islamabad offers Park View City Terrace Apartments at
          reasonable rates. The apartments are to be built within Block A and
          Block B of the PVC.
        </p>
        <p>
          <b>Following are the types of Terrace Apartments:</b>
        </p>
        <ul className="li-style-p">
          <li>
            <b>Studio/One Bedroom Apartments</b>
          </li>
          <li>
            <b>Two Bedroom Apartments</b>
          </li>
          <li>
            <b>Three Bedroom Apartments</b>
          </li>
        </ul>
      </div>
      <div>
        <h2 className="sub-m-h-text py-3">Park View Homes:</h2>
        <p>
          Park View City offers already developed houses at reasonable rates.{" "}
          <b>The PVC Homes are available in the following size:</b>
        </p>
        <ul className="li-style-p">
          <li>
            <b>5 Marla</b>
          </li>
        </ul>
      </div>
      <div>
        <h2 className="sub-m-h-text py-3">
          Park View City 3.5 Marla Residential Plots:
        </h2>
        <p>
          Park View City Islamabad has recently launched its 3.5 Marla
          residential plots in Block K. Due to the growing demand for
          residences, these plots are introduced at extremely low rates.
        </p>
        <p>
          Park View City is currently offering 2 Year payment plan on the
          following sizes.
        </p>
      </div>
      <div>
        <h2 className="sub-m-h-text py-3">Residential Plot Sizes:</h2>
        <ul className="li-style-p">
          <li>
            <b>5 Marla (26”x 50”)</b>
          </li>
          <li>
            <b>8 Marla (30”x60”)</b>
          </li>
          <li>
            <b>10 Marla (35”x70”)</b>
          </li>
          <li>
            <b>1 Kanal (50”x90”)</b>
          </li>
          <li>
            <b>2 Kanal (75”x120”)</b>
          </li>
        </ul>
      </div>
      <div>
        <h2 className="sub-m-h-text py-3">Commercial Plot Sizes:</h2>
        <ul className="li-style-p">
          <li>
            <b>6 Marla (35”x40”)</b>
          </li>
          <li>
            <b>8 Marla (40”x45”)</b>
          </li>
        </ul>
        <p>
          Booking of the above-mentioned plots in park view housing society
          starts from a 20% down payment, while the rest of the payable amount
          is to be paid in 8 equal quarterly installments spanned over a time of
          2 years.
        </p>
      </div>
      <div>
        <h2 className="sub-m-h-text py-3">Park View City Payment Plans:</h2>
        <p>
          The Payment plan of Residential and Commercial plots in the society
          are attached below:
        </p>
      </div>
      <div>
        <h2 className="sub-m-h-text py-3">
          Park View City Downtown Commercial Plots Payment Plan:
        </h2>
        <div className="text-center m-5">
          <img
            className="img-w-100"
            src={require("../../assets/images/park_view_city/park_view_city_downtown_commercial_plots_payment_plan.jpg")}
            alt="park_view_city_downtown_commercial_plots_payment_plan"
          />
        </div>
      </div>
      <div>
        <h2 className="sub-m-h-text py-3">
          Park View City Residential Plots A & B Installment Plans:
        </h2>
        <div className="text-center m-5">
          <img
            className="img-w-100"
            src={require("../../assets/images/park_view_city/park_view_city_residential_plots_a_&_b_installment_plans.jpg")}
            alt="park_view_city_residential_plots_a_&_b_installment_plans"
          />
        </div>
      </div>
      <div>
        <h2 className="sub-m-h-text py-3">
          Park View City Residential Plots Payment Plan Terrace C Block:
        </h2>
        <div className="text-center m-5">
          <img
            className="img-w-100"
            src={require("../../assets/images/park_view_city/park_view_city_residential_plots_payment_plan_terrace_c_block.jpg")}
            alt="park_view_city_residential_plots_payment_plan_terrace_c_block"
          />
        </div>
      </div>
      <div>
        <h2 className="sub-m-h-text py-3">
          Park View City Residential Plots Payment Plan D Block:
        </h2>
        <div className="text-center m-5">
          <img
            className="img-w-100"
            src={require("../../assets/images/park_view_city/park_view_city_residential_plots_payment_plan_d_block.jpg")}
            alt="park_view_city_residential_plots_payment_plan_d_block"
          />
        </div>
      </div>
      <div>
        <h2 className="sub-m-h-text py-3">
          Park View City Residential Plots Payment Plan E Block:
        </h2>
        <div className="text-center m-5">
          <img
            className="img-w-100"
            src={require("../../assets/images/park_view_city/park_view_city_residential_plots_payment_plan_e_block.jpg")}
            alt="park_view_city_residential_plots_payment_plan_e_block"
          />
        </div>
      </div>
      <div>
        <h2 className="sub-m-h-text py-3">
          Park View City Residential Plots Payment Plan F Block:
        </h2>
        <div className="text-center m-5">
          <img
            className="img-w-100"
            src={require("../../assets/images/park_view_city/park_view_city_residential_plots_payment_plan_f_block.jpg")}
            alt="park_view_city_residential_plots_payment_plan_f_block"
          />
        </div>
      </div>
      <div>
        <h2 className="sub-m-h-text py-3">
          Park View City Residential Plots Payment Plan H Block:
        </h2>
        <div className="text-center m-5">
          <img
            className="img-w-100"
            src={require("../../assets/images/park_view_city/park_view_city_residential_plots_payment_plan_h_block.jpg")}
            alt="park_view_city_residential_plots_payment_plan_h_block"
          />
        </div>
      </div>
      <div>
        <h2 className="sub-m-h-text py-3">
          Park View City Residential Plot J Block Payment plan:
        </h2>
        <div className="text-center m-5">
          <img
            className="img-w-100"
            src={require("../../assets/images/park_view_city/park_view_city_residential_plot_j_block_payment_plan.jpg")}
            alt="park_view_city_residential_plot_j_block_payment_plan"
          />
        </div>
      </div>
      <div>
        <h2 className="sub-m-h-text py-3">
          Park View City K Block Payment Plan:
        </h2>
        <div className="text-center m-5">
          <img
            className="img-w-100"
            src={require("../../assets/images/park_view_city/park_view_city_k_block_payment_plan.jpg")}
            alt="park_view_city_k_block_payment_plan"
          />
        </div>
      </div>
      <div>
        <h2 className="sub-m-h-text py-3">
          Park View City Hills Estate Block Payment Plan:
        </h2>
        <div className="text-center m-5">
          <img
            className="img-w-100"
            src={require("../../assets/images/park_view_city/park_view_city_hills_estate_block_payment_plan.jpg")}
            alt="park_view_city_hills_estate_block_payment_plan"
          />
        </div>
      </div>
      <div>
        <h2 className="sub-m-h-text py-3">
          Park View City Terrace Apartments Payment Plan:
        </h2>
        <div className="text-center m-5">
          <img
            className="img-w-100"
            src={require("../../assets/images/park_view_city/park_view_city_terrace_apartments_payment_plan.jpg")}
            alt="park_view_city_terrace_apartments_payment_plan"
          />
        </div>
      </div>
      <div>
        <h2 className="sub-m-h-text py-3">Park View Homes Payment Plan:</h2>
        <div className="text-center m-5">
          <img
            className="img-w-100"
            src={require("../../assets/images/park_view_city/park_view_homes_payment_plan.jpg")}
            alt="park_view_homes_payment_plan"
          />
        </div>
      </div>
      <div>
        <h2 className="sub-m-h-text py-3">
          Park View City The Walk Payment Plan:
        </h2>
        <div className="text-center m-5">
          <img
            className="img-w-100"
            src={require("../../assets/images/park_view_city/park_view_city_the_walk_payment_plan.jpg")}
            alt="park_view_city_the_walk_payment_plan"
          />
        </div>
      </div>
      <div>
        <h2 className="sub-m-h-text py-3">Category Plots:</h2>
        <p>
          The category plots in the society such as Corner, Park Facing, and the
          plots located on Main Boulevard of the society are priced{" "}
          <b>10% higher</b> than that of the regular plot prices proposed by the
          society.
        </p>
      </div>
      <div>
        <h2 className="sub-m-h-text py-3">Discounted Price:</h2>
        <p>
          Society is offering a reduction of 5% of the total price of the plot
          on the lump-sum payment. This means that if you are buying any plot in
          Park View City on full payment without availing the installment plan
          attached above, the society will offer you a 5% discount.
        </p>
      </div>
      <div>
        <h2 className="sub-m-h-text py-3">Price Comparison:</h2>
        <p>
          Despite the fact that the cost of the plots in this society is viewed
          as somewhat higher compared to other housing societies such as
          University town and Lahore Smart City to a few people but are
          definitely much lower than societies located in the CDA sectors of
          Islamabad and housing societies like B-17 and ICHS, it will definitely
          be worth it in the end.
        </p>
      </div>

      <div>
        <h2 className="sub-m-h-text py-3">Features And Amenities:</h2>
        <p>
          Park view city is an impeccable housing society with unmatched
          facilities and immaculate vision. Each block of the society is planned
          with a unique perspective and functionality to provide its residents
          with an exalting living.
        </p>
      </div>
      <div>
        <h2 className="sub-m-h-text py-3">Imax Cinemas:</h2>
        <p>
          Park view City will be a paragon of luxury and entertainment, the
          Cinemas with IMAX and 3 D technology will provide you with an
          unimaginable cinematic experience.
        </p>
      </div>
      <div>
        <h2 className="sub-m-h-text py-3">Commercial Walk:</h2>
        <p>
          Commercial zones including the biggest brands name in the retail will
          cater to all your needs while giving you an ultimate enjoyable and
          convenient shopping experience.
        </p>
      </div>
      <div>
        <h2 className="sub-m-h-text py-3">Zoo And Parks:</h2>
        <p>
          The zoos, parks and other fun activities are also included in the plan
          of the society to provide elements of enjoyment for all ages.
        </p>
      </div>
      <div>
        <h2 className="sub-m-h-text py-3">Club & Community Center:</h2>
        <p>
          The club and community center in the society will give a chance to the
          residents to enjoy a variety of indoor and outdoor sports including
          bowling, swimming pools, snooker, cricket, tennis and golf.
        </p>
      </div>
      <div>
        <h2 className="sub-m-h-text py-3">Hospitals:</h2>
        <p>
          A huge specially designed hospital will be constructed in the society
          to facilitate healthy issues with modern-day equipment and the latest
          technology
        </p>
      </div>
      <div>
        <h2 className="sub-m-h-text py-3">Schools:</h2>
        <p>
          The incorporation of the best Schools and educational institutions
          will be set-up in the society offering the best curriculum in
          proximity to the residential blocks so they are easily accessible and
          at a minimum distance.
        </p>
        <p>
          The Vision group has decided to establish the newest branch of the
          National School in Park View City which will operate under the Abdul
          Aleem Khan Foundation owned by the Vision Group among other well-known
          educational facilities.
        </p>
      </div>
      <div>
        <h2 className="sub-m-h-text py-3">Mosque:</h2>
        <p>
          To look after the religious values and significance of beautiful
          mosques will be part of this extraordinary housing scheme.
        </p>
      </div>
      <div>
        <h2 className="sub-m-h-text py-3">Gated Community:</h2>
        <p>
          Park view City is a secure and fully gated neighbourhood surrounded by
          a boundary wall covering all the sides of the project. The
          neighbouring societies of Park View are not entirely covered this
          extensively while this society has even barricaded the side that is
          nearby the national forest area.
        </p>
      </div>
      <div>
        <h2 className="sub-m-h-text py-3">24/7 Electricity, Gas and Water:</h2>
        <p>
          Availability of electricity, water and gas is precedence in park view
          Islamabad and will be supplied in all parts of the society 24 hrs.
          Park View City is superior to the remaining societies in the region as
          it is naturally blessed with natural resources among these the
          presence of underground water is of utmost importance.
        </p>
        <p>
          The water is available at only under 50 feet of the ground and Gumrah
          River passing through its region also ensures a sufficient supply of
          water in the society.
        </p>

        <p>
          The plan of each house in the society includes having an underground
          built water storage tanker to store the abundance of rainwater
          received in the area. The society also has a plan in motion to build
          its own Dam for collection and usage of the water, the work on this
          dam has already commenced in the society.
        </p>
      </div>
      <div>
        <h2 className="sub-m-h-text py-3">Load Shedding Free Zone:</h2>
        <p>
          To make the society a load-shedding free zone the construction work
          commenced on the water dam will also include an electric power plant
          in order to ensure the abundance of electric supply in the society.
        </p>
      </div>
      <dv>
        <h2 className="sub-m-h-text py-3">
          Underground Supply of Electricity:
        </h2>
        <p>
          All the electric work in the society is planned underground to control
          the Power outages Underground wires are much safer and make an
          aesthetically pleasing feature in the society.
        </p>
      </dv>
      <div>
        <h2 className="sub-m-h-text py-3">24/7 Surveillance:</h2>
        <p>
          Society takes the security of its residents very seriously and ensures
          that a network of CCTV cameras is installed throughout society.
        </p>
      </div>
      <div>
        <h2 className="sub-m-h-text py-3">
          Modern Infrastructure And Elegant Housing Plan:
        </h2>
        <p>
          Taking advantage of the lush green natural surroundings of Park View
          City Islamabad, this project promises to be one the landmarks in the
          making, High-rise buildings, modern infrastructure and its elegant
          housing plan will add to its appeal.
        </p>
      </div>
      <div>
        <h2 className="sub-m-h-text py-3">State-Of-Art Development:</h2>
        <p>
          With the greatest craftsmanship and the most majestic development,
          Park View city will be a landmark of modern civilization in Pakistan.
        </p>
      </div>
      <div>
        <h2 className="sub-m-h-text py-3">Park View City Apartments:</h2>
        <p>
          With the incorporation of park view city apartments in the society not
          only will the residents enjoy an upscale modern life but also raise a
          standard of living. These luxury apartments will be fully equipped
          with a fitness center, swimming pools, laundry facilities, convenience
          store and much more on the premises of these apartments.
        </p>
      </div>
      <div>
        <h2 className="sub-m-h-text py-3">Botanical Garden:</h2>
        <p>
          A Botanical Garden in the midst of Park View housing society makes it
          superior to other societies in its region. Not only will this
          Botanical Garden enhance the natural beauty of the project but will
          also increase add to the fertility of its soil.
        </p>
        <p>
          The greenhouse will incorporate a huge assortment of plants, garden
          herbs and unique plants from around the globe. There will be nurseries
          and greenhouses to grow and cultivate extraordinary tropical plants
          and flowers in the society. An organic food market will also be formed
          in the society to provide organic fruits and vegetables so the
          occupants of this society can enjoy a healthy lifestyle.
        </p>
      </div>
      <div>
        <h2 className="sub-m-h-text py-3">Park View City Golf Estate:</h2>
        <p>
          Park View City Islamabad has recently launched the <b>Golf Estate,</b>{" "}
          an exciting addition to the landscape of society. It is a beautiful
          development offering a unique scenic living environment like nowhere
          in society. The Golf Estate location lies beautifully{" "}
          <b>adjacent to the Bahria Enclave</b> that could be <b>accessed</b>{" "}
          from <b>Gate 2</b> of the society.
        </p>
        <p>
          The Golf Estate Park View City is designed to offer a luxurious
          experience with refreshing beauty by the Margalla Hills away from the
          hustle of city life. The Golf Estate is a perfect getaway for the city
          dwellers and enjoys the stunning views. The{" "}
          <b>Golf Estate in Park View City</b> is no doubt a <b>heaven</b> on
          earth <b>for Golf enthusiasts.</b>
        </p>
        <p>
          The project offers everything from residences to commercial facilities
          along with sports in the form of Golf Estate. The Park View City has
          become a true piece of luxurious art in the real estate landscape of
          the twin cities. The addition of this <b>9-hole Golf Course</b> has
          further made it prominent on the map of Islamabad.
        </p>
        <p>
          The Golf Estate is a perfect blend of luxury, comfort, and world-class
          amenities offered at the best market prices of the real estate
          industry. The Park View City <b>Golf Estate offers</b> a range of
          <b>residential plots</b> as follows:
        </p>
        <ul className="li-style-p">
          <li>
            <b>5 Marla</b>
          </li>
          <li>
            <b>10 Marla</b>
          </li>
          <li>
            <b>1 Kanal</b>
          </li>
        </ul>
      </div>
      <div>
        <h2 className="sub-m-h-text py-3">
          Park View City Golf Estate Payment Plan:
        </h2>
        <p>
          The <b>payment plan</b> of Park View City <b>Golf Estate</b> is
          <b>affordable</b> with <b>flexible installments.</b> Plots sizes other
          than 5 Marla and 10 Marla will also be launched soon. Till 27th
          August, plots were given on a first-come, first-serve basis. The plots
          prices are also expected to rise after some time.
        </p>
        <ul className="li-style-p">
          <li>
            <b>5 Marla</b>
            is offered for Rs.
            <b>8.5 million</b>
          </li>
          <li>
            <b>10 Marla</b>
            is offered for Rs.
            <b>16 million</b>
          </li>
          <li>
            <b>1 Kanal </b>
            is offered for Rs.
            <b>35 million</b>
          </li>
        </ul>
        <p>
          The plots could easily be booked by just paying the 25% down payment.
          The remaining dues after down payment could be paid in 8 quarterly
          installments. Other charges include:
        </p>
        <ul className="li-style-p">
          <li>
            The
            <b>membership fee</b>
            for
            <b>5 Marla</b>
            is Rs. 7,500/-
          </li>
          <li>
            The
            <b>membership fee</b>
            for
            <b>10 Marla</b>
            is Rs. 15,000/-
          </li>
          <li>
            The
            <b>membership fee</b>
            for
            <b>1 Kanal</b>
            is Rs. 25,000/-
          </li>
        </ul>
      </div>
      <div className="text-center m-5">
        <img
          className="img-w-100"
          src={require("../../assets/images/park_view_city/park_view_city_golf_estate_payment_plan.jpg")}
          alt="park_view_city_golf_estate_payment_plan"
        />
      </div>
      <div>
        <h2 className="sub-m-h-text py-3">
          Park View City Islamabad Overseas Block:
        </h2>
        <p>
          Park view city is a charismatic housing project that has covered the
          needs of everyone, from residential and commercial properties to
          classic apartments to Park View City Islamabad Villas and now the
          latest addition to this esteemed project is Park View City Islamabad
          Overseas Block. The Overseas Block will be launched soon in the grand
          project and rest assured it will fulfill all the requirements of
          international standard housings. The block is specially designed by
          the planners with the incorporation of the most advanced technology
          and amenities to provide an exquisite living experience for overseas
          Pakistanis.
        </p>
        <p>
          The need was felt to introduce this particular block on the
          requisition of overseas Pakistani’s who are seeking an identity,
          recognition and a safe place to invest in Pakistan. The overseas block
          in Park View City does not only provide grand residences but also a
          variety of business opportunities as well. The properties in this
          block will be of added value and high in demand. The success of this
          block can easily be predicted from the trend seen in the Overseas
          Block of Capital Smart City and Blue world City Islamabad.
        </p>
      </div>
      <div>
        <h2 className="sub-m-h-text py-3">
          Park View City Overseas Block Location:
        </h2>
        <p>
          The location of the overseas block in Park view city is at the most
          prime area in Park View City near Gate 1. The block is placed near the
          commercial downtown of the project and will offer its residents
          fruitful investment apart from an elite living.
        </p>
      </div>
      <div>
        <h2 className="sub-m-h-text py-3">Amenities:</h2>
        <p>
          Apart from the beautiful location, the overseas block will cater to
          the needs of all members of society. The block will have the following
          amenities included in its plan.
        </p>
        <ul className="li-style-p">
          <li>24/7 Supply of Electricity, Gas and Water</li>
          <li>State of the art waste disposal system</li>
          <li>CCTV Security , Guards and Motion censors</li>
          <li>Parks, Playgrounds and beautifully designed green areas</li>
          <li>Shopping malls, restaurants and Entertainment centers</li>
          <li>Health centers and Educational Facilities</li>
        </ul>
      </div>
      <div>
        <h2 className="sub-m-h-text py-3">
          Park View City Overseas Block Payment Plan:
        </h2>
        <p>
          Initially, the Overseas block offers 5 Marla and 10 Marla Residential
          plots with an initial 3-year installment plan for its residents.
        </p>
        <p>
          <b>
            The payment plans of Park View City Overseas block are attached for
            your convenience:
          </b>
        </p>
      </div>
      <div>
        <h2 className="sub-m-h-text py-3">
          Park View City Overseas Block Residential Plots Payment Plan:
        </h2>
        <div className="text-center m-5">
          <img
            className="img-w-100"
            src={require("../../assets/images/park_view_city/park_view_city_overseas_block_residential_plots_payment_plan.jpg")}
            alt="park_view_city_overseas_block_residential_plots_payment_plan"
          />
        </div>
      </div>
      <div>
        <h2 className="sub-m-h-text py-3">
          Park View City Overseas Block Commercial Plots Payment Plan:
        </h2>
        <div className="text-center m-5">
          <img
            className="img-w-100"
            src={require("../../assets/images/park_view_city/park_view_city_overseas_block_commercial_plots_payment_plan.jpg")}
            alt="park_view_city_overseas_block_commercial_plots_payment_plan"
          />
        </div>
      </div>
      <div>
        <h2 className="sub-m-h-text py-3">
          How to Book your Plot in the Overseas Block?
        </h2>
        <p>
          The prices offered in Overseas Block are introductory and will be
          available on a 3-year easy installment. In order to book your plot
          contact AM Marketing with the following documents.
        </p>
        <ul className="li-style-p">
          <li>Copy of your CNIC</li>
          <li>Copy of your CNIC of your next to kin</li>
          <li>2 passport size photographs</li>
        </ul>
        <p>
          The exact location of the overseas block will be revealed in a month
          or two, however, the investment in this block is the most beneficial
          and fruitful at this time. The plots are available on the lowest
          prices and the scope of development is immense. If you are looking to
          invest your savings at a place that is secure and will provide you
          with a safe environment for your loved ones then Overseas Block in
          Park View City is the most suitable for you.
        </p>
      </div>
      <div>
        <h2 className="sub-m-h-text py-3">
          Ongoing Market trend and Price Index:
        </h2>
        <p>
          The demand for prices in Park View City has been consistent for a
          while. The majority of people who have purchased the plots in society
          are mostly end-users and have invested in society for residential
          purposes rather than investment. The alluring location, amenities, and
          prices offered all constitute the popularity of the project.
        </p>
        <p>
          Two years ago 5 Marla price was nearly 43 Lac which got revised in 6
          months, a significant rise of 7 to 8 lac was observed, since then the
          prices are rising up along with the development work. The latest price
          demanded a 5 Marla residential plot today is around PKR 57-58 lac.
        </p>
        <p>
          The demand for 5 Marla and 10 Marla residential plots and 6 Marla
          commercial plots is higher due to the easy tradable sizes of these
          properties. 1 Kanal plots have also shown great potential as the
          buyers prefer a spacious plot size for a house.
        </p>
      </div>
      <div>
        <h2 className="sub-m-h-text py-3">Possession:</h2>
        <p>
          Possessions of the initial blocks where the construction work has
          already begun have been announced by the officials of this society in
          the recent balloting event. The society will be fully developed within
          the next 2 years and the possession of the remaining plots will also
          be granted to the investors in the same time frame.
        </p>
        <p>
          The society is currently offering new bookings for a limited time
          only, so investors are encouraged to avail of this once in a lifetime
          investment opportunity and benefit from it. This housing scheme is not
          only suitable for investment, but also its secure residential and
          commercial location is an ideal place for you and your loved ones to
          live and work at.
        </p>
      </div>
      <div>
        <h2 className="sub-m-h-text py-3">Park View City Development:</h2>
        <p>
          The management of the esteemed society is aiming to speed up the
          developing process to deliver the project ahead of its proposed time
          to its investors. The development work in Block A & B has almost been
          completed and the earthwork around the surrounding areas of these
          blocks has begun. A water treatment plant will also be set up soon in
          A-block shortly to ensure the supply of clean water to its
          inhabitants. The water will be filtered, recycled and supplied to all
          parts of the block through this treatment plant.
        </p>
        <p>
          The main entrance of the society has already been completed with a
          majestic main gate and the road leading the pathway in the alluring
          society. The land in this society is naturally rich and agricultural
          with an abundance of natural green sceneries so the levelling and
          plotting of the land do not require any major alternation.
        </p>
        <p>
          The society is looking in much better shape now that the work on the
          main entrance gate of Park View has been completed and construction
          work on the main 200-foot wide road has initiated and is expected to
          be completed in a few months. This 2-kilometre long road is being
          constructed after the authorization from CDA and it will gain direct
          access from Kurri Road.
        </p>
      </div>
      <div>
        <h2 className="sub-m-h-text py-3">Park View City Balloting:</h2>
        <p>
          The balloting and possession of plots in Park View City Islamabad
          commenced on 24th March 2019 in the premises of Park View Housing
          society for the eligible plots after the clearance of outstanding
          dues.
        </p>
        <p>
          The event was attended by the notable personalities of the society and
          the authorized dealers of this esteemed project. Plot numbers were
          assigned to of 5 marlas, 8 marlas, 10 marlas, 1 Kanal and 2 Kanal plot
          files booked in the extension blocks in the society.
        </p>
        <p>
          The balloting was done via a fair computerized process for the
          investors who had paid their installments and dues on time. Within a
          week the official documents comprising of the assigned plot no. and
          details were sent to the respective owners of the plot.
        </p>
      </div>
      <div>
        <h2 className="sub-m-h-text py-3">Pros And Cons:</h2>
      </div>
    </div>
  );
}

export default PVCDetails;
