import React from "react";

function CarouselHome() {
  return (
    // <div
    //   id="carouselExampleCaptions"
    //   className="carousel slide"
    //   data-bs-ride="false"
    // >
    //   <div className="carousel-inner">
    //     <div className="carousel-item active">
    //       <img
    //         src={require("../../assets/carousel/carousel-1.webp")}
    //         className="d-block w-100"
    //         width="100%"
    //         height="580px"
    //         alt="Carousel_1"
    //       />
    //       <div className="carousel-caption d-none d-md-block">
    //         <h2 className="carousel-main-text m-0">MOVE ON UP</h2>
    //         <p className="carousel-submain-text">
    //           We have more than 17 years of experience
    //         </p>
    //         <div className="row row-cols-1 row-cols-md-3 g-4">
    //           <div className="col">
    //             <div className="card border-0">
    //               <img
    //                 src={require("../../assets/carousel/carousel-img-1.jpg")}
    //                 width="100%"
    //                 height="160px"
    //                 alt="CAPITAL_SMART_CITY"
    //               />
    //               <div className="card-body bg-golden p-2">
    //                 <h5 className="card-title m-0">CAPITAL SMART CITY</h5>
    //               </div>
    //             </div>
    //           </div>
    //           <div className="col">
    //             <div className="card border-0">
    //               <img
    //                 src={require("../../assets/carousel/carousel-img-2.jpg")}
    //                 width="100%"
    //                 height="160px"
    //                 alt="PARK_VIEW_CITY"
    //               />
    //               <div className="card-body bg-golden p-2">
    //                 <h5 className="card-title m-0">PARK VIEW CITY</h5>
    //               </div>
    //             </div>
    //           </div>
    //           <div className="col">
    //             <div className="card border-0">
    //               <img
    //                 src={require("../../assets/carousel/carousel-img-3.jpg")}
    //                 width="100%"
    //                 height="160px"
    //                 alt="BLUE_WORLD_CITY"
    //               />
    //               <div className="card-body bg-golden p-2">
    //                 <h5 className="card-title m-0">BLUE WORLD CITY</h5>
    //               </div>
    //             </div>
    //           </div>
    //         </div>
    //       </div>
    //     </div>
    //   </div>
    // </div>

    <div
      id="carouselExampleInterval"
      className="carousel slide"
      data-bs-ride="carousel"
    >
      <div className="carousel-inner">
        <div className="carousel-item active" data-bs-interval="10000">
          <img
            src={require("../../assets/carousel/carousel-img-1.jpg")}
            className="d-block w-100"
            width="100%"
            alt="Carousel_1"
          />
        </div>
        <div className="carousel-item" data-bs-interval="2000">
          <img
            src={require("../../assets/carousel/carousel-img-2.jpg")}
            className="d-block w-100"
            width="100%"
            alt="Carousel_2"
          />
        </div>
        <div className="carousel-item">
          <img
            src={require("../../assets/carousel/carousel-img-3.jpeg")}
            className="d-block w-100"
            width="100%"
            alt="Carousel_3"
          />
        </div>
        <div className="carousel-item">
          <img
            src={require("../../assets/carousel/carousel-img-4.jpg")}
            className="d-block w-100"
            width="100%"
            alt="Carousel_4"
          />
        </div>
      </div>
      <button
        className="carousel-control-prev"
        type="button"
        data-bs-target="#carouselExampleInterval"
        data-bs-slide="prev"
      >
        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
        <span className="visually-hidden">Previous</span>
      </button>
      <button
        className="carousel-control-next"
        type="button"
        data-bs-target="#carouselExampleInterval"
        data-bs-slide="next"
      >
        <span className="carousel-control-next-icon" aria-hidden="true"></span>
        <span className="visually-hidden">Next</span>
      </button>
    </div>
  );
}

export default CarouselHome;
