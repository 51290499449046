import React, { useState, useEffect } from "react";
import SimpleReactLightbox from "simple-react-lightbox";
import { SRLWrapper } from "simple-react-lightbox";
import AOS from "aos";
import "aos/dist/aos.css";

function HotDeals() {
  const dealsList = [
    {
      title: "Deal 1",
      category: ["hot-deals"],
      imageSrc:
        "../assets/images/kingdom_valley/kingdom_valley_3.5_marla_residential_plots.jpg",
      img: (
        <img
          src={require("../assets/images/kingdom_valley/kingdom_valley_3.5_marla_residential_plots.jpg")}
          layout="responsive"
          width={280}
          height="auto"
          alt="hot-deal"
        />
      ),
    },
    {
      title: "Deal 2",
      category: ["hot-deals"],
      imageSrc:
        "../assets/images/kingdom_valley/kingdom_valley_7_marla_residential_plots_payment_plan.jpg",
      img: (
        <img
          src={require("../assets/images/kingdom_valley/kingdom_valley_7_marla_residential_plots_payment_plan.jpg")}
          layout="responsive"
          width={280}
          height="auto"
          alt="hot-deal"
        />
      ),
    },
    {
      title: "Deal 3",
      category: ["hot-deals"],
      imageSrc:
        "../assets/images/kingdom_valley/kingdom_valley_farmhouses_payment_plan.jpg",
      img: (
        <img
          src={require("../assets/images/kingdom_valley/kingdom_valley_farmhouses_payment_plan.jpg")}
          layout="responsive"
          width={280}
          height="auto"
          alt="hot-deal"
        />
      ),
    },
    {
      title: "Deal 4",
      category: ["hot-deals"],
      imageSrc:
        "../assets/images/kingdom_valley/kingdom_valley_general_block_residential_plots_new_payment_plan.jpg",
      img: (
        <img
          src={require("../assets/images/kingdom_valley/kingdom_valley_general_block_residential_plots_new_payment_plan.jpg")}
          layout="responsive"
          width={280}
          height="auto"
          alt="hot-deal"
        />
      ),
    },
    {
      title: "Deal 5",
      category: ["hot-deals"],
      imageSrc:
        "../assets/images/kingdom_valley/kingdom_valley_islamabad_4_marla_residential_plots_payment_plan.jpg",
      img: (
        <img
          src={require("../assets/images/kingdom_valley/kingdom_valley_islamabad_4_marla_residential_plots_payment_plan.jpg")}
          layout="responsive"
          width={280}
          height="auto"
          alt="hot-deal"
        />
      ),
    },
    {
      title: "Deal 6",
      category: ["hot-deals"],
      imageSrc:
        "../assets/images/kingdom_valley/kingdom_valley_islamabad_commercial_plots_payment_plan.jpg",
      img: (
        <img
          src={require("../assets/images/kingdom_valley/kingdom_valley_islamabad_commercial_plots_payment_plan.jpg")}
          layout="responsive"
          width={280}
          height="auto"
          alt="hot-deal"
        />
      ),
    },
    {
      title: "Deal 7",
      category: ["hot-deals"],
      imageSrc:
        "../assets/images/kingdom_valley/kingdom_valley_islamabad_executive_block_commercial_plots_payment_plan.jpg",
      img: (
        <img
          src={require("../assets/images/kingdom_valley/kingdom_valley_islamabad_executive_block_commercial_plots_payment_plan.jpg")}
          layout="responsive"
          width={280}
          height="auto"
          alt="hot-deal"
        />
      ),
    },
    {
      title: "Deal 8",
      category: ["hot-deals"],
      imageSrc:
        "../assets/images/kingdom_valley/kingdom_valley_islamabad_executive_block_residential_plots_new_payment_plan.jpg",
      img: (
        <img
          src={require("../assets/images/kingdom_valley/kingdom_valley_islamabad_executive_block_residential_plots_new_payment_plan.jpg")}
          layout="responsive"
          width={280}
          height="auto"
          alt="hot-deal"
        />
      ),
    },
    {
      title: "Deal 9",
      category: ["hot-deals"],
      imageSrc:
        "../assets/images/kingdom_valley/kingdom_valley_islamabad_general_block_residential_plots_payment_plan.jpg",
      img: (
        <img
          src={require("../assets/images/kingdom_valley/kingdom_valley_islamabad_general_block_residential_plots_payment_plan.jpg")}
          layout="responsive"
          width={280}
          height="auto"
          alt="hot-deal"
        />
      ),
    },
    {
      title: "Deal 10",
      category: ["hot-deals"],
      imageSrc:
        "../assets/images/kingdom_valley/kingdom_valley_islamabad_payment_plan.jpg",
      img: (
        <img
          src={require("../assets/images/kingdom_valley/kingdom_valley_islamabad_payment_plan.jpg")}
          layout="responsive"
          width={280}
          height="auto"
          alt="hot-deal"
        />
      ),
    },
    {
      title: "Deal 11",
      category: ["hot-deals"],
      imageSrc:
        "../assets/images/kingdom_valley/kingdom_valley_islamabad_residential_plots_detailed_payment_plan.jpg",
      img: (
        <img
          src={require("../assets/images/kingdom_valley/kingdom_valley_islamabad_residential_plots_detailed_payment_plan.jpg")}
          layout="responsive"
          width={280}
          height="auto"
          alt="hot-deal"
        />
      ),
    },
    {
      title: "Deal 12",
      category: ["hot-deals"],
      imageSrc:
        "../assets/images/kingdom_valley/kingdom_valley_kingdom_villas_payment_plan.jpg",
      img: (
        <img
          src={require("../assets/images/kingdom_valley/kingdom_valley_kingdom_villas_payment_plan.jpg")}
          layout="responsive"
          width={280}
          height="auto"
          alt="hot-deal"
        />
      ),
    },
  ];

  useEffect(() => {
    AOS.init();
    AOS.refresh();
  });

  return (
    <div className="container">
      <SimpleReactLightbox>
        <SRLWrapper>
          <div className="clearfix">
            <div id="masonry" className="row row-cols-1 row-cols-lg-3 py-5">
              {dealsList.map((item) => (
                <div class="col mb-4">
                  <div
                    class="card h-100"
                    data-aos="zoom-in"
                    data-aos-duration="300"
                    data-aos-delay="100"
                    style={{ border: "2px solid red" }}
                  >
                    <div class="card-body">
                      <a className="pointer">{item.img}</a>
                    </div>
                    <div class="card-footer">
                      <h4 className="m-0">
                        <b>{item.title}</b>
                      </h4>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </SRLWrapper>
      </SimpleReactLightbox>
    </div>
  );
}

export default HotDeals;
