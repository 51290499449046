import React from "react";
import BWCDetails from "../../components/blueWorldCity/BWCDetails";
import MainCarouselBWC from "../../components/blueWorldCity/MainCarouselBWC";

function BlueWorldCity() {
  return (
    <div>
      <MainCarouselBWC />
      <BWCDetails />
    </div>
  );
}

export default BlueWorldCity;
