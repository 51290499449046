import React from "react";
import KVDetails from "../../components/kingdomValley/KVDetails";
import MainCarouselKV from "../../components/kingdomValley/MainCarouselKV";

function KingdomValley() {
  return (
    <div>
      <MainCarouselKV />
      <KVDetails />
    </div>
  );
}

export default KingdomValley;
