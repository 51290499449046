import React, { useState, useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";

import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import TwitterIcon from "@mui/icons-material/Twitter";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import PhoneIcon from "@mui/icons-material/Phone";

import emailjs from "emailjs-com";

import { Snackbar } from "@mui/material";
import MuiAlert from "@mui/material/Alert";

import validator from "validator";

import "../index.css";

function ContactForm() {
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [subject, setSubject] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const [emailError, setEmailError] = useState("");

  const [open, setOpen] = React.useState(false);
  const [error, setError] = React.useState(false);

  useEffect(() => {
    AOS.init();
    AOS.refresh();
  });

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  const validateEmail = (e) => {
    var email = e.target.value;
    console.log(validator.isEmail(email));
    setEmail(email);
  };

  const sendEmail = (e) => {
    e.preventDefault();
    if (validator.isEmail(email)) {
      setEmailError("");
      setName("");
      setPhone("");
      setSubject("");
      setMessage("");
      setEmail("");

      emailjs
        .sendForm(
          "service_xot1ogu",
          "template_6mg7chq",
          e.target,
          "-Ap3wBf33WGm_SO0b"
        )
        .then(
          function (response) {
            if (response.status === 200) {
              return setOpen(true);
            }
            console.log("SUCCESS!", response.status, response.text);
          },
          function (error) {
            if (error) {
              return setError(true);
            }
            console.log("FAILED...", error);
          }
        );
    } else {
      setEmailError("Enter valid Email!");
    }
  };
  return (
    <div className="p-5 bg-dark">
      <h2 className="text-light text-start">Get in touch</h2>
      <form onSubmit={sendEmail}>
        <div className="mb-2">
          <div className="row row-cols-1 row-cols-md-2">
            <div className="col py-3">
              <input
                className="contact-input py-3 text-light"
                placeholder="Full Name"
                id="name"
                value={name}
                multiline
                onChange={(e) => setName(e.target.value)}
                name="name"
                required
                isInvalid
                variant="standard"
              />
            </div>
            <div className="col py-3">
              <input
                className="contact-input py-3 text-light"
                placeholder="Your Email"
                id="email"
                value={email}
                onChange={(e) => validateEmail(e)}
                name="email"
                type="email"
                required
                isInvalid
                multiline
                variant="standard"
              />
              <span
                style={{
                  // fontWeight: "bold",
                  color: "red",
                }}
              >
                {emailError}
              </span>
            </div>
            <div className="col py-3">
              <input
                className="contact-input py-3 text-light"
                placeholder="Your Telephone"
                id="phone"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
                name="phone"
                type="number"
                required
                isInvalid
                multiline
                variant="standard"
              />
            </div>
            <div className="col py-3">
              <input
                className="contact-input py-3 text-light"
                placeholder="Subject"
                id="subject"
                value={subject}
                multiline
                onChange={(e) => setSubject(e.target.value)}
                name="subject"
                required
                isInvalid
                variant="standard"
              />
            </div>
          </div>
          <div className="py-3">
            <textarea
              className="contact-input py-3 text-light"
              placeholder="Your Message"
              rows="5"
              id="message"
              // style={{ height: "398px" }}
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              name="message"
            />
          </div>
          <div className="py-3">
            <button className="send-button des-font-color w-100" type="submit">
              SEND MESSAGE
            </button>
          </div>
        </div>

        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
          <Alert
            onClose={handleClose}
            severity="success"
            sx={{ width: "100%" }}
          >
            Your form submitted successfully!
          </Alert>
        </Snackbar>
        <Snackbar error={error} autoHideDuration={6000} onClose={handleClose}>
          <Alert
            onClose={handleClose}
            severity="success"
            sx={{ width: "100%" }}
          >
            Please submit again!
          </Alert>
        </Snackbar>
      </form>
    </div>
  );
}

export default ContactForm;
