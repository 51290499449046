import React, { useState, useEffect } from "react";
import "./App.css";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Home from "./pages/Home";
import { Routes, Route, useLocation, Navigate } from "react-router-dom";
import Blog from "./pages/Blog";
import Videos from "./pages/Videos";
import Offers from "./pages/Offers";
import News from "./pages/News";
import Career from "./pages/Career";
import Reviews from "./pages/Reviews";
import Contact from "./pages/Contact";
import KingdomValley from "./pages/projects/KingdomValley";
import BlueWorldCity from "./pages/projects/BlueWorldCity";
import ParkViewCity from "./pages/projects/ParkViewCity";
import AlNoorOrchard from "./pages/projects/AlNoorOrchard";
import PageNotFound from "./pages/PageNotFound";
import HotDeals from "./pages/HotDeals";

function App() {
  const { pathname } = useLocation();
  const [backToTop, setBackToTop] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  useEffect(() => {
    function backTop() {
      if (window.scrollY <= 480) {
        setBackToTop(false);
      }
      if (window.scrollY >= 480) {
        setBackToTop(true);
      }
    }

    window.addEventListener("scroll", backTop);
    return () => window.addEventListener("scroll", backTop);
  }, []);

  const ScrollToTopAction = () => {
    window.scrollTo(0, 0);
  };

  return (
    <div className="App">
      {backToTop === true ? (
        <a
          // href=""
          onClick={ScrollToTopAction}
          id="toTopBtn"
          className="cd-top text-replace js-cd-top cd-top--is-visible cd-top--fade-out"
          data-abc="true"
        ></a>
      ) : (
        ""
      )}

      <a
        href="https://wa.me/+923259009786"
        className="whatsapp_float"
        target="_blank"
        rel="noopener noreferrer"
      >
        <i className="fa fa-whatsapp whatsapp-icon"></i>
      </a>

      <Header />
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route exact path="/blog" element={<Blog />} />
        <Route exact path="/videos" element={<Videos />} />
        <Route exact path="/offers" element={<Offers />} />
        <Route exact path="/news" element={<News />} />
        <Route exact path="/career" element={<Career />} />
        <Route exact path="/reviews" element={<Reviews />} />
        <Route exact path="/contact" element={<Contact />} />
        <Route exact path="/kingdom-valley" element={<KingdomValley />} />
        <Route exact path="/blue-world-city" element={<BlueWorldCity />} />
        <Route exact path="/park-view-city" element={<ParkViewCity />} />
        <Route exact path="/al-noor-orchard" element={<AlNoorOrchard />} />
        <Route exact path="/hot-deals" element={<HotDeals />} />
        <Route exact path="/page-not-found" element={<PageNotFound />} />
        <Route path="*" element={<Navigate to="/page-not-found" replace />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
