import React from "react";
import CarouselHome from "../components/home/CarouselHome";
import OurProjects from "../components/home/OurProjects";
import ReadyToServe from "../components/home/ReadyToServe";
import Welcome from "../components/home/Welcome";
import WhyChoose from "../components/home/WhyChoose";

function Home() {
  return (
    <div>
      <CarouselHome />
      <Welcome />
      <OurProjects />
      <WhyChoose />
      <ReadyToServe />
    </div>
  );
}

export default Home;
